import styled from "styled-components";

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
`

const Error = styled.p`
  text-align: center;
`


const FallbackRenderer = () => {
	return (
		<Main>
			<div>
				<Error>Es gab einen Fehler :( <br/>Bitte Lade die Seite neu und versuche die Aktion erneut.</Error>
			</div>
		</Main>
	);
}

export default FallbackRenderer;