import {Logtail} from "@logtail/browser";

const logtailClient = new Logtail(process.env.REACT_APP_LOGTAIL_ID || "");
const requestID = Math.floor(Math.random() * 10000);

const LogtailWrapper = () => {
	return {
		error: (message: any) => logtailClient.error(message, {
			requestID,
			type: "ERROR",
		})
	};
};
export default LogtailWrapper();