export const NavigationStructure = {
	jobs: {
		url: "jobs",
		display: "Einsätze"
	},
	jobEdit: {
		url: "jobs/:jobId/edit",
		display: "Einsatz bearbeiten",
		getURL: (id: string) => (`jobs/${id}/edit`)
	},
	jobConfirmationUpload: {
		url: "jobs/:jobId/upload/confirmation",
		display: "Einsatzbestätigung abgeben"
	},
	jobConfirmationReplace: {
		url: "jobs/:jobId/replace/confirmation",
		display: "Einsatzbestätigung überschreiben",
		getURL: (id: string) => (`jobs/${id}/replace/confirmation`)
	},
	jobAdd: {
		url: "jobs/add",
		display: "Einsatz erstellen"
	},
	login: {
		url: "/login",
		display: "Login"
	},
	interpreters: {
		url: "dolmetscher",
		display: "Dolmetscher"
	},
	interpreterEdit: {
		url: "dolmetscher/:interpreterId/edit",
		display: "Dolmetscher bearbeiten",
		getURL: (id: string) => (`dolmetscher/${id}/edit`)
	},
	interpreterPayslips: {
		url: "dolmetscher/:interpreterId/payslips",
		display: "Dolmetscher Honoraranzeigen",
		getURL: (id: string) => (`dolmetscher/${id}/payslips`)
	},
	clients: {
		url: "kunden",
		display: "Kunden"
	},
	clientEdit: {
		url: "kunden/:clientId/edit",
		display: "Kunden bearbeiten",
		getURL: (id: string) => (`kunden/${id}/edit`)
	},
	clientJobInstructionEdit: {
		url: "kunden/:clientId/edit/jobInstruction",
		display: "Hinweise bearbeiten",
		getURL: (id: string) => (`kunden/${id}/edit/jobInstruction`)
	},
	clientAdd: {
		url: "kunden/create",
		display: "Kunde erstellen",
	},
	confirmations: {
		url: "einsatzbestaetigungen",
		display: "Einsatzbestätigungen"
	},
	confirmationEdit: {
		url: "einsatzbestaetigungen/:id/edit",
		display: "Einsatzbestätigung bearbeiten",
		getURL: (id: string) => (`einsatzbestaetigungen/${id}/edit`)
	},
	register: {
		url: "anmeldung",
		display: "Anmeldung"
	},
	upload: {
		url: "upload",
		display: "Einsatzbestätigung abgeben"
	},
	logout: {
		url: "/logout",
		display: "Logout"
	},
	bidding: {
		url: "jobs/:jobId/bidding/:offerId",
		display: "Einsatz annehmen"
	},
	tasks: {
		url: "aufgaben",
		display: "Aufgaben"
	},
};