import {useState} from "react";
import {JobWithRelations} from "./Types/Job";
import {getBiddingInfo} from "./Calls/jobs/getBiddingInfo";
import {postBidding} from "./Calls/jobs/postBidding";

export const useBidding = () => {
	const [data, setData] = useState<JobWithRelations | null>(null);
	const [result, setResult] = useState<'ok' | 'invalid' | 'loading' | '' | 'won' | 'requesting'>('');

	const load = (jobId: string, interpreterId: string) => {
		setResult('loading');
		return getBiddingInfo(jobId, interpreterId).then((res) => {
			if (res) {
				setData(res);
				setResult('ok');
			} else {
				setResult('invalid');
			}
		}).catch(() => setResult('invalid'))
	}

	const putBid = (jobId: string, interpreterId: string) => {
		setResult('requesting');
		return postBidding(jobId, interpreterId).then((res) => {
			if (res) {
				setData(res);
				setResult('won');
			} else {
				setResult('invalid');
			}
		}).catch(() => setResult('invalid'))
	}

	return {
		result,
		data,
		load,
		putBid
	}
}
