import Page from "../../../Components/Page/Page";
import {useNavigate, useParams} from "react-router-dom";
import {useJob} from "../../../Api/useJob";
import React, {useEffect, useState} from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	CircularProgress,
	Dialog, DialogActions,
	DialogContent, DialogContentText,
	DialogTitle,
	Divider, FormControl, FormLabel,
	IconButton, InputLabel, List,
	ListItem,
	ListItemAvatar,
	ListItemText, MenuItem, Radio, RadioGroup, Select
} from "@mui/material";
import Grid from "../../../Components/Grid/Grid";
import JobForm from "../../../Components/Job/JobForm";
import Job, {JobStatus} from "../../../Api/Types/Job";
import {assignJob} from "../../../Api/Calls/jobs/assignJob";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import {
	ExpandMore,
	ContentCopy,
	Info,
	Edit,
	Person,
	DocumentScanner,
	Article,
	ReceiptLong,
	RequestQuote,
	Send,
	Comment,
	Notifications
} from "@mui/icons-material";
import {getJobAsText} from "../../../Helpers/JobHelper";
import JobAssignForm, {JobAssignFormValues} from "../../../Components/Job/JobAssignForm";
import ConfirmationFile from "../../../Api/Types/ConfirmationFile";
import OrangeButton from "../../../Components/OrangeButton/OrangeButton";
import {FilePreview} from "../../../Api/Types/FilePreview";
import PreviewDocument from "../../../Components/PreviewDocument/PreviewDocument";
import CreateOrUpdateInvoiceForm, {
	CreateOrUpdateInvoiceFormProps
} from "../../../Components/Invoice/CreateOrUpdateInvoiceForm";
import jobFeatureAllowed from "../../../Helpers/JobFeatures";
import JobStatusChip from "../../../Components/Job/JobStatusChip";
import {useConfirmationFiles} from "../../../Api/useConfirmationFiles";
import dayjs from "dayjs";
import InvoiceStatus from "../../../Components/Invoice/InvoiceStatus";
import JobMatchingForm, {JobMatchingFormValues, MatchingMethode} from "../../../Components/Job/JobMatchingForm";
import {matchingMethodeDescription, matchingMethodeName} from "../../../Helpers/matchingHelper";
import JobInstructionForm from "../../../Components/JobInstruction/JobInstructionView";
import CommentForm from "../../../Components/Comment/CommentForm";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ManageInvoice from "../../../Components/Job/Sections/ManageInvoice";
import {Actions} from "../../../Components/Actions/Actions";
import ManagePayslip from "../../../Components/Job/Sections/ManagePayslip";
import ManageConfirmation from "../../../Components/Job/Sections/ManageConfirmation";

export const JobEditView = () => {
	const {jobId} = useParams();
	useNavigate();
	const [confirmationFile, setConfirmationFile] = useState<ConfirmationFile | null>(null);
	const [previewConfirmation, setPreviewConfirmation] = useState<FilePreview | null>(null);
	const [previewPayslip, setPreviewPayslip] = useState<FilePreview | null>(null);
	const [previewInvoice, setPreviewInvoice] = useState<FilePreview | null>(null);
	const [previewInvoiceConfirmation, setPreviewInvoiceConfirmation] = useState<FilePreview | null>(null);
	const [openCancel, setOpenCancel] = useState<boolean>(false);
	const [openStepBack, setOpenStepBack] = useState<boolean>(false);
	const [cancelDistanceEnabled, setCancelDistanceEnabled] = useState<boolean>(false);
	const [cancelFixEnabled, setCancelFixEnabled] = useState<boolean>(false);
	const [cancelUnder24H, setCancelUnder24H] = useState<'true' | 'false'>('false');
	const [matchingMethode, setMatchingMethode] = useState<MatchingMethode>('normal');

	const {
		data: job,
		load,
		loading,
		token,
		update,
		getPayslipPreview,
		approveInvoice,
		getInvoicePreview,
		getInvoiceConfirmationPreview,
		unassign,
		cancel,
		createCancelInvoice,
		availableInterpreters,
		triggerBidding,
		stepBack,
		setReminder,
		sendReminder
	} = useJob();
	const {getById, getUrl} = useConfirmationFiles();

	useEffect(() => {
		if (jobId && token) load(jobId);
	}, [jobId, token]);

	useEffect(() => {
			if (job?.ConfirmationFileId) {
				getById(job?.ConfirmationFileId).then(res => setConfirmationFile(res));
				getUrl(job?.ConfirmationFileId).then(res => setPreviewConfirmation(res));
			}
		}, [job?.ConfirmationFileId]
	);

	useEffect(() => {
			if (job?.Payslip?.FileId) {
				getPayslipPreview(job?.Id).then(res => setPreviewPayslip(res));
			}
		}, [job?.Payslip?.FileId]
	);

	useEffect(() => {
			if (job?.Invoice?.InvoiceFileId) {
				getInvoicePreview(job?.Id).then(res => setPreviewInvoice(res));
			}
		}, [job?.Invoice?.InvoiceFileId]
	);

	useEffect(() => {
			if (job?.ConfirmationFileId) {
				getInvoiceConfirmationPreview(job?.Id).then(res => setPreviewInvoiceConfirmation(res));
			}
		}, [job?.ConfirmationFileId]
	);

	const handleEditSubmit = (values: any) => {
		if (job) {
			update(job.Id, values as Job).then(() => window.location.reload())
		}
	}

	const handleAssignSubmit = (values: JobAssignFormValues) => {
		if (job && values.InterpreterId) {
			assignJob(token, job.Id, values.InterpreterId, values.SendNotifications).then(() => window.location.reload())
		}
	}
	const handleBiddingSubmit = (values: JobMatchingFormValues) => {
		if (job && values.selectedInterpreters) {
			triggerBidding(job.Id, values.selectedInterpreters).then(() => window.location.reload())
		}
	}
	const confirmCancelInvoiceApproval = () => {
		if (window.confirm(`Soll die aktuelle Rechnung an den Kunden versendet werden?`) && job) {
			approveInvoice(job?.Id, true).then(() => window.location.reload())
		}
	}

	const handleCancelInvoiceCreation = (values: CreateOrUpdateInvoiceFormProps) => {
		if (job) {
			createCancelInvoice(job.Id, {
				...values,
				distanceEnabled: cancelDistanceEnabled,
				fixEnabled: cancelFixEnabled
			}).then(() => window.location.reload())
		}
	}

	const handleUnassign = () => {
		if (window.confirm(`Soll der aktuelle Dolmetscher vom Auftrag abgezogen werden? Danach kann ein neuer Dolmetscher zugewiesen werden.`) && job) {
			unassign(job.Id).then(() => window.location.reload());
		}
	}

	const handleCancel = () => {
		if (job) {
			cancel(job.Id, cancelUnder24H === 'true').then(() => window.location.reload());
		}
	}

	const handleStepBack = () => {
		if (job) {
			stepBack(job.Id).then(() => window.location.reload());
		}
	}

	const handleUpdateReminder = (event: unknown, value: boolean) => {
		if (job) {
			setReminder(job.Id, value);
		}
	}

	const confirmSendReminder = () => {
		if (window.confirm(`Soll jetzt eine Upload-Erinnerung an den Dolmetscher versendet werden?`) && job) {
			sendReminder(job?.Id).then(() => alert("Die Erinnerung wurde versendet!"))
		}
	}

	return (
		<Page headline={NavigationStructure.jobEdit.display}>
			{loading && (<div><CircularProgress/></div>)}
			{!loading && job && (
				<Grid>
					<Accordion defaultExpanded={true}>
						<AccordionSummary
							expandIcon={<ExpandMore/>}
						>
							<Info fontSize={"small"}/>
							Details Übersicht
						</AccordionSummary>
						<AccordionDetails>
							<div>
								{getJobAsText(job)}
								<IconButton onClick={() => navigator.clipboard.writeText(getJobAsText(job))}>
									<ContentCopy fontSize={"small"}/>
								</IconButton>
							</div>
							{job.Interpreter && (
								<div>
									Dolmetscher: {`${job.Interpreter.Prename} ${job.Interpreter.Surname}`}
								</div>
							)}
							<div>
								Status: {<JobStatusChip status={job.Status}/>}
							</div>
							<Divider style={{margin: "10px"}}/>
							<div>
								<FormControlLabel
									control={<Checkbox defaultChecked={job.EnableReminder}
									                   onChange={handleUpdateReminder}/>}
									label="Versenden EB Erinnerungen - täglich werden automatische Nachrichten an den Dolmetscher mit der Bitte um Upload versendet."/>
							</div>
							{jobFeatureAllowed(job, 'sendConfirmationReminder') && (
								<>
									<Divider style={{margin: "10px"}}/>
									<Actions>
										<OrangeButton
											onClick={confirmSendReminder}
											startIcon={<Notifications fontSize={"small"}/>}>
											Upload Erinnerung versenden
										</OrangeButton>
									</Actions>
								</>
							)}
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore/>}
						>
							<Edit fontSize={"small"}/>
							Details anpassen
						</AccordionSummary>
						<AccordionDetails>
							<JobForm
								job={job}
								handleSubmit={handleEditSubmit}
								clients={[job.Client]}
								mode={jobFeatureAllowed(job, 'editDetails') ? "edit" : "view"}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded={jobFeatureAllowed(job, 'openInterpreter')}>
						<AccordionSummary
							expandIcon={<ExpandMore/>}
						>
							<Person fontSize={"small"}/>
							Dolmetscher zuweisen
						</AccordionSummary>
						<AccordionDetails>
							<div>
								<FormControl>
									<InputLabel>Zuweisung Methode</InputLabel>
									<Select
										value={matchingMethode}
										label="Zuweisung Methode"
										onChange={(event) => setMatchingMethode(event.target.value as MatchingMethode)}
									>
										<MenuItem value={'normal'}>An einen Dolmetscher senden</MenuItem>
										<MenuItem value={'toAll'}>An alle verfügbaren Dolmetscher senden</MenuItem>
										<MenuItem value={'toSome'}>An bestimmte Dolmetscher senden</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div>
								<p>
									<b>{matchingMethodeName(matchingMethode)}</b> - {matchingMethodeDescription(matchingMethode)}
								</p>
							</div>
							{matchingMethode === "normal" && (
								<div>
									<JobAssignForm
										interpreters={availableInterpreters}
										job={job}
										handleSubmit={handleAssignSubmit}
									/>
								</div>
							)}
							{matchingMethode === "toAll" && (
								<div>
									<JobMatchingForm
										interpreters={availableInterpreters}
										job={job}
										handleSubmit={handleBiddingSubmit}
										methode={matchingMethode}
									/>
								</div>
							)}
							{matchingMethode === "toSome" && (
								<div>
									<JobMatchingForm
										interpreters={availableInterpreters}
										job={job}
										handleSubmit={handleBiddingSubmit}
										methode={matchingMethode}
									/>
								</div>
							)}
							{jobFeatureAllowed(job, 'unassigedAllowed') && (
								<div style={{display: "flex", justifyContent: "end"}}>
									<OrangeButton onClick={handleUnassign}>Einsatz Freigeben</OrangeButton>
								</div>
							)}
						</AccordionDetails>
					</Accordion>
					{confirmationFile && (
						<Accordion defaultExpanded={jobFeatureAllowed(job, 'openConfirmation')}>
							<AccordionSummary
								expandIcon={<ExpandMore/>}
							>
								<ReceiptLong fontSize={"small"}/>
								Dolmetscher Einsatzbestätigung
							</AccordionSummary>
							<AccordionDetails>
								<ManageConfirmation
									job={job}
									confirmationFile={confirmationFile}
									previewConfirmation={previewConfirmation}
								/>
							</AccordionDetails>
						</Accordion>
					)}
					{(jobFeatureAllowed(job, 'editPayslip') || previewPayslip) && confirmationFile && (
						<Accordion defaultExpanded={jobFeatureAllowed(job, 'openPayslip')}>
							<AccordionSummary
								expandIcon={<ExpandMore/>}
							>
								<RequestQuote fontSize={"small"}/>
								Dolmetscher Lohnabrechnung
							</AccordionSummary>
							<AccordionDetails>
								<ManagePayslip
									job={job}
									confirmationFile={confirmationFile}
									previewPayslip={previewPayslip}
								/>
							</AccordionDetails>
						</Accordion>
					)}
					{(jobFeatureAllowed(job, 'editInvoice') || previewInvoice) && confirmationFile && (
						<Accordion defaultExpanded={jobFeatureAllowed(job, 'openInvoice')}>
							<AccordionSummary
								expandIcon={<ExpandMore/>}
							>
								<RequestQuote fontSize={"small"}/>
								Rechnung an den Kunden
							</AccordionSummary>
							<AccordionDetails>
								<ManageInvoice
									job={job}
									confirmationFile={confirmationFile}
									previewInvoice={previewInvoice}
									previewInvoiceConfirmation={previewInvoiceConfirmation}
								/>
							</AccordionDetails>
						</Accordion>
					)}
					{jobFeatureAllowed(job, 'createCancelInvoice') && (
						<Accordion defaultExpanded={true}>
							<AccordionSummary
								expandIcon={<ExpandMore/>}
							>
								<RequestQuote fontSize={"small"}/>
								Storno-Rechnung an den Kunden
							</AccordionSummary>
							<AccordionDetails>
								<InvoiceStatus invoice={job.Invoice}/>
								<div style={{gap: "20px", display: "flex"}}>
									<FormControl>
										<FormLabel id="km">Entfernung Berechnung?</FormLabel>
										<RadioGroup
											value={cancelDistanceEnabled}
											aria-labelledby="km"
											onChange={(a: any, b: string) => {
												setCancelDistanceEnabled(b === "true");
											}}
										>
											<FormControlLabel value="true" control={<Radio/>} label="Ja"/>
											<FormControlLabel value="false" control={<Radio/>} label="Nein"/>
										</RadioGroup>
									</FormControl>
									<FormControl>
										<FormLabel id="km">Aufwandspauschale Berechnung?</FormLabel>
										<RadioGroup
											value={cancelFixEnabled}
											aria-labelledby="km"
											onChange={(a: any, b: string) => {
												setCancelFixEnabled(b === "true");
											}}
										>
											<FormControlLabel value="true" control={<Radio/>} label="Ja"/>
											<FormControlLabel value="false" control={<Radio/>} label="Nein"/>
										</RadioGroup>
									</FormControl>
								</div>

								<CreateOrUpdateInvoiceForm
									quantity={1}
									rate={job.Client.RateInvoice}
									distanceEnabled={cancelDistanceEnabled}
									distanceValue={0.42}
									fixEnabled={cancelFixEnabled}
									fixValue={0}
									distanceQuantity={0}
									handleSubmit={handleCancelInvoiceCreation}
									comment={''}
									invoiceDate={dayjs()}
								/>
								{previewInvoice && (
									<div>
										<p>Vorschau:</p>
										<PreviewDocument {...previewInvoice}/>
									</div>
								)}
								{previewInvoice && (
									<Actions>
										<OrangeButton
											onClick={confirmCancelInvoiceApproval}
											startIcon={<Send fontSize={"small"}/>}>
											Storno-Rechnung versenden
										</OrangeButton>
									</Actions>
								)}
							</AccordionDetails>
						</Accordion>
					)}
					<Accordion defaultExpanded={true}>
						<AccordionSummary
							expandIcon={<ExpandMore/>}
						>
							<Comment fontSize={"small"}/>
							Interne Kommentare (nur im System sichtbar)
						</AccordionSummary>
						<AccordionDetails>
							<CommentForm job={job}/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded={true}>
						<AccordionSummary
							expandIcon={<ExpandMore/>}
						>
							<DocumentScanner fontSize={"small"}/>
							Alle Dokumente und Links
						</AccordionSummary>
						<AccordionDetails>
							<List sx={{width: '100%', maxWidth: 360}}>
								{jobFeatureAllowed(job, 'showUploadConfirmation') && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<ReceiptLong/>
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Einsatzbestätigung Hochladen" secondary={
											<a target={"_blank"}
											   href={`${window.location.protocol}//${window.location.host}/jobs/${job?.Id}/upload/confirmation`}
											   rel="noreferrer">Link zum Upload</a>
										}/>
									</ListItem>
								)}
								{job.ConfirmationSystemId && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<Article/>
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="System Einsatzbestätigung" secondary={
											<a target={"_blank"}
											   href={`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/files/confirmation-system/${job.ConfirmationSystemId}/download`}
											   rel="noreferrer">Link</a>
										}/>
									</ListItem>
								)}
							</List>
						</AccordionDetails>
					</Accordion>

					<div style={{display: "flex", justifyContent: "end", gap: "16px"}}>
						{jobFeatureAllowed(job, 'stepBack') && (
							<OrangeButton
								onClick={() => setOpenStepBack(true)}>
								Schritt zurück
							</OrangeButton>
						)}
						{jobFeatureAllowed(job, 'cancelAllowed') && (
							<OrangeButton
								onClick={() => setOpenCancel(true)}>
								Auftrage Stornieren
							</OrangeButton>
						)}
					</div>

					{openCancel && (
						<Dialog
							open={true}
							onClose={() => setOpenCancel(false)}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle id="form-dialog-title">
								Auftrag stornieren?
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									<span>
									Soll der aktuelle Auftrag storniert werden? Diese Schritt kann nicht
										rückgängig gemacht werden!</span>
									<div>
										<JobInstructionForm client={job.Client} status={JobStatus.Canceled}/>
									</div>
									<span>Fällt eine Gebühr durch die stornierung an?</span>
								</DialogContentText>
								<FormControl fullWidth>
									<Select
										value={cancelUnder24H}
										onChange={(e) => setCancelUnder24H(e.target.value as 'true' | 'false')}
									>
										<MenuItem value={'false'}>Nein</MenuItem>
										<MenuItem value={'true'}>Ja</MenuItem>
									</Select>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<OrangeButton onClick={() => setOpenCancel(false)}>
									Abbrechen
								</OrangeButton>
								<OrangeButton onClick={handleCancel}>
									Bestätigen
								</OrangeButton>
							</DialogActions>
						</Dialog>
					)}
					{openStepBack && (
						<Dialog
							open={true}
							onClose={() => setOpenCancel(false)}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle id="form-dialog-title">
								Auftrag einen Schritt zurücksetzten?
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Soll der aktuelle Auftrag einen Schritt zurückgesetzt werden?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<OrangeButton onClick={() => setOpenStepBack(false)}>
									Abbrechen
								</OrangeButton>
								<OrangeButton onClick={handleStepBack}>
									Bestätigen
								</OrangeButton>
							</DialogActions>
						</Dialog>
					)}
				</Grid>
			)}
		</Page>
	)
}

export default JobEditView;