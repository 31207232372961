import React from 'react';
import './App.css';
import {createTheme, ThemeProvider} from '@mui/material';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {NavigationStructure} from "./Components/Navigation/NavigationStructure";
import JobsView from "./Pages/Jobs/JobsView";
import ClientsListPage from './Pages/Clients/List/ClientsListPage';
import RegisterView from './Pages/Register/RegisterView';
import PageNotFound from './Components/Navigation/PageNotFound';
import Header from "./Components/Navigation/Header";
import {Auth0Provider} from "@auth0/auth0-react";
import LoginForm from "./Pages/Login/LoginForm";
import LogoutView from "./Pages/Logout/LogoutView";
import AuthorizationParams from "./Api/AuthorizationParams";
import HomeView from "./Pages/Home/HomeView";
import JobAddView from "./Pages/Jobs/Add/JobAddView";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import JobEditView from "./Pages/Jobs/Edit/JobEditView";
import GeneralConfirmationUploadView from "./Pages/ConfirmationUpload/General/GeneralConfirmationUploadView";
import JobConfirmationUploadView from "./Pages/ConfirmationUpload/Job/JobConfirmationUploadView";
import InterpreterListPage from "./Pages/Interpreter/List/InterpreterListPage";
import InterpreterEditPage from "./Pages/Interpreter/Edit/InterpreterEditPage";
import InterpreterPayslipPage from "./Pages/Interpreter/Payslip/InterpreterPayslipPage";
import ConfirmationsView from "./Pages/Confirmations/ConfirmationsView";
import {ErrorBoundary} from "react-error-boundary";
import FallbackRenderer from "./Components/FallbackRenderer/FallbackRenderer";
import logtailClient from "./Logging/LogtailClient";
import BiddingView from "./Pages/Bidding/BiddingView";
import ClientEditPage from "./Pages/Clients/Edit/ClientEditPage";
import ClientAddPage from "./Pages/Clients/Add/ClientAddPage";
import JobInstructionEditPage from "./Pages/Clients/JobInstruction/Edit/JobInstructionEditPage";
import JobConfirmationReplaceView from "./Pages/ConfirmationUpload/Job/JobConfirmationReplaceView";
import ConfirmationEditView from "./Pages/Confirmations/Edit/ConfirmationEditView";
import TasksView from "./Pages/Tasks/TasksView";

const theme = createTheme({
	typography: {
		fontFamily: 'Lato'
	},
});

const auth0Config = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
	audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
}

const logError = (error: Error, info: { componentStack: string }) => {
	const logger = logtailClient;
	logger.error({error: error.message, info})
};

function App() {
	return (
		<ErrorBoundary
			fallback={<FallbackRenderer/>}
			onError={logError}
		>
			<Auth0Provider
				domain={auth0Config.domain}
				clientId={auth0Config.clientId}
				cacheLocation="localstorage"
				useRefreshTokens={true}
				authorizationParams={AuthorizationParams}>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Router>
							<div className="App">
								<Header/>
								<Routes>
									<Route
										path="/"
										element={<HomeView/>}
									/>
									<Route
										path={NavigationStructure.login.url}
										element={<LoginForm/>}
									/>
									<Route
										path={`/${NavigationStructure.jobs.url}`}
										element={<JobsView/>}
									/>
									<Route
										path={`/${NavigationStructure.jobEdit.url}`}
										element={<JobEditView/>}
									/>
									<Route
										path={`/${NavigationStructure.jobConfirmationUpload.url}`}
										element={<JobConfirmationUploadView/>}
									/>
									<Route
										path={`/${NavigationStructure.jobConfirmationReplace.url}`}
										element={<JobConfirmationReplaceView/>}
									/>
									<Route
										path={`/${NavigationStructure.jobAdd.url}`}
										element={<JobAddView/>}
									/>
									<Route
										path={`/${NavigationStructure.interpreters.url}`}
										element={<InterpreterListPage/>}
									/>
									<Route
										path={`/${NavigationStructure.interpreterEdit.url}`}
										element={<InterpreterEditPage/>}
									/>
									<Route
										path={`/${NavigationStructure.interpreterPayslips.url}`}
										element={<InterpreterPayslipPage/>}
									/>
									<Route
										path={`/${NavigationStructure.clients.url}`}
										element={<ClientsListPage/>}
									/>
									<Route
										path={`/${NavigationStructure.clientEdit.url}`}
										element={<ClientEditPage/>}
									/>
									<Route
										path={`/${NavigationStructure.clientAdd.url}`}
										element={<ClientAddPage/>}
									/>
									<Route
										path={`/${NavigationStructure.clientJobInstructionEdit.url}`}
										element={<JobInstructionEditPage/>}
									/>
									<Route
										path={`/${NavigationStructure.register.url}`}
										element={<RegisterView/>}
									/>
									<Route
										path={`/${NavigationStructure.upload.url}`}
										element={<GeneralConfirmationUploadView/>}
									/>
									<Route
										path={`/${NavigationStructure.confirmations.url}`}
										element={<ConfirmationsView/>}
									/>
									<Route
										path={`/${NavigationStructure.confirmationEdit.url}`}
										element={<ConfirmationEditView/>}
									/>
									<Route
										path={NavigationStructure.logout.url}
										element={<LogoutView/>}
									/>
									<Route
										path={NavigationStructure.bidding.url}
										element={<BiddingView/>}
									/>
									<Route
										path={NavigationStructure.tasks.url}
										element={<TasksView/>}
									/>
									<Route
										path="*"
										element={<PageNotFound/>}
									/>
								</Routes>
							</div>
						</Router>
					</LocalizationProvider>
				</ThemeProvider>
			</Auth0Provider>
		</ErrorBoundary>
	);
}

export default App;
