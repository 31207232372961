import {useState} from "react";
import {JobWithConfirmation} from "./Types/Job";
import {getJobConfirmationUpload} from "./Calls/jobs/getJobConfirmationUpload";
import {replaceConfirmationFileRequest} from "./Calls/confirmationFiles/replaceConfirmationFile";
import {useAuthentication} from "./useAuthentication";
import {uploadConfirmationFileRequest} from "./Calls/confirmationFiles/uploadConfirmationFile";

export const useJobConfirmationUpload = (mode: 'upload' | 'replace' = 'upload') => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<JobWithConfirmation | undefined>(undefined);
	const [error, setError] = useState<string>('');
	const {token} = useAuthentication(false);

	const load = (id: string) => {
		setLoading(true);
		return getJobConfirmationUpload(id, mode).then((res) => {
				if (res) {
					setData(res);
					setLoading(false);
				}
			})
			.catch(() => setError('Es fehlt die Freigabe für den Upload einer Einsatzbestätigung'))
			.finally(() => setLoading(false))
	}

	const replace = (id: string, data: FormData) => {
		return replaceConfirmationFileRequest(token, id, data);
	}

	const upload = (id: string, data: FormData) => {
		return uploadConfirmationFileRequest(id, data);
	}

	return {
		loading,
		data,
		load,
		error,
		replace,
		upload
	}
}
