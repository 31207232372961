import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableSortLabel,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import {Edit, PaymentsRounded} from "@mui/icons-material";
import DisabledInterpreterIcon from '@mui/icons-material/HowToReg';
import EnabledInterpreterIcon from '@mui/icons-material/PersonOff';
import DeleteInterpreterIcon from '@mui/icons-material/DeleteForever';
import {prioToLabel} from "../../Helpers/Mapper";
import Interpreter from "../../Api/Types/Interpreter";
import CustomIconButton from "../CustomIconButton/CustomIconButton";
import {ACTIVE} from "../../Reducers/Enums/DolmetscherStatus";
import {useNavigate} from "react-router-dom";
import {NavigationStructure} from "../Navigation/NavigationStructure";

type Props = {
	order: "asc" | "desc";
	orderBy: string;
	interpreters: Array<Interpreter>;
	requesting: boolean;
	openDeactivateDialog: (id: string) => void;
	openActivateDialog: (id: string) => void;
	openEditDialog: (id: string) => void;
	openDeletionDialog: (id: string) => void;
	handleSortChange: (property: keyof Interpreter | string) => void;
}

export const InterpretersList = ({
	                                 order,
	                                 orderBy,
	                                 interpreters,
	                                 requesting,
	                                 openDeactivateDialog,
	                                 openActivateDialog,
	                                 openEditDialog,
	                                 openDeletionDialog,
	                                 handleSortChange
                                 }: Props) => {

	const navigation = useNavigate();
	const goToPayslips = (id: string) => {
		navigation("/" + NavigationStructure.interpreterPayslips.getURL(id));
	}

	const renderLanguages = (languages: string[]) => {
		if (languages.length >= 6) {
			languages = languages.slice(0, 5);
			languages.push("...");
		}
		return languages.map((language) => (<div key={language}>
			{language}
			<br/>
		</div>));
	}

	return (
		<div>
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>#</TableCell>
							<TableCell align="right">
								<TableSortLabel
									active={orderBy === "Prename"}
									direction={orderBy === "Prename" ? order : "asc"}
									onClick={() => handleSortChange("Prename")}
								>
									Vorname
								</TableSortLabel>
							</TableCell>
							<TableCell align="right">
								<TableSortLabel
									active={orderBy === "Surname"}
									direction={orderBy === "Surname" ? order : "asc"}
									onClick={() => handleSortChange("Surname")}
								>
									Nachname
								</TableSortLabel>
							</TableCell>
							<TableCell align="right">
								<TableSortLabel
									active={orderBy === "City"}
									direction={orderBy === "City" ? order : "asc"}
									onClick={() => handleSortChange("City")}
								>
									Adresse
								</TableSortLabel>
							</TableCell>
							<TableCell align="right">Sprache(n)</TableCell>
							<TableCell align="right">Mail</TableCell>
							<TableCell align="right">Telefon</TableCell>
							<TableCell align="right">IBAN</TableCell>
							<TableCell align="right">
								<TableSortLabel
									active={orderBy === "Prio"}
									direction={orderBy === "Prio" ? order : "asc"}
									onClick={() => handleSortChange("Prio")}
								>
									Prio
								</TableSortLabel>
							</TableCell>
							<TableCell align="right">
								Aktive Kunden
							</TableCell>
							<TableCell align="right">
								<TableSortLabel
									active={orderBy === "interpreterActive"}
									direction={orderBy === "interpreterActive" ? order : "asc"}
									onClick={() => handleSortChange("Status")}
								>
									de/aktivieren
								</TableSortLabel>
							</TableCell>
							<TableCell align="right">Bearbeiten</TableCell>
							<TableCell align="right">Honoraranzeigen</TableCell>
							<TableCell align="right">Löschen</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{interpreters.map((row, index) => (
						<TableRow
							key={row.Id}
							style={{opacity: row.Status === ACTIVE ? 1 : 0.5}}
						>
							<TableCell align="left">#{index + 1}</TableCell>
							<TableCell align="right">{row.Prename}</TableCell>
							<TableCell align="right">{row.Surname}</TableCell>
							<TableCell align="right">
								{row.Address.Street}
								<br/>
								{row.Address.Postcode} {row.Address.City}
								<br/>
							</TableCell>
							<TableCell align="right">
								{renderLanguages(row.Languages)}
							</TableCell>
							<TableCell align="right">
								{row.Mail}
							</TableCell>
							<TableCell align="right">
								{row.PhoneNumber}
							</TableCell>
							<TableCell align="right">
								{row.IBAN}
							</TableCell>
							<TableCell align="right">{prioToLabel(row.Prio)}</TableCell>
							<TableCell align="right">{row.Clients.length}</TableCell>
							<TableCell align="right">
								{row.Status === ACTIVE && (<CustomIconButton
									onClick={() => openDeactivateDialog(row.Id)}
								>
									<EnabledInterpreterIcon/>
								</CustomIconButton>)}
								{row.Status !== ACTIVE && (<CustomIconButton
									onClick={() => openActivateDialog(row.Id)}
								>
									<DisabledInterpreterIcon/>
								</CustomIconButton>)}
							</TableCell>
							<TableCell align="right">
								<CustomIconButton
									onClick={() => openEditDialog(row.Id)}
									disabled={requesting}
								>
									<Edit/>
								</CustomIconButton>
							</TableCell>
							<TableCell align="right">
								<CustomIconButton
									onClick={() => goToPayslips(row.Id)}
								>
									<PaymentsRounded/>
								</CustomIconButton>
							</TableCell>
							<TableCell align="right">
								<CustomIconButton
									onClick={() => openDeletionDialog(row.Id)}
								>
									<DeleteInterpreterIcon/>
								</CustomIconButton>
							</TableCell>
						</TableRow>))
					}</TableBody>
				</Table>
			</TableContainer>
		</div>);
}

export default React.memo(InterpretersList);