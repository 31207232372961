import React from "react";
import {
	TextField,
	FormControl,
	MenuItem,
	FormGroup,
	FormLabel, FormHelperText, Divider
} from "@mui/material";

import {prioToLabel} from "../../Helpers/Mapper";
import Client from "../../Api/Types/Client";
import Interpreter from "../../Api/Types/Interpreter";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
	KurdischOptions,
	GeneralLanguageOptions,
	PakistanischOptions,
	PersischOptions,
	RussianOptions
} from "../../Reducers/Enums/LanguageOptions";
import {CheckboxWithLabel} from "formik-material-ui";
import OrangeButton from "../OrangeButton/OrangeButton";
import InterpreterValidation from "./InterpreterValidation";
import Grid3 from "../Grid/Grid3";
import {checkExistingMail} from "../../Api/Calls/interpreters/checkExistingMail";

type Props = {
	clients: Array<Client>;
	interpreter?: Interpreter;
	onClose?: () => void;
	handleSubmit: any;
	mode: "edit" | "register" | "add"
}

export const InterpretersForm = ({
	                                 clients,
	                                 interpreter,
	                                 onClose,
	                                 handleSubmit,
	                                 mode
                                 }: Props) => {

	const initialValue: Omit<Interpreter, "Id" | "UpdatedAt" | "Status" | "CreatedAt"> = {
		Address: interpreter?.Address || {
			City: '',
			Postcode: '',
			Street: ''
		},
		Clients: interpreter?.Clients || [],
		HourlyRate: interpreter?.HourlyRate ?? 20,
		IBAN: interpreter?.IBAN || '',
		IsTaxesEnabled: interpreter?.IsTaxesEnabled || false,
		Languages: interpreter?.Languages || [],
		Mail: interpreter?.Mail || '',
		PhoneNumber: interpreter?.PhoneNumber || '',
		Prename: interpreter?.Prename || '',
		Prio: interpreter?.Prio || 0,
		Surname: interpreter?.Surname || ''
	};

	return (<>
			<Formik
				initialValues={initialValue}
				validationSchema={InterpreterValidation}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {
						Address,
						Clients,
						HourlyRate,
						IBAN,
						IsTaxesEnabled,
						Languages,
						Mail,
						PhoneNumber,
						Prename,
						Prio,
						Surname
					} = props.values;

					const languageChange = (e: any) => {
						if (PersischOptions.some(language => language.systemName === e.target.value)) {
							const groupedLanguages = PersischOptions.map(language => language.systemName);
							if (e.target.checked) {
								props.setFieldValue('Languages', [...Languages, ...groupedLanguages]);
								return;
							} else {
								props.setFieldValue('Languages', [...Languages.filter(language => !groupedLanguages.includes(language))]);
								return;
							}
						}

						if (RussianOptions.some(language => language.systemName === e.target.value)) {
							const groupedLanguages = RussianOptions.map(language => language.systemName);
							if (e.target.checked) {
								props.setFieldValue('Languages', [...Languages, ...groupedLanguages]);
								return;
							} else {
								props.setFieldValue('Languages', [...Languages.filter(language => !groupedLanguages.includes(language))]);
								return;
							}
						}

						if (KurdischOptions.some(language => language.systemName === e.target.value)) {
							const groupedLanguages = KurdischOptions.map(language => language.systemName);
							if (e.target.checked) {
								props.setFieldValue('Languages', [...Languages, ...groupedLanguages]);
								return;
							} else {
								props.setFieldValue('Languages', [...Languages.filter(language => !groupedLanguages.includes(language))]);
								return;
							}
						}


						if (PakistanischOptions.some(language => language.systemName === e.target.value)) {
							const groupedLanguages = PakistanischOptions.map(language => language.systemName);
							if (e.target.checked) {
								props.setFieldValue('Languages', [...Languages, ...groupedLanguages]);
								return;
							} else {
								props.setFieldValue('Languages', [...Languages.filter(language => !groupedLanguages.includes(language))]);
								return;
							}
						}

						if (e.target.checked) {
							props.setFieldValue('Languages', [...Languages, e.target.value]);
						} else {
							props.setFieldValue('Languages', [...Languages.filter(language => language !== e.target.value)]);
						}
					}

					return (
						<Form>
							<Grid3>
								<div>
									<TextField
										label="Vorname"
										name="Prename"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Prename}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Prename"/>}
										error={props.errors.Prename as unknown as boolean && props.touched.Prename}
										required
									/></div>
								<div>
									<TextField
										label="Nachname"
										name="Surname"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Surname}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Surname"/>}
										error={props.errors.Surname as unknown as boolean && props.touched.Surname}
										required
									/>
								</div>
								<div>
									<TextField
										label="Mail"
										name="Mail"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Mail}
										onChange={props.handleChange}
										onBlur={(event) => {
											props.handleBlur(event);
											if (!props.errors.Mail && event.target.value) {
												checkExistingMail(event.target.value).then(res => {
													if (res) {
														props.setFieldError("Mail", undefined);
													} else {
														props.setFieldError('Mail', `Die E-Mail(${event.target.value}) ist schon vergeben!`)
													}
												})
											}
										}}
										helperText={<ErrorMessage name="Mail"/>}
										error={props.errors.Mail as unknown as boolean && props.touched.Mail}
										required
									/>
								</div>
							</Grid3>
							<Grid3>
								<div>
									<TextField
										label="Adresse"
										name="Address.Street"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.Street}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.Street"/>}
										error={props.errors.Address?.Street as unknown as boolean && props.touched.Address?.Street}
										required
									/>
								</div>
								<div>
									<TextField
										label="PLZ"
										name="Address.Postcode"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.Postcode}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.Postcode"/>}
										error={props.errors.Address?.Postcode as unknown as boolean && props.touched.Address?.Postcode}
										required
									/>
								</div>
								<div>
									<TextField
										label="Stadt"
										name="Address.City"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.City}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.City"/>}
										error={props.errors.Address?.City as unknown as boolean && props.touched.Address?.City}
										required
									/>
								</div>
							</Grid3>
							<Grid3>
								<div>
									<TextField
										label="Mobiltelefon"
										name="PhoneNumber"
										fullWidth
										variant="outlined"
										margin="dense"
										value={PhoneNumber}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="PhoneNumber"/>}
										error={props.errors.PhoneNumber as unknown as boolean && props.touched.PhoneNumber}
										required
									/>
								</div>
								<div>
									<TextField
										label="IBAN"
										name="IBAN"
										fullWidth
										variant="outlined"
										margin="dense"
										value={IBAN}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="IBAN"/>}
										error={props.errors.IBAN as unknown as boolean && props.touched.IBAN}
									/>
								</div>
								<div></div>
							</Grid3>
							{(mode === "add" || mode === "edit") &&
								(<>
										<TextField
											type="number"
											label="Stundenlohn(€/h)"
											name="HourlyRate"
											fullWidth
											variant="outlined"
											margin="dense"
											value={HourlyRate}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="HourlyRate"/>}
											error={props.errors.HourlyRate as unknown as boolean && props.touched.HourlyRate}
											required
										/>
										<TextField
											select={true}
											label="Prio"
											name="Prio"
											fullWidth
											variant="outlined"
											margin="dense"
											value={Prio}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="Prio"/>}
											error={props.errors.Prio as unknown as boolean && props.touched.Prio}
											required
										>
											<MenuItem value={0}>{prioToLabel(0)}</MenuItem>
											<MenuItem value={1}>{prioToLabel(1)}</MenuItem>
											<MenuItem value={2}>{prioToLabel(2)}</MenuItem>
										</TextField>
									</>
								)}
							<div style={{display: "flex", paddingBottom: "40px"}}>
								<FormControl aria-autocomplete={"none"}>
									<FormGroup>
										<Field
											type="checkbox"
											component={CheckboxWithLabel}
											name="IsTaxesEnabled"
											value={IsTaxesEnabled}
											Label={{label: "Steuern auf der Abrechnung anrechnen?"}}
											checked={IsTaxesEnabled}
										/>
									</FormGroup>
								</FormControl>
							</div>
							{(mode === "add" || mode === "edit") &&
								(
									<div style={{display: "flex", paddingBottom: "40px"}}>
										<FormControl aria-autocomplete={"none"}>
											<FormLabel component="legend">Kunden:</FormLabel>
											<FormGroup row={true}>
												{clients.map(client => (
													<Field
														type="checkbox"
														component={CheckboxWithLabel}
														name="Clients"
														key={client.Id}
														value={client.Id}
														Label={{label: client.ShortName}}
													/>
												))}
											</FormGroup>
										</FormControl>
									</div>
								)}

							<div style={{display: "flex", paddingBottom: "40px"}}>
								<FormControl aria-autocomplete={"none"}>
									<FormLabel component="legend" style={{textAlign: "left"}}>Sprachen wählen (Bitte
										beachte, dass einige Sprachen zu einer Gruppe gehören und daher nur als eine
										gesamte Gruppe ausgewählt werden können!)</FormLabel>
									{Languages.length === 0 && (
										<FormHelperText style={{color: "red"}}>
											Mindestens eine Sprache auswählen!
										</FormHelperText>
									)}
									<FormGroup row={true}>
										{PersischOptions.map(language => (
											<Field
												type="checkbox"
												component={CheckboxWithLabel}
												name="Languages"
												key={language.displayName}
												value={language.displayName}
												Label={{label: language.displayName}}
												onChange={languageChange}
											/>
										))}
									</FormGroup>
									<Divider/>
									<FormGroup row={true}>
										{RussianOptions.map(language => (
											<Field
												type="checkbox"
												component={CheckboxWithLabel}
												name="Languages"
												key={language.displayName}
												value={language.displayName}
												Label={{label: language.displayName}}
												onChange={languageChange}

											/>
										))}
									</FormGroup>
									<Divider/>
									<FormGroup row={true}>
										{KurdischOptions.map(language => (
											<Field
												type="checkbox"
												component={CheckboxWithLabel}
												name="Languages"
												key={language.displayName}
												value={language.displayName}
												Label={{label: language.displayName}}
												onChange={languageChange}
											/>
										))}
									</FormGroup>
									<Divider/>
									<FormGroup row={true}>
										{PakistanischOptions.map(language => (
											<Field
												type="checkbox"
												component={CheckboxWithLabel}
												name="Languages"
												key={language.displayName}
												value={language.displayName}
												Label={{label: language.displayName}}
												onChange={languageChange}
											/>
										))}
									</FormGroup>
									<Divider/>
									<FormGroup row={true}>
										{GeneralLanguageOptions.map(language => (
											<Field
												type="checkbox"
												component={CheckboxWithLabel}
												name="Languages"
												key={language.displayName}
												value={language.displayName}
												Label={{label: language.displayName}}
												onChange={languageChange}
											/>
										))}
									</FormGroup>
								</FormControl>
							</div>
							<div>
								<div style={{display: "flex"}}>
									{onClose && (<OrangeButton onClick={onClose}>
										Abbrechen
									</OrangeButton>)}
									<OrangeButton type={"submit"}>
										Speichern
									</OrangeButton>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default InterpretersForm;
