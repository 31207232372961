import {useState} from "react";
import Job from "./Types/Job";
import {getJob} from "./Calls/jobs/getJob";
import {getNextNumber} from "./Calls/jobs/getNextNumber";
import NextNumber from "./Types/NextNumber";
import {updateJob} from "./Calls/jobs/updateJob";
import {approveConfirmationRequest} from "./Calls/jobs/approveConfirmation";
import {createPayslipRequest} from "./Calls/jobs/createPayslipRequest";
import {getJobPayslipUrl} from "./Calls/jobs/getJobPayslipUrl";
import {approvePayslipRequest} from "./Calls/jobs/approvePayslip";
import {approveInvoiceRequest} from "./Calls/jobs/approveInvoice";
import {CreateOrUpdateInvoiceFormProps} from "../Components/Invoice/CreateOrUpdateInvoiceForm";
import {createInvoiceRequest} from "./Calls/jobs/createInvoiceRequest";
import {getJobInvoiceUrl} from "./Calls/jobs/getJobInvoiceUrl";
import {getJobInvoiceConfirmationUrl} from "./Calls/jobs/getJobInvoiceConfirmationUrl";
import {unassignJob} from "./Calls/jobs/unassignJob";
import {cancelJob} from "./Calls/jobs/cancelJob";
import {dismissConfirmationRequest} from "./Calls/jobs/dismissConfirmation";
import {getAvailableInterpreters} from "./Calls/jobs/getAvailableInterpreters";
import Interpreter from "./Types/Interpreter";
import {startBidding} from "./Calls/jobs/startBidding";
import {stepBackJob} from "./Calls/jobs/stepBackJob";
import {useAuthentication} from "./useAuthentication";
import {updateJobComment} from "./Calls/jobs/updateJobComment";
import {setReminderJob} from "./Calls/jobs/setReminderJob";
import {sendReminderJob} from "./Calls/jobs/sendReminderJob";

export const useJob = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Job | null>(null);
	const [nextNumber, setNextNumber] = useState<string>('');
	const [availableInterpreters, setAvailableInterpreters] = useState<Array<Interpreter>>([]);
	const {token} = useAuthentication();

	const load = (id: string) => {
		setLoading(true);
		loadAvailableInterpreters(id);
		return getJob(token, id).then((res: Job | null) => {
			if (res) {
				setData(res);
				setLoading(false);
			}
		})
	}

	const update = (id: string, job: Job) => {
		setLoading(true);
		return updateJob(token, job, id);
	}

	const updateComment = (id: Job['Id'], comment: string = '') => {
		return updateJobComment(token, id, comment);
	}

	const approveConfirmation = (id: string) => {
		return approveConfirmationRequest(token, id);
	}

	const dismissConfirmation = (id: string) => {
		return dismissConfirmationRequest(token, id);
	}

	const approvePayslip = (id: string, sendNotification: boolean) => {
		return approvePayslipRequest(token, id, sendNotification);
	}

	const approveInvoice = (id: string, sendNotification: boolean) => {
		return approveInvoiceRequest(token, id, sendNotification);
	}

	const loadNextNumber = (id: string) => {
		return getNextNumber(token, id).then((res: NextNumber | null) => {
			if (res) {
				setNextNumber(`${res.Prefix}-${res.Counter}`);
			}
		})
	}

	const createPayslip = (id: string, hours: number, grant: number) => {
		return createPayslipRequest(token, id, {hours, grant});
	}

	const createInvoice = (id: string, data: CreateOrUpdateInvoiceFormProps) => {
		return createInvoiceRequest(token, id, data);
	}

	const createCancelInvoice = (id: string, data: CreateOrUpdateInvoiceFormProps) => {
		return createInvoiceRequest(token, id, data);
	}

	const getPayslipPreview = (id: string) => {
		return getJobPayslipUrl(token, id);
	}

	const getInvoicePreview = (id: string) => {
		return getJobInvoiceUrl(token, id);
	}

	const getInvoiceConfirmationPreview = (id: string) => {
		return getJobInvoiceConfirmationUrl(token, id);
	}

	const unassign = (id: string) => {
		return unassignJob(token, id);
	}

	const loadAvailableInterpreters = (id: string) => {
		return getAvailableInterpreters(token, id).then(interpreters => setAvailableInterpreters(interpreters));
	}

	const cancel = (id: string, under24H: boolean) => {
		return cancelJob(token, id, under24H);
	}

	const stepBack = (id: string) => {
		return stepBackJob(token, id);
	}

	const triggerBidding = (id: string, interpreters: Array<Interpreter['Id']>) => {
		return startBidding(token, id, interpreters);
	}

	const setReminder = (id: string, value: boolean) => {
		return setReminderJob(token, id, value);
	}

	const sendReminder = (id: string) => {
		return sendReminderJob(token, id);
	}

	return {
		loading,
		data,
		load,
		token,
		getNextNumber,
		nextNumber,
		loadNextNumber,
		update,
		approveConfirmation,
		createPayslip,
		getPayslipPreview,
		approvePayslip,
		approveInvoice,
		createInvoice,
		getInvoicePreview,
		getInvoiceConfirmationPreview,
		unassign,
		cancel,
		createCancelInvoice,
		dismissConfirmation,
		loadAvailableInterpreters,
		availableInterpreters,
		triggerBidding,
		stepBack,
		updateComment,
		setReminder,
		sendReminder
	}
}
