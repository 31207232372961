import Client, {ClientWithRelations} from './Client'
import Address from './Address'
import Patient from "./Patient";
import Payslip from "./Payslip";
import dayjs from "dayjs";
import Interpreter from "./Interpreter";
import Invoice from "./Invoice";

type Job = {
	Id: string;
	NumberPrefix: string;
	NumberCounter: number;
	ClientId: string;
	Date: dayjs.Dayjs | string;
	Address: Address;
	InterpreterId?: string;
	Interpreter?: Interpreter;
	Language: string;
	Patient?: Patient;
	Status: JobStatus;
	UpdatedAt: dayjs.Dayjs;
	CreatedAt: dayjs.Dayjs;
	Client: ClientWithRelations;
	ConfirmationSystemId: string | null;
	ConfirmationFileId: string | null;
	Payslip?: Payslip;
	Invoice?: Invoice;
	ExternReference: string | null;
	Comment?: string;
	EnableReminder: boolean;
}

export type JobWithRelations = Job & {
	Interpreter: Interpreter;
}

export type JobWithConfirmation = JobWithRelations & {
	ConfirmationInterpreter: {
		DriveStart: string;
		DriveEnd: string;
		WorkingStart: string;
		WorkingEnd: string;
	};
}

export const JobStatus = {
	Open: 'Open',
	Bidding: 'Bidding',
	Assigned: 'Assigned',
	Confirmed: 'Confirmed',
	AwaitConfirmation: 'AwaitConfirmation',
	ApproveConfirmation: 'ApproveConfirmation',
	ApproveInterpreterPay: 'ApproveInterpreterPay',
	ApproveClientPay: 'ApproveClientPay',
	Closed: 'Closed',
	Canceled: 'Canceled',
	CanceledUnder24H: 'CanceledUnder24H'
};

export type JobStatus = (typeof JobStatus)[keyof typeof JobStatus]


export default Job;