import {useEffect, useState} from "react";
import Client from "./Types/Client";
import Interpreter from "./Types/Interpreter";
import {getInterpreters} from "./Calls/interpreters/getInterpreters";
import {deactivateInterpreters} from "./Calls/interpreters/deactivateInterpreters";
import {activateInterpreters} from "./Calls/interpreters/activateInterpreters";
import {deleteInterpreters} from "./Calls/interpreters/deleteInterpreters";
import {registerInterpreters} from "./Calls/interpreters/registerInterpreters";
import {useSearchParams} from "react-router-dom";
import {useAuthentication} from "./useAuthentication";

export const useInterpreters = (withFilers = false) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [interpreters, setInterpreters] = useState<Interpreter[]>([]);
	const [searchParams] = useSearchParams();
	const {token} = useAuthentication();

	useEffect(() => {
		if (token && !withFilers) {
			setLoading(true);
			loadInterpreters();
		}
	}, [token]);

	useEffect(() => {
		if (token && withFilers) {
			loadInterpreters(searchParams.toString());
		}
	}, [token, searchParams]);

	const loadInterpreters = (query = '') => {
		getInterpreters(token, query).then((data: Interpreter[]) => {
			if (data) {
				setInterpreters(data);
				setLoading(false);
			}
		})
	}

	const requestDeactivateInterpreter = (id: Interpreter['Id']) => {
		return deactivateInterpreters(id, token);
	}

	const requestActivateInterpreter = (id: Client['Id']) => {
		return activateInterpreters(id, token);
	}
	const requestDeleteInterpreter = (id: Client['Id']) => {
		return deleteInterpreters(id, token);
	}

	const requestRegisterInterpreter = (interpreter: Omit<Interpreter, "Id">) => {
		return registerInterpreters(token, interpreter);
	}

	return {
		loading,
		interpreters,
		loadInterpreters,
		requestDeactivateInterpreter,
		requestActivateInterpreter,
		requestDeleteInterpreter,
		requestRegisterInterpreter
	}
}
