import Invoice from "../../Api/Types/Invoice";
import {Chip} from "@mui/material";

type Props = {
	invoice?: Invoice
}
const InvoiceStatus = ({invoice}: Props) => {
	let lable = 'Unbekannt'
	switch (invoice?.Status) {
		case undefined:
			lable = 'Nicht erstellt';
			break;
		case "Open":
			lable = 'Erstellt, nicht versendet';
			break;
		case "Sent":
			lable = 'Versendet';
			break;
	}

	return (<Chip label={`Status: ${lable}`}/>)
}

export default InvoiceStatus;