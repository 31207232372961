import {JobWithRelations} from "../../../Api/Types/Job";
import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import JobStatusChip from "../../../Components/Job/JobStatusChip";

type Props = {
	jobs: Array<JobWithRelations>;
}

export const TasksList = ({jobs}: Props) => {
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>#</TableCell>
						<TableCell>Nummer</TableCell>
						<TableCell>Datum</TableCell>
						<TableCell>Aufgabe</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{jobs.map((job, index) => (
						<TableRow component={Link}
						          hover={true}
						          key={job.Id}
						          href={`/${NavigationStructure.jobs.url}/${job.Id}/edit`}
						          target="_blank"
						          underline="none"
						>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{`${job.NumberPrefix}-${job.NumberCounter}`}</TableCell>
							<TableCell>{dayjs(job.Date).format("DD.MM.YYYY")}</TableCell>
							<TableCell>
								<JobStatusChip status={job.Status}/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default TasksList;