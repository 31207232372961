import React, {useEffect, useState} from "react";

import dayjs, {Dayjs} from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import {JobDateOptions} from "../../../Types/JobDateOptions";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import FiltersDropdown from "../../../Components/FiltersDropdown/FiltersDropdown";
import {Chip, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {Clear} from "@mui/icons-material";
import {useClients} from "../../../Api/useClients";
import {usePayslips} from "../../../Api/usePayslips";
import PayslipsLIst from "./PayslipsList";
import OrangeButton from "../../../Components/OrangeButton/OrangeButton";

dayjs.extend(utc);
dayjs.extend(isoWeek);

export const InterpreterPayslipPage = () => {
	const {interpreterId} = useParams();
	const {data, loading, total} = usePayslips(interpreterId || '');
	const {activeClients, loading: loadingClients} = useClients();
	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

	const [selectedClients, setSelectedClients] = useState<Array<string>>(searchParams.getAll('clients') || []);
	const [selectedFromDate, setSelectedFromDate] = useState<JobDateOptions>('all');
	const [from, setFrom] = useState<undefined | Dayjs>(dayjs(searchParams.get('from')).isValid() ? dayjs(searchParams.get('from')) : undefined);
	const [to, setTo] = useState<undefined | Dayjs>(dayjs(searchParams.get('to')).isValid() ? dayjs(searchParams.get('to')) : undefined);

	useEffect(() => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('clients');
		selectedClients.forEach(client => {
			params.append('clients', client);
		});

		params.delete('from');
		if (from && dayjs(from).isValid()) {
			params.append('from', dayjs(from).format('YYYY-MM-DD'));
		}

		params.delete('to');
		if (to && dayjs(to).isValid()) {
			params.append('to', dayjs(to).format('YYYY-MM-DD'));
		}


		setSearchParams(params.toString());
	}, [selectedClients, searchParams, from, to])

	const deleteFilters = () => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('clients');
		params.delete('statuses');
		params.delete('interpreters');
		params.delete('to');
		params.delete('from');
		setSelectedClients([]);
		setSearchParams(params.toString());
		setTo(undefined);
		setFrom(undefined);
		setSelectedFromDate('all');
	}

	const onChangeClient = (checked: boolean, value: string) => {
		if (checked) {
			setSelectedClients([...selectedClients, value]);
		} else {
			setSelectedClients([...selectedClients.filter(client => client !== value)]);
		}
	}

	const handleDateFilterPreset = (event: any) => {
		const value = event.target.value as JobDateOptions;
		setSelectedFromDate(value);
		if (value === 'all') {
			setFrom(undefined);
			setTo(undefined);
		} else if (value === 'thisWeek') {
			const start = dayjs().startOf('week');
			const end = dayjs().endOf('week');
			setFrom(start);
			setTo(end);
		} else if (value === 'nextWeek') {
			const start = dayjs().add(1, 'week').startOf('week');
			const end = dayjs().add(1, 'week').endOf('week');
			setFrom(start);
			setTo(end);
		}
	}

	return (
		<Page headline={NavigationStructure.interpreterPayslips.display}>
			<div style={{display: "flex"}}>
				<FiltersDropdown
					label={'Kunde'}
					onChange={onChangeClient}
					values={selectedClients}
					options={activeClients.map(client => ({name: client.ShortName, value: client.Id}))}
				/>
				<FormControl>
					<InputLabel>Datum</InputLabel>
					<Select
						value={selectedFromDate}
						label="Datum"
						onChange={handleDateFilterPreset}
					>
						<MenuItem value={'all'}>Alles</MenuItem>
						<MenuItem value={'thisWeek'}>Diese Woche</MenuItem>
						<MenuItem value={'nextWeek'}>Nächste Woche</MenuItem>
						<MenuItem value={'custom'}>Eigener Filter</MenuItem>
					</Select>
				</FormControl>
				{selectedFromDate === 'custom' && (
					<div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label="Von"
								defaultValue={from}
								format="DD.MM.YYYY"
								onChange={(newValue) => setFrom(dayjs(newValue))}/>
							<DatePicker
								label="Bis"
								defaultValue={to}
								format="DD.MM.YYYY"
								onChange={(newValue) => setTo(dayjs(newValue))}
							/>
						</LocalizationProvider>
					</div>
				)}
				<div style={{display: "flex"}}>
					<div>
						<IconButton onClick={deleteFilters}>
							<Clear/>
						</IconButton>
					</div>
				</div>
			</div>
			<div>
				<div>
					{selectedClients.length > 0 && (
						<div>
							<span>Kundenfilter:</span>
							{selectedClients.map(client => (
								<Chip label={activeClients.find(item => item.Id === client)?.Name}/>))}
						</div>
					)}
				</div>
			</div>
			{(loading || loadingClients) && (
				<div>
					<CircularProgress/>
				</div>
			)}
			{!loading && !loadingClients && (
				<div>
					<Typography variant={"body2"}>{`Es wurden ${total} Honoraranzeigen gefunden`}</Typography>
					<Typography variant={"body2"}>
						{`Insgesamt Netto: ${data.reduce((sum, pay,) => (sum + pay.Net), 0)} €`}
					</Typography>
					<Typography variant={"body2"}>
						{`Insgesamt Brutto: ${data.reduce((sum, pay,) => (sum + pay.Gros), 0)} €`}
					</Typography>
					<PayslipsLIst
						payrolls={data}
						sortKey={"InvoiceDate"}
						order={"asc"}
					/>
				</div>
			)}
		</Page>
	);
};

export default InterpreterPayslipPage;