import React, {useEffect} from "react";
import {
	TextField,
	MenuItem,
	TextFieldProps, CircularProgress, Divider, Typography
} from "@mui/material";
import Client from "../../Api/Types/Client";
import {ErrorMessage, Form, Formik, useFormikContext} from "formik";
import {AllLanguages} from "../../Reducers/Enums/LanguageOptions";
import OrangeButton from "../OrangeButton/OrangeButton";
import JobValidation from "./JobValidation";
import Job, {JobStatus} from "../../Api/Types/Job";
import FormikDatePicker from "../FormikDatePicker/FormikDatePicker";
import dayjs from "dayjs";
import FormikTimePicker from "../FormikTimePicker/FormikTimePicker";
import {useJob} from "../../Api/useJob";
import Grid3 from "../Grid/Grid3";
import {addressToStringHelper} from "../../Helpers/addressToString";
import compare from "../../Helpers/compare";

type Props = {
	clients: Array<Client>;
	job?: Job;
	onClose?: () => void;
	handleSubmit: any;
	mode: "edit" | "add" | "view";
}

const NextJobNumber = () => {
	const {values} = useFormikContext<{ ClientId: string }>();

	const {
		token,
		loadNextNumber,
		nextNumber
	} = useJob();

	useEffect(() => {
		if (token && values.ClientId) {
			loadNextNumber(values.ClientId)
		}
	}, [values.ClientId, token])

	return (
		<TextField
			label="Einsatznummer"
			fullWidth
			variant="outlined"
			margin="dense"
			value={nextNumber}
			disabled={true}
		/>
	);
}

export const JobForm = ({
	                        clients,
	                        job,
	                        onClose,
	                        handleSubmit,
	                        mode
                        }: Props) => {

	const initialValue: Omit<Job, "Id" | "UpdatedAt" | "CreatedAt" | "Client" | "ConfirmationSystemId" | "InterpreterId" | "ConfirmationFileId" | "EnableReminder"> = {
		Address: job?.Address || {
			City: '',
			Postcode: '',
			Street: '',
			Station: '',
			Location: ''
		},
		ClientId: job?.ClientId || '',
		NumberPrefix: job?.NumberPrefix || '',
		NumberCounter: job?.NumberCounter || 0,
		Language: job?.Language || '',
		Date: dayjs(job?.Date || Date.now()),
		Patient: job?.Patient || {
			Name: '',
			Comment: ''
		},
		Status: job?.Status || JobStatus.Open,
		ExternReference: job?.ExternReference || '',
	};

	return (<>
			<Formik
				initialValues={initialValue}
				validationSchema={JobValidation}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting, setFieldValue} = props;
					const {
						Address,
						ClientId,
						Language,
						Patient,
						ExternReference
					} = props.values;

					const foundJobAddress = clients.find(client => client.Id === ClientId)?.JobAddress;

					const updateAddressFromDB = (e: any) => {
						if (e.target.value && foundJobAddress) {
							const found = foundJobAddress.find(address => e.target.value === address.Id)
							if (found) {
								setFieldValue('Address', found);
							} else {
								setFieldValue('Address', job?.Address || {
									City: '',
									Postcode: '',
									Street: '',
									Station: '',
									Location: ''
								});
							}
						}
					}

					return (
						<Form>
							<Divider/>
							<Typography variant={"body2"} p={1}>Kundeninfo & Sprache</Typography>
							<Grid3>
								<div>
									<TextField
										select={true}
										label="Kunde"
										name="ClientId"
										fullWidth
										variant="outlined"
										margin="dense"
										value={ClientId}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="ClientId"/>}
										error={props.errors.ClientId as unknown as boolean && props.touched.ClientId}
										required
										disabled={mode !== 'add'}
									>
										{clients.sort((a, b) => compare(a.JobNumberPrefix, b.JobNumberPrefix)).map(client => (
											<MenuItem
												value={client.Id}>
												{`(Nr.:${client.Number}) ${client.ShortName} | ${client.Postcode}`}
											</MenuItem>))}
									</TextField>
								</div>
								<div>
									{(mode === "add") && (
										<NextJobNumber/>
									)}
									{(mode === "edit" || mode === "view") && job && (
										<TextField
											label="Einsatznummer"
											fullWidth
											variant="outlined"
											margin="dense"
											value={`${job.NumberPrefix}-${job.NumberCounter}`}
											disabled
										/>
									)}
								</div>
								<div>
									<TextField
										label="Externe Auftragsnummer"
										name="ExternReference"
										fullWidth
										variant="outlined"
										margin="dense"
										value={ExternReference}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="ExternReference"/>}
										disabled={mode === "view"}
									/>
								</div>
							</Grid3>
							<Grid3>
								<div>
									<TextField
										select={true}
										label="Sprache"
										name="Language"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Language}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Language"/>}
										error={props.errors.Language as unknown as boolean && props.touched.Language}
										required
										disabled={mode === "view" || (mode === "edit" && job?.Status !== JobStatus.Open)}
									>
										<MenuItem value={''} defaultValue={''}>Bitte die Sprache auswählen...</MenuItem>
										{AllLanguages.map(language => (
											<MenuItem value={language.systemName}>{language.displayName}</MenuItem>))}
									</TextField>
								</div>
								<div></div>
								<div></div>
							</Grid3>
							<Divider/>
							<Typography variant={"body2"} p={1}>Einsatzort</Typography>
							<Grid3>
								{foundJobAddress && foundJobAddress.length > 0 && (
									<>
										<div>
											<TextField
												select={true}
												label="Gespeicherte Adressen"
												fullWidth
												variant="outlined"
												margin="dense"
												defaultValue={'x'}
												onChange={updateAddressFromDB}
												disabled={mode === "view" || (mode === "edit" && job?.Status !== JobStatus.Open)}
											>
												<MenuItem value={'x'} defaultValue={'x'}>
													Gespeicherte Adresse auswählen?
												</MenuItem>
												{foundJobAddress.map(address => (
													<MenuItem value={address.Id}>
														{addressToStringHelper(address)}
													</MenuItem>
												))}
											</TextField>
										</div>
										<div></div>
										<div></div>
									</>
								)}
								<div>
									<TextField
										label="Firma"
										name="Address.Station"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.Station}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.Station"/>}
										error={props.errors.Address?.Station as unknown as boolean && props.touched.Address?.Station}
										disabled={mode === "view"}
									/>
								</div>
								<div>
									<TextField
										label="Straße"
										name="Address.Street"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.Street}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.Street"/>}
										error={props.errors.Address?.City as unknown as boolean && props.touched.Address?.City}
										disabled={mode === "view"}
										required
									/>
								</div>
								<div>
									<TextField
										label="Zusatz"
										name="Address.Location"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.Location}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.Location"/>}
										error={props.errors.Address?.Location as unknown as boolean && props.touched.Address?.Location}
										disabled={mode === "view"}
									/>
								</div>
								<div>
									<TextField
										label="PLZ"
										name="Address.Postcode"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.Postcode}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.Postcode"/>}
										error={props.errors.Address?.Postcode as unknown as boolean && props.touched.Address?.Postcode}
										disabled={mode === "view"}
										required
									/>
								</div>
								<div>
									<TextField
										label="Stadt"
										name="Address.City"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Address.City}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Address.City"/>}
										error={props.errors.Address?.City as unknown as boolean && props.touched.Address?.City}
										disabled={mode === "view"}
										required
									/>
								</div>
							</Grid3>
							<Divider/>
							<Typography variant={"body2"} p={1}>Einsatzinfos</Typography>
							<Grid3>
								<div>
									<FormikDatePicker
										name="Date"
										slotProps={{
											textField: {
												fullWidth: true,
												label: 'Datum',
												required: true
											}
										}}
										renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
											<TextField {...params} />
										)}
										disabled={mode === "view"}
									/>
								</div>
								<div>
									<FormikTimePicker
										name="Date"
										slotProps={{
											textField: {
												fullWidth: true,
												label: 'Uhrzeit',
												required: true
											}
										}}
										renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
											<TextField {...params} />
										)}
										disabled={mode === "view"}
									/>
								</div>
								<div></div>
							</Grid3>
							<Grid3>
								<div>
									<TextField
										label="Patient"
										name="Patient.Name"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Patient?.Name || ''}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Patient.Name"/>}
										disabled={mode === "view"}
									/>
								</div>
								{Patient?.Name && (
									<div>
										<FormikDatePicker
											name="Patient.BDay"
											slotProps={{textField: {fullWidth: true, label: 'Geburtstag'}}}
											renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
												<TextField {...params} />
											)}
											disabled={mode === "view"}
										/>
									</div>
								)}
								<div>
									<TextField
										label="Patient Kommentar"
										name="Patient.Comment"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Patient?.Comment}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Patient.Comment"/>}
										disabled={mode === "view"}
									/>
								</div>
							</Grid3>
							<div>
								<div style={{display: "flex", paddingTop: "20px", maxWidth: "600px", gap: "20px"}}>
									{onClose && (
										<OrangeButton onClick={onClose}>
											Zurück
										</OrangeButton>
									)}
									{mode !== "view" && (
										<OrangeButton
											type={"submit"}
											disabled={isSubmitting}
											startIcon={isSubmitting ?
												<CircularProgress size={20} color={"error"}/> : null}
										>
											{mode === 'add' ? 'Erstellen' : 'Änderungen speichern'}
										</OrangeButton>
									)}

								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default JobForm;
