import {JobWithRelations} from "../../Types/Job";

export const postBidding = async (jobId: string, interpreterId: string): Promise<JobWithRelations | null> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/jobs/${jobId}/bidding/${interpreterId}`, {
		method: "POST",
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status !== 201) {
				return null;
			} else {
				return res.json();
			}
		})
}