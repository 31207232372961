import {FormValuesConfirmationUpload} from "../Pages/ConfirmationUpload/UploadConfirmationForm";
import dayjs from "dayjs";

export const getFormData = (input: FormValuesConfirmationUpload) => {
	const formData = new FormData();
	formData.append("formFile", input.formFile);
	formData.append("name", input.fileName);
	formData.append("workNumber", input.workNumber);
	formData.append("workingStart", input.workingStart);
	formData.append("workingEnd", input.workingEnd);
	formData.append("workerName", input.workerName);
	formData.append("driveStart", input.driveStart);
	formData.append("driveEnd", input.driveEnd);
	formData.append("workingDate", dayjs(input.workingDate).format('MM/DD/YYYY HH:mm:ss'));
	return formData;
}