import Address from "../Api/Types/Address";

export const addressToDestination = (address: Address): string => {
	const city = [address.Postcode, address.City]
		.filter((word) => word)
		.join('+');
	return [address.Street, city]
		.filter((word) => word)
		.join('+');
};
