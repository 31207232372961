import {JobWithConfirmation} from "../../Types/Job";
import axios from "axios";

export const getJobConfirmationUpload = async (id: string, mode: 'upload' | 'replace' = 'upload'): Promise<JobWithConfirmation | null> => {
	return axios.get(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/jobs/${id}/${mode}/confirmation`)
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				return null;
			} else {
				return res.data;
			}
		})
}