import React, {useEffect} from "react";
import Page from "../../Components/Page/Page";
import {useParams} from "react-router-dom";
import {useBidding} from "../../Api/useBidding";
import {Alert, Card, CardContent, CircularProgress, Divider, List, ListItem, Skeleton, Typography} from "@mui/material";
import styled from "styled-components";
import dayjs from "dayjs";
import {addressToStringHelper} from "../../Helpers/addressToString";
import OrangeButton from "../../Components/OrangeButton/OrangeButton";

export type BiddingStatus = 'none' | 'won' | 'lost' | 'invalid';

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 600px;
`;

const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`

export const BiddingView = () => {
	const {jobId, offerId} = useParams();
	const {data, result, load, putBid} = useBidding();

	useEffect(() => {
		if (jobId && offerId) load(jobId, offerId);
	}, []);

	console.log(data)

	return (
		<Page>
			<ContentCenter>
				<ContentCard>
					<CardContent>
						<div>
							<Typography variant={"h5"}>Möchtest Du den Einsatz annehmen?</Typography>
							<Typography variant={"subtitle1"}>Hier findest Du die wichtigsten Informationen zum
								Einsatz. Bitte nehmen den Einsatz nur an, wenn Du ihn auch wirklich wahrnehmen
								kannst.</Typography>
							<Divider style={{margin: "10px 0"}}/>
						</div>
						{result === 'loading' && (
							<div>
								<Skeleton/>
								<Skeleton/>
								<Skeleton/>
								<Skeleton/>
								<Skeleton/>
							</div>
						)}
						{data && (
							<div>
								<Typography
									variant={"body1"}>Einsatznummer: <b>{`${data.NumberPrefix}-${data.NumberCounter}`}</b></Typography>
								<Typography
									variant={"body1"}>Einsatzdatum: <b>{dayjs((data.Date)).utc().format('DD.MM.YYYY')}</b></Typography>
								<Typography
									variant={"body1"}>Einsatzzeit: <b>{dayjs((data.Date)).utc().format('HH:mm')}</b></Typography>
								<Typography
									variant={"body1"}>Sprache: <b>{data.Language}</b></Typography>
								<Typography
									variant={"body1"}>Ort: <b>{addressToStringHelper(data.Address)}</b></Typography>
								<Divider style={{margin: "10px 0"}}/>
								{result === 'ok' && (
									<ContentCenter>
										<OrangeButton onClick={() => putBid(jobId || '', offerId || '')}>
											Einsatz annehmen
										</OrangeButton>
									</ContentCenter>
								)}
							</div>
						)}
						{result === 'requesting' && (
							<div>
								<Skeleton/>
							</div>
						)}
						{result === 'invalid' && (
							<div>
								<Alert severity="error">Du bist zu langsam gewesen. Der Einsatz wurde bereits an einen
									anderen
									Dolmetscher vergeben.</Alert>
							</div>
						)}
						{result === 'won' && (
							<div>
								<Alert severity="success">Der Einsatz wurde Dir zugewiesen. In wenigen Minuten bekommst
									Du
									eine Mail
									mit der Einsatzbestätigung und weiteren Informationen.</Alert>
							</div>
						)}
					</CardContent>
				</ContentCard>
			</ContentCenter>
		</Page>
	);
};

export default BiddingView;