import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { useField, useFormikContext } from "formik";
import React from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Props<TInputDate, TDate> = {
	name: string;
// @ts-ignore
} & Omit<DatePickerProps<TInputDate, TDate>, "onChange" | "value">;

const FormikDatePicker = <TInputDate, TDate = TInputDate>(
	props: Props<TInputDate, TDate>
) => {
	const { name, ...restProps } = props;
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();

	return (
		<DatePicker
			{...restProps}
			format="DD.MM.YYYY"
			value={dayjs(field.value || Date.now())}
			onChange={(val) => setFieldValue(name, val)}
			timezone={'UTC'}
		/>
	);
};

export default FormikDatePicker;