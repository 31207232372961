import {getHeaderTempToken} from "../../getHeader";
import Client from "../../Types/Client";
import JobInstruction from "../../Types/JobInstruction";

export const editJobInstructions = async (id: Client['Id'], data: JobInstruction[], token: string): Promise<boolean> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/clients/${id}/jobInstructions`, {
		method: "POST",
		headers: getHeaderTempToken(token),
		body: JSON.stringify(data)
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				console.log("logout");
			} else {
				return true;
			}
		}).then((res) => {
			if (res) return true;
			return false;
		})
}