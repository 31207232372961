import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import Payslip from "../../../Api/Types/Payslip";
import JobStatusChip from "../../../Components/Job/JobStatusChip";

type Props = {
	payrolls: Array<Payslip>;
	order: 'asc' | 'desc';
	sortKey: 'InvoiceDate';
}

export const Row = styled(TableRow)`

`;

export const PayslipsLIst = ({payrolls, sortKey, order}: Props) => {

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<TableSortLabel
								direction={order}
							>#
							</TableSortLabel>
						</TableCell>
						<TableCell>Honorarnummer</TableCell>
						<TableCell>
							<TableSortLabel
								active={sortKey === "InvoiceDate"}
								direction={order}
							>
								<b>Erstellungsdatum</b>
							</TableSortLabel>
						</TableCell>
						<TableCell>Netto (€)</TableCell>
						<TableCell>Brutto (€)</TableCell>
						<TableCell>Einsatzdatum</TableCell>
						<TableCell>Job Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{payrolls.map((payslip, index) => (
						<Row hover={true} key={payslip.Id}>
							<TableCell>#{index + 1}</TableCell>
							<TableCell>{payslip.Number}</TableCell>
							<TableCell>{dayjs(payslip.InvoiceDate).format("DD.MM.YYYY")}</TableCell>
							<TableCell>{payslip.Net}</TableCell>
							<TableCell>{payslip.Gros}</TableCell>
							<TableCell>{dayjs(payslip.Job.Date).format("DD.MM.YYYY")}</TableCell>
							<TableCell>
								<JobStatusChip status={payslip.Job.Status}/>
							</TableCell>
						</Row>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default PayslipsLIst;