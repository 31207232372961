import {useState} from "react";
import {getInterpreter} from "./Calls/interpreters/getInterpreter";
import Interpreter from "./Types/Interpreter";
import {editInterpreter} from "./Calls/interpreters/editInterpreter";
import {useAuthentication} from "./useAuthentication";

export const useInterpreter = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Interpreter | null>(null);
	const {token} = useAuthentication();

	const load = (id: string) => {
		setLoading(true);
		return getInterpreter(token, id).then((res: Interpreter | null) => {
			if (res) {
				setData(res);
				setLoading(false);
			}
		})
	}

	const update = (id: Interpreter['Id'], interpreter: Omit<Interpreter, "Id">) => {
		return editInterpreter(token, id, interpreter);
	}

	return {
		loading,
		data,
		load,
		token,
		update
	}
}
