import {TextField, CircularProgress} from "@mui/material";
import React from "react";
import {ErrorMessage, Form, Formik} from "formik";
import Grid3 from "../../Components/Grid/Grid3";
import OrangeButton from "../../Components/OrangeButton/OrangeButton";
import {FormikHelpers} from "formik/dist/types";
import ConfirmationFile from "../../Api/Types/ConfirmationFile";
import UpdateValidation from "./UpdateValidation";

export type FormValuesConfirmationApprove = {
	WorkingStart: string;
	WorkingEnd: string;
	DriveStart: string;
	DriveEnd: string;
}

type Props = {
	confirmation: ConfirmationFile;
	onSubmit: (values: FormValuesConfirmationApprove, formikHelpers: FormikHelpers<FormValuesConfirmationApprove>) => void | Promise<any>;
}

export const EditConfirmationForm = ({confirmation, onSubmit}: Props) => {
	const initialValue: FormValuesConfirmationApprove = {
		WorkingStart: confirmation.WorkingStart || '',
		WorkingEnd: confirmation.WorkingEnd || '',
		DriveStart: confirmation.DriveStart || '',
		DriveEnd: confirmation.DriveEnd || '',
	};

	return (
		<Formik
			initialValues={initialValue}
			validationSchema={UpdateValidation}
			onSubmit={onSubmit}
		>
			{(formik) => {
				const {
					WorkingStart,
					WorkingEnd,
					DriveStart,
					DriveEnd,
				} = formik.values;

				return (
					<Form>
						<Grid3>
							<div>
								<TextField
									inputProps={{
										placeholder: "8:00",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									label="Beginn der Hinfahrt"
									name="DriveStart"
									fullWidth
									variant="outlined"
									margin="dense"
									value={DriveStart}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									helperText={<ErrorMessage name="driveStart"/>}
									error={formik.errors.DriveStart as unknown as boolean && formik.touched.DriveStart}
								/>
							</div>
							<div>
								<TextField
									inputProps={{
										placeholder: "9:00",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									label="Beginn des Einsatzes"
									name="WorkingStart"
									fullWidth
									variant="outlined"
									margin="dense"
									value={WorkingStart}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									helperText={<ErrorMessage name="workingStart"/>}
									error={formik.errors.WorkingStart as unknown as boolean && formik.touched.WorkingStart}
								/>
							</div>
							<div>
								<TextField
									inputProps={{
										placeholder: "10:00",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									label="Ende des Einsatzes (inkl. Wartezeit)"
									name="WorkingEnd"
									fullWidth
									variant="outlined"
									margin="dense"
									value={WorkingEnd}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									helperText={<ErrorMessage name="workingEnd"/>}
									error={formik.errors.WorkingEnd as unknown as boolean && formik.touched.WorkingEnd}
								/>
							</div>
							<div>
								<TextField
									inputProps={{
										placeholder: "11:00",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									label="Ende der Rückfahrt"
									name="DriveEnd"
									fullWidth
									variant="outlined"
									margin="dense"
									value={DriveEnd}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									helperText={<ErrorMessage name="driveEnd"/>}
									error={formik.errors.DriveEnd as unknown as boolean && formik.touched.DriveEnd}
								/>
							</div>
							<div>
								{`Effektive Arbeitszeit: ${confirmation.EffectiveWorkingTime}h`}
							</div>
							<div>
								{`Arbeitszeit: ${confirmation.WorkingTime}h`}
							</div>
						</Grid3>
						<Grid3>
							<div>
								<OrangeButton type="submit"
								              disabled={formik.isSubmitting}
								              startIcon={formik.isSubmitting ?
									              <CircularProgress size={20} color={"error"}/> : null}
								>
									Anpassung speichern
								</OrangeButton>
							</div>
						</Grid3>
					</Form>
				)
			}}
		</Formik>
	);
};

export default EditConfirmationForm;
