import React, {useEffect, useState} from "react";
import Page from "../../Components/Page/Page";
import {NavigationStructure} from "../../Components/Navigation/NavigationStructure";
import {useJobs} from "../../Api/useJobs";
import {
	Typography
} from "@mui/material";
import {JobStatus, JobWithRelations} from "../../Api/Types/Job";
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import TasksList from "./List/TasksList";
import styled from "styled-components";
import {useVisbilityState} from "../../Helpers/useVisibilityState";

dayjs.extend(utc);
dayjs.extend(isoWeek);

const TableBlock = styled.div`
    padding-bottom: 80px;
`;

export const TasksView = () => {
	const {data, token, load} = useJobs(false);
	const [approveClientPay, setApproveClientPay] = useState<JobWithRelations[]>([]);
	const [approveInterpreterPay, setApproveInterpreterPay] = useState<JobWithRelations[]>([]);
	const [approveConfirmation, setApproveConfirmation] = useState<JobWithRelations[]>([]);
	const visible = useVisbilityState();

	useEffect(() => {
		if (token && (visible === "visible" || visible === null)) {
			const params = new URLSearchParams({});
			params.append('statuses', JobStatus.ApproveClientPay);
			params.append('statuses', JobStatus.ApproveInterpreterPay);
			params.append('statuses', JobStatus.ApproveConfirmation);
			params.append('take', "9999");
			params.append('sortKey', "Date");
			params.append('sortOrder', "desc");
			load(params.toString())
		}
	}, [token, visible]);

	useEffect(() => {
		setApproveClientPay(data.filter(job => job.Status === JobStatus.ApproveClientPay));
		setApproveInterpreterPay(data.filter(job => job.Status === JobStatus.ApproveInterpreterPay));
		setApproveConfirmation(data.filter(job => job.Status === JobStatus.ApproveConfirmation));
	}, [data.length]);

	return (
		<Page headline={NavigationStructure.tasks.display}>
			{data.length === 0 && (
				<div>Alle Aufgaben sind schon erledigt! 🎉</div>
			)}
			{approveClientPay.length > 0 && (
				<TableBlock>
					<Typography
						variant={"h6"}>{`Es gibt ${approveClientPay.length} Erstelle-Rechnung-Aufgaben`}</Typography>
					<TasksList
						jobs={approveClientPay}
					/>
				</TableBlock>
			)}
			{approveInterpreterPay.length > 0 && (
				<TableBlock>
					<Typography
						variant={"h6"}>{`Es gibt ${approveInterpreterPay.length} Erstelle-Honorar-Aufgaben`}</Typography>
					<TasksList
						jobs={approveInterpreterPay}
					/>
				</TableBlock>
			)}
			{approveConfirmation.length > 0 && (
				<TableBlock>
					<Typography
						variant={"h6"}>{`Es gibt ${approveConfirmation.length} Bestätige-EB-Aufgaben`}</Typography>
					<TasksList
						jobs={approveConfirmation}
					/>
				</TableBlock>
			)}
		</Page>
	);
};

export default TasksView;