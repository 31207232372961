import JobInstructionForm from "../../JobInstruction/JobInstructionView";
import Job, {JobStatus} from "../../../Api/Types/Job";
import jobFeatureAllowed from "../../../Helpers/JobFeatures";
import PreviewDocument from "../../PreviewDocument/PreviewDocument";
import OrangeButton from "../../OrangeButton/OrangeButton";
import {Cancel, Check, Download, FileUpload} from "@mui/icons-material";
import React from "react";
import ConfirmationFile from "../../../Api/Types/ConfirmationFile";
import {Actions} from "../../Actions/Actions";
import {FilePreview} from "../../../Api/Types/FilePreview";
import {useJob} from "../../../Api/useJob";
import Distance from "../../Distance/Distance";
import EditConfirmationForm, {
	FormValuesConfirmationApprove
} from "../../../Pages/ConfirmationUpload/EditConfirmationForm";
import {FormikHelpers} from "formik/dist/types";
import {useConfirmationFiles} from "../../../Api/useConfirmationFiles";
import {NavigationStructure} from "../../Navigation/NavigationStructure";
import {useNavigate} from "react-router-dom";

type Props = {
	job: Job;
	confirmationFile: ConfirmationFile;
	previewConfirmation: FilePreview | null;
}

const ManageConfirmation = ({job, confirmationFile, previewConfirmation}: Props) => {
	const navigate = useNavigate();

	const {
		dismissConfirmation,
		approveConfirmation,
	} = useJob();

	const {update} = useConfirmationFiles();


	const creation = (values: FormValuesConfirmationApprove, formikHelpers: FormikHelpers<FormValuesConfirmationApprove>) => {
		if (confirmationFile) {
			update(confirmationFile.Id, values).then(() => window.location.reload())
		}
	}

	const confirm = () => {
		if (window.confirm(`Soll die aktuelle Einsatzbestätigung mit ${confirmationFile?.WorkingTime} und ${confirmationFile?.EffectiveWorkingTime} Arbeitsstunden akzeptiert werden?`) && job) {
			approveConfirmation(job?.Id).then(() => window.location.reload())
		}
	}

	const confirmCancel = () => {
		if (window.confirm(`Soll die EB gelöscht werden und der Dolmetscher einen Upload-Link per Mail bekommen?`) && job) {
			dismissConfirmation(job?.Id).then(() => window.location.reload())
		}
	}

	const getConfirmationFileDownloadLink = (id: string) => {
		return `${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/confirmations/${id}/download`
	}

	const navigateToReplaceConfirmation = () => {
		navigate(`/${NavigationStructure.jobConfirmationReplace.getURL(job.Id)}`);
	}

	return (
		<>
			{jobFeatureAllowed(job, 'editConfirmation') && (
				<>
					<JobInstructionForm client={job.Client} status={JobStatus.ApproveConfirmation}/>
					<Distance from={job.Interpreter?.Address} to={job.Address}/>
					<EditConfirmationForm
						confirmation={confirmationFile}
						onSubmit={creation}
					/>
				</>
			)}
			{job.ConfirmationFileId && previewConfirmation && (
				<div>
					<p>Vorschau:</p>
					<PreviewDocument {...previewConfirmation}/>
					<p>
						<a target={"_blank"}
						   href={getConfirmationFileDownloadLink(job.ConfirmationFileId)}
						   rel="noreferrer">
							<Download fontSize={"small"}/>
							Download Einsatzbestätigung
						</a>
					</p>
				</div>
			)}
			{jobFeatureAllowed(job, 'approveConfirmation') && (
				<Actions>
					<OrangeButton
						onClick={confirm}
						startIcon={<Check fontSize={"small"}/>}>
						Einsatzbestätigung Annehmen
					</OrangeButton>
					<OrangeButton
						onClick={confirmCancel}
						startIcon={<Cancel fontSize={"small"}/>}>
						Neues Dokument Anfordern
					</OrangeButton>
					<OrangeButton
						onClick={navigateToReplaceConfirmation}
						startIcon={<FileUpload fontSize={"small"}/>}>
						EB Uploaden/Ersetzten
					</OrangeButton>
				</Actions>
			)}
		</>
	);
}

export default ManageConfirmation;