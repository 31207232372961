import {Chip} from "@mui/material";
import {ConfirmationFileWithRelations} from "../../Api/Types/ConfirmationFile";

type Props = {
	confirmation: ConfirmationFileWithRelations
}
export const ConfirmationFileStatusChip = ({confirmation}: Props) => {
	if (!confirmation.Job[0]) {
		return <Chip color="error" label={'Job nicht importiert'}/>;
	}
	if (confirmation.Job && confirmation.Job[0].ConfirmationFileId && confirmation.Job[0].Interpreter) {
		if (confirmation.Job[0].Interpreter.Prename !== 'Unbekannt') {
			return <Chip color="success" label={'Verknüpft'}/>;
		}
		return <Chip color="success" label={'Verknüpft aber Dolmetscher nicht importiert'}/>;
	}
	return <Chip label={'Unbekannt'}/>;
}

export default ConfirmationFileStatusChip;