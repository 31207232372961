import React, {ReactNode} from "react";
import {Typography} from "@mui/material";

type Props = {
	headline?: string;
	children: ReactNode;
}

export const Page = ({children, headline}: Props) => {
	return (
		<div style={{padding: "90px 32px 32px"}}>
			{headline && (
				<Typography variant="h5"
				            style={{
					            padding: "20px 0px 40px 0px",
					            color: "black",
					            fontFamily: "Lato",
					            fontWeight: 900,
					            fontSize: "32px"
				            }}>
					{headline}
				</Typography>
			)}
			<>
				{children}
			</>
		</div>);
}

export default Page