import * as yup from "yup";

const timeFormat = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

const helper = "Bitte die richtige Form beachten z.B. 08:00 oder 23:45";

const UploadValidationWithoutWorknumber = yup.object().shape({
	driveStart: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	workingStart: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	workingEnd: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	driveEnd: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
});

export default UploadValidationWithoutWorknumber;
