import {JobStatus} from "../Api/Types/Job";

const getStatusLabel = (status: JobStatus) => {
	switch (status) {
		case "Open":
			return 'Offen';
		case "Bidding":
			return 'Warte auf Zusage von Dolmetscher';
		case "Assigned":
			return 'Zugewiesen';
		case "Confirmed":
			return 'Von Dolmetscher bestätigt';
		case "AwaitConfirmation":
			return 'Warte auf EB';
		case "ApproveConfirmation":
			return 'Bestätige von EB';
		case "ApproveInterpreterPay":
			return 'Erstelle Honoraranzeige';
		case "ApproveClientPay":
			return 'Erstelle Rechnung';
		case "Closed":
			return 'Abgeschlossen';
		case "Canceled":
			return 'Storniert';
		case "CanceledUnder24H":
			return 'Storniert unter 24H';
		default:
			return status;
	}
}

export default getStatusLabel;