import {getHeaderTempToken} from "../../getHeader";
import {FilePreview} from "../../Types/FilePreview";

export const getJobInvoiceUrl = async (token: string, id: string): Promise<FilePreview> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/jobs/${id}/invoice/fetch`, {
		method: "GET",
		headers: getHeaderTempToken(token)
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return '';
			} else if (res.status === 401) {
				return '';
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return '';
		})
}