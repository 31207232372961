import React, {useState} from "react";
import {
	AppBar,
	Box,
	ClickAwayListener,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Toolbar
} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {NavigationStructure} from "./NavigationStructure";
import logo from '../../Svg/gebrueder-saleh.svg';
import {
	AssignmentOutlined,
	PersonAdd,
	ReceiptLong,
	RecentActorsOutlined,
	SupervisorAccount, TaskAlt
} from "@mui/icons-material";
import OrangeButton from "../OrangeButton/OrangeButton";
import {useAuthentication} from "../../Api/useAuthentication";

const Header = () => {
	const [anchorAccountMenu, setAnchorAccountMenu] = useState(null);
	const navigation = useNavigate();

	const {isAuthenticated, logout, isAdmin} = useAuthentication();

	const handleMenuOpen = (ev: any) => {
		setAnchorAccountMenu(ev.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorAccountMenu(null);
	};

	const menuGotoUrl = (siteUrl: string) => {
		navigation(siteUrl);
		handleMenuClose();
	};

	const logoutWithRedirect = () => {
		logout({
			openUrl: () => window.location.replace(window.location.origin + NavigationStructure.logout.url)
		});
	}

	const redirectLogin = () => {
		window.location.replace(window.location.origin + NavigationStructure.login.url);
	}

	return (
		<AppBar position="absolute">
			<Toolbar style={{display: "flex", backgroundColor: "#f4f7ff"}}>
				<OrangeButton
					variant="outlined"
					onClick={handleMenuOpen}
				>
					Menu
				</OrangeButton>

				<Popper
					open={!!anchorAccountMenu}
					anchorEl={anchorAccountMenu}
					disablePortal
				>
					<Paper>
						<ClickAwayListener onClickAway={handleMenuClose}>
							<MenuList>
								{isAdmin && (
									<>
										<MenuItem
											onClick={() => menuGotoUrl(
												`/${NavigationStructure.tasks.url}`
											)}
										>
											<ListItemIcon>
												<TaskAlt fontSize="small"/>
											</ListItemIcon>
											<ListItemText
												primary={NavigationStructure.tasks.display}
											/>
										</MenuItem>
										<MenuItem
											onClick={() => menuGotoUrl(
												`/${NavigationStructure.jobs.url}`
											)}
										>
											<ListItemIcon>
												<AssignmentOutlined fontSize="small"/>
											</ListItemIcon>
											<ListItemText
												primary={NavigationStructure.jobs.display}
											/>
										</MenuItem>
										<MenuItem
											onClick={() => menuGotoUrl(
												`/${NavigationStructure.interpreters.url}`
											)}
										>
											<ListItemIcon>
												<RecentActorsOutlined fontSize="small"/>
											</ListItemIcon>
											<ListItemText
												primary={NavigationStructure.interpreters.display}
											/>
										</MenuItem>
										<MenuItem
											onClick={() => menuGotoUrl(
												`/${NavigationStructure.clients.url}`
											)}
										>
											<ListItemIcon>
												<SupervisorAccount fontSize="small"/>
											</ListItemIcon>
											<ListItemText
												primary={NavigationStructure.clients.display}
											/>
										</MenuItem>
										<MenuItem
											onClick={() => menuGotoUrl(
												`/${NavigationStructure.confirmations.url}`
											)}
										>
											<ListItemIcon>
												<ReceiptLong fontSize="small"/>
											</ListItemIcon>
											<ListItemText
												primary={NavigationStructure.confirmations.display}
											/>
										</MenuItem>
									</>)}
								<MenuItem
									onClick={() => menuGotoUrl(
										`/${NavigationStructure.register.url}`
									)}
								>
									<ListItemIcon>
										<PersonAdd fontSize="small"/>
									</ListItemIcon>
									<ListItemText
										primary={NavigationStructure.register.display}
									/>
								</MenuItem>
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Popper>
				<Box style={{flex: 10, textAlign: "center"}}>
					<img src={logo} alt="Gebrueder Saleh Logo" width={212} height={75}/>
				</Box>
				{isAuthenticated && (
					<OrangeButton
						variant="outlined"
						onClick={logoutWithRedirect}
					>
						Logout
					</OrangeButton>
				)}
				{!isAuthenticated && (
					<OrangeButton
						variant="outlined"
						onClick={redirectLogin}
					>
						Login
					</OrangeButton>
				)}
			</Toolbar>
		</AppBar>
	);

}

export default Header;