import * as yup from "yup";

const InterpreterValidation = yup.object().shape({
	Prename: yup
		.string()
		.required("Bitte angeben!"),
	Surname: yup
		.string()
		.required("Bitte angeben!"),
	Address: yup.object({
		Street: yup
			.string()
			.required("Bitte angeben!"),
		Postcode: yup
			.string()
			.required("Bitte angeben!"),
		City: yup
			.string()
			.required("Bitte angeben!"),
	}),
	Mail: yup
		.string()
		.email("Muss eine gültige E-Mail sein!")
		.required("Bitte angeben!"),
	Languages: yup
		.array()
		.required("Mindestens eine Sprache auswählen!"),
	PhoneNumber: yup
		.string()
		.required("Bitte angeben!")
});

export default InterpreterValidation;
