import * as yup from "yup";

const timeFormat = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

const helper = "Bitte die richtige Form beachten z.B. 08:00 oder 23:45";

const UploadValidation = yup.object().shape({
	workerName: yup.string()
		.required("Bitte angeben"),
	workNumber: yup.string()
		.min(4, "Die Nummer wie auf der Einsatzbestätigung")
		.required("Die Nummer wie auf der Einsatzbestätigung")
		.test({
			message: "Bitte prüfe die eingegebene Einsatznummer!", test: (schema) => {
				if (schema) {
					if (schema.toLowerCase().startsWith('laiv')) {
						return true;
					}
					if (schema.toLowerCase().startsWith('ob')) {
						return true;
					}
					if (schema.toLowerCase().startsWith('proarbeit')) {
						return true;
					}
					if (schema.toLowerCase().startsWith('d')) {
						return true;
					}
					if (schema.toLowerCase().startsWith('rf')) {
						return true;
					}
					if (schema.toLowerCase().startsWith('2') || schema.toLowerCase().startsWith('3')) {
						return true;
					}
				}
				console.log(schema)
				return false;
			}
		}),
	driveStart: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	workingStart: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	workingEnd: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	driveEnd: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
});

export default UploadValidation;
