import React, {useEffect, useState} from "react";
import Page from "../../Components/Page/Page";
import {NavigationStructure} from "../../Components/Navigation/NavigationStructure";
import {useSearchParams} from "react-router-dom";
import {
	CircularProgress,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import dayjs, {Dayjs} from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import ConfirmationsList from "./List/ConfirmationsList";
import {Clear} from "@mui/icons-material";
import {ConfirmationFilesSortKeys} from "../../Types/ConfirmationFilesSortKeys";
import {ConfirmationFileDateOptions} from "../../Types/ConfirmationFileDateOptions";
import {useConfirmationFiles} from "../../Api/useConfirmationFiles";
import OrangeButton from "../../Components/OrangeButton/OrangeButton";

dayjs.extend(utc);
dayjs.extend(isoWeek);

export const ConfirmationsView = () => {
	const {data, loading, total, downloadExport} = useConfirmationFiles();
	const [searchParams, setSearchParams] = useSearchParams();

	const [selectedFromDate, setSelectedFromDate] = useState<ConfirmationFileDateOptions>('all');
	const [take] = useState<string>(searchParams.get('take') || '100');
	const [skip, setSkip] = useState<string>(searchParams.get('skip') || '0');
	const [page, setPage] = useState<number>(Number(searchParams.get('skip')) / 100);
	const [search, setSearch] = useState<string>(searchParams.get('search') || '');
	const [sortKey, setSortKey] = useState<ConfirmationFilesSortKeys>(searchParams.get('sortKey') as ConfirmationFilesSortKeys || 'WorkNumber');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(searchParams.get('sortOrder') as 'asc' | 'desc' || 'asc');
	const [from, setFrom] = useState<undefined | Dayjs>(dayjs(searchParams.get('from')).isValid() ? dayjs(searchParams.get('from')) : undefined);
	const [to, setTo] = useState<undefined | Dayjs>(dayjs(searchParams.get('to')).isValid() ? dayjs(searchParams.get('to')) : undefined);

	useEffect(() => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('from');
		if (from && dayjs(from).isValid()) {
			params.append('from', dayjs(from).format('YYYY-MM-DD'));
		}

		params.delete('to');
		if (to && dayjs(to).isValid()) {
			params.append('to', dayjs(to).format('YYYY-MM-DD'));
		}

		params.delete('take');
		if (take) params.append('take', take);

		params.delete('skip');
		if (take) params.append('skip', skip);

		params.delete('search');
		if (search) params.append('search', search);

		params.delete('sortKey');
		if (sortKey) params.append('sortKey', sortKey);

		params.delete('sortOrder');
		if (sortOrder) params.append('sortOrder', sortOrder);

		setSearchParams(params.toString());
	}, [searchParams, skip, take, search, sortKey, sortOrder, from, to])

	const deleteFilters = () => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('to');
		params.delete('from');
		setSearchParams(params.toString());
		setPage(1);
		setSkip('0');
		setSearch('');
		setTo(undefined);
		setFrom(undefined);
		setSelectedFromDate('all');
	}

	const handlePaginationChange = (e: any, page: number) => {
		setSkip(`${(page - 1) * 100}`);
		setPage(page);
	}

	const handleSearchChange = (e: any) => {
		setPage(1);
		setSkip('0');
		setSearch(e.target.value);
	}

	const createSortHandler = (key: ConfirmationFilesSortKeys) => {
		if (key === sortKey) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		}
		setSortKey(key);
	}

	const handleDateFilterPreset = (event: any) => {
		const value = event.target.value as ConfirmationFileDateOptions;
		setSelectedFromDate(value);
		if (value === 'all') {
			setFrom(undefined);
			setTo(undefined);
		} else if (value === 'last30') {
			const start = dayjs().add(-30, 'days');
			const end = dayjs();
			setFrom(start);
			setTo(end);
		} else if (value === 'last60') {
			const start = dayjs().add(-60, 'days');
			const end = dayjs();
			setFrom(start);
			setTo(end);
		}
	}

	return (
		<Page headline={NavigationStructure.confirmations.display}>
			<div style={{display: "flex", justifyContent: "end", marginBottom: "20px"}}>
				<OrangeButton onClick={downloadExport}>Download CSV</OrangeButton>
				<div>{/*Download*/}</div>
			</div>
			<div style={{display: "flex"}}>
				<TextField
					value={search}
					onChange={handleSearchChange}
					label={"Einsatznummer"}
				/>
				<FormControl>
					<InputLabel>Datum</InputLabel>
					<Select
						value={selectedFromDate}
						label="Datum"
						onChange={handleDateFilterPreset}
					>
						<MenuItem value={'all'}>Alles</MenuItem>
						<MenuItem value={'last30'}>Letzten 30 Tage</MenuItem>
						<MenuItem value={'last60'}>Letzten 60 Tage</MenuItem>
						<MenuItem value={'custom'}>Eigener Filter</MenuItem>
					</Select>
				</FormControl>
				{selectedFromDate === 'custom' && (
					<div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label="Von"
								defaultValue={from}
								format="DD.MM.YYYY"
								onChange={(newValue) => setFrom(dayjs(newValue))}/>
							<DatePicker
								label="Bis"
								defaultValue={to}
								format="DD.MM.YYYY"
								onChange={(newValue) => setTo(dayjs(newValue))}
							/>
						</LocalizationProvider>
					</div>
				)}
				<div style={{display: "flex"}}>
					<div>
						<IconButton onClick={deleteFilters}>
							<Clear/>
						</IconButton>
					</div>
				</div>
			</div>
			{(loading) && (
				<div>
					<CircularProgress/>
				</div>
			)}
			{!loading && (
				<div>
					<div>
						<Typography variant={"body2"}>
							{`Es wurden ${total} EBs gefunden`}
						</Typography>
						<Pagination
							count={Math.ceil(total / 100)}
							onChange={handlePaginationChange}
							style={{marginTop: "15px"}}
							page={page}
						/>
						<ConfirmationsList
							confirmations={data}
							order={sortOrder}
							createSortHandler={createSortHandler}
							sortKey={sortKey}
						/>
						<Pagination
							count={Math.ceil(total / 100)}
							onChange={handlePaginationChange}
							style={{marginTop: "15px"}}
							page={page}
						/>
					</div>
				</div>
			)}
		</Page>
	);
};

export default ConfirmationsView;