import React from "react";
import {
	Divider
} from "@mui/material";
import {ClientWithRelations} from "../../Api/Types/Client";
import OrangeButton from "../OrangeButton/OrangeButton";
import {Form, Formik} from "formik";
import {useClient} from "../../Api/useClient";
import Grid from "../Grid/Grid";
import {JobStatus} from "../../Api/Types/Job";
import getStatusLabel from "../../Helpers/getStatusLabel";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
	client?: ClientWithRelations;
	onClose: () => void;
	onError?: (e: any) => void;
}

export const JobInstructionForm = ({client, onClose, onError}: Props) => {
	const initialValue: Pick<ClientWithRelations, "JobInstruction"> = {
		JobInstruction: client?.JobInstruction || []
	};

	const {requestEditJobInstructions} = useClient();

	const handleSubmit = (values: typeof initialValue) => {
		if (client?.Id) {
			requestEditJobInstructions(client.Id, values.JobInstruction).then(() => onClose()).catch(onError);
		}
	}

	return (
		<div>
			<Formik
				initialValues={initialValue}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting, setFieldValue} = props;

					const {
						JobInstruction
					} = props.values;

					const handleChange = (value: string, name: string) => {
						if (name) {
							const index = JobInstruction.findIndex(instruction => instruction.Status === name);
							if (index === -1) {
								// not found
								JobInstruction.push({
									Status: name,
									Instruction: [value]
								})
								setFieldValue('JobInstruction', JobInstruction);
							} else {
								JobInstruction[index].Instruction = [value];
								setFieldValue('JobInstruction', JobInstruction);
							}
						}

					}

					return (
						<Form>
							<Grid>
								{Object.keys(JobStatus).map(status => {
									if (status === JobStatus.CanceledUnder24H) {
										return null;
									}

									const found = JobInstruction.find(instruction => instruction.Status === status);

									return (
										<div>
											<p>Für den Status <b>"{getStatusLabel(status)}"</b> gilt folgender
												Hinweis:</p>
											<ReactQuill
												theme="snow"
												value={found?.Instruction[0] || ''}
												onChange={(value) => handleChange(value, status)} id={status}
											/>
											<Divider/>
										</div>
									);
								})}
							</Grid>
							<div style={{
								display: "flex",
								paddingTop: "16px",
								justifyContent: "flex-end",
								gap: "8px"
							}}>
								<OrangeButton onClick={onClose} disabled={isSubmitting}>
									Abbrechen
								</OrangeButton>
								<OrangeButton type={"submit"} disabled={isSubmitting}>
									Speichern
								</OrangeButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}

export default JobInstructionForm;