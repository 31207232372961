import {ClientWithRelations} from "../Api/Types/Client";
import {JobStatus} from "../Api/Types/Job";

export const getJobInstructionByStatus = (client?: ClientWithRelations, status?: JobStatus) => {
	if (client && status) {
		const value = client.JobInstruction.find(instruction => instruction.Status === status)?.Instruction[0];
		if (value) return value;
		return '';
	}
	return '';
}