import React from "react";
import Page from "../../../Components/Page/Page";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import JobForm from "../../../Components/Job/JobForm";
import {useClients} from "../../../Api/useClients";
import {useJobs} from "../../../Api/useJobs";
import {useNavigate} from "react-router-dom";

export const JobAddView = () => {
	const navigate = useNavigate();
	const {activeClients} = useClients();
	const {create} = useJobs();

	const onClose = () => {
		goToList()
	}

	const goToList = () => {
		navigate(`/${NavigationStructure.jobs.url}`)
	}

	const goToJob = (id: string) => {
		navigate(`/${NavigationStructure.jobs.url}/${id}/edit`)
	}

	const handleSubmit = (values: any) => {
		create(values).then(res => {
			if (res) goToJob(res.Id)
		})
	}

	return (
		<Page headline={NavigationStructure.jobAdd.display}>
			<JobForm
				handleSubmit={handleSubmit}
				onClose={onClose}
				clients={activeClients}
				mode={"add"}
			/>
		</Page>
	);
};

export default JobAddView;