import {getHeaderTempToken} from "../../getHeader";
import Interpreter from "../../Types/Interpreter";

export const editInterpreter = async (token: string, id: Interpreter['Id'], interpreter: Omit<Interpreter, "Id">): Promise<Interpreter> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/interpreters/${id}`, {
		method: "PUT",
		headers: getHeaderTempToken(token),
		body: JSON.stringify(interpreter)
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				console.log("logout");
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return {};
		})
}