import React, {useEffect, useState} from "react";
import {
	Snackbar,
	SnackbarContent,
	CircularProgress
} from "@mui/material";

import Interpreter from "../../../Api/Types/Interpreter";
import {useClients} from "../../../Api/useClients";
import {useNavigate, useParams} from "react-router-dom";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import InterpretersForm from "../../../Components/Interpreter/InterpretersForm";
import {useInterpreter} from "../../../Api/useInterpreter";

export const InterpretersView = () => {
	const {interpreterId} = useParams();
	const navigate = useNavigate();

	const [okOpened, setOkOpened] = useState(false);
	const [okMessage, setOkMessage] = useState("");
	const [errorOpened, setErrorOpened] = useState(false);
	const [error, setError] = useState("");

	const {
		data,
		load,
		loading,
		token,
		update
	} = useInterpreter();

	const {activeClients} = useClients();

	useEffect(() => {
		if (interpreterId && token) load(interpreterId);
	}, [interpreterId, token]);

	const handleCloseOk = () => {
		setOkMessage("");
		setOkOpened(false);
	};

	if (error) return (
		<Page headline={NavigationStructure.interpreterEdit.display}>
			<center>
				{error}
			</center>
		</Page>
	);

	if (loading) return (
		<Page headline={NavigationStructure.interpreterEdit.display}>
			<center>
				<CircularProgress/>
			</center>
		</Page>
	);

	const handleSubmit = async (updatedInterpreter: any) => {
		if (data?.Id) {
			update(data.Id, updatedInterpreter as Interpreter).then(() => {
					onClose();
				})
				.catch((error) => {
					let message = "Ein unerwartetes Problem ist aufgetreten. Bitte erneut versuchen.";
					if (error.status === 400) {
						message = "Bitte die Eingabe überprüfen.";
					}
					setError(message);
					setErrorOpened(true);
				});
		}
	}
	const onClose = () => {
		//TODO how to get previous search params as URL
		navigate(-1);
	}

	return (
		<Page headline={NavigationStructure.interpreterEdit.display}>
			<Snackbar
				open={okOpened}
				autoHideDuration={6000}
				onClose={handleCloseOk}
				anchorOrigin={{vertical: "top", horizontal: "center"}}
			>
				<SnackbarContent
					style={{
						backgroundColor: "#4caf50",
					}}
					message={okMessage}
				/>
			</Snackbar>
			<Snackbar
				open={errorOpened}
				autoHideDuration={6000}
				onClose={() => setErrorOpened(false)}
				anchorOrigin={{vertical: "top", horizontal: "center"}}
			>
				<SnackbarContent
					style={{
						backgroundColor: "#f44336",
					}}
					message={error}
				/>
			</Snackbar>
			<InterpretersForm
				interpreter={data ? data : undefined}
				clients={activeClients}
				handleSubmit={handleSubmit}
				onClose={onClose}
				mode="edit"
			/>
		</Page>);
}

export default InterpretersView;