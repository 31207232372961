import {useEffect, useState} from "react";
import Job, {JobWithRelations} from "./Types/Job";
import {getJobs} from "./Calls/jobs/getJobs";
import {createJob} from "./Calls/jobs/createJob";
import {useSearchParams} from "react-router-dom";
import {useAuthentication} from "./useAuthentication";

export const useJobs = (autoLoad = true) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<JobWithRelations[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [searchParams] = useSearchParams();
	const {token} = useAuthentication();

	useEffect(() => {
		if (token && autoLoad) {
			load(searchParams.toString());
		}
	}, [token, searchParams]);

	const load = (query: string = '') => {
		setLoading(true);
		getJobs(token, query).then((res) => {
			if (res) {
				setData(res.results);
				setTotal(res.total);
				setLoading(false);
			}
		}).catch(error => {
			alert(`Ein Fehler ist aufgetreten: ${error}`);
			setData([]);
			setTotal(0);
			setLoading(false);
		})
	}

	const create = (job: Omit<Job, "Id">) => {
		return createJob(token, job);
	}

	return {
		loading,
		data,
		load,
		create,
		total,
		token
	}
}
