import {getHeaderTempToken} from "../../getHeader";
import Client from "../../Types/Client";

export const activateClient = async (id: Client['Id'], token: string): Promise<boolean> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/clients/${id}/activate`, {
		method: "PUT",
		headers: getHeaderTempToken(token),
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				console.log("logout");
			} else {
				return true;
			}
		}).then((res) => {
			if (res) return true;
			return false;
		})
}