import {JobWithRelations} from "../../Api/Types/Job";
import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import {NavigationStructure} from "../../Components/Navigation/NavigationStructure";
import {JobsSortKeys} from "../../Types/JobsSortKeys";
import JobStatusChip from "../../Components/Job/JobStatusChip";

type Props = {
	jobs: Array<JobWithRelations>;
	order: 'asc' | 'desc';
	sortKey: JobsSortKeys;
	createSortHandler: (key: JobsSortKeys) => void;
}

export const JobsLIst = ({jobs, createSortHandler, sortKey, order}: Props) => {
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>#</TableCell>
						<TableCell>
							<TableSortLabel
								active={sortKey === "NumberCounter"}
								direction={order}
								onClick={() => createSortHandler("NumberCounter")}
							>
								<b>Nummer</b>
							</TableSortLabel>
						</TableCell>
						<TableCell>Kunde</TableCell>
						<TableCell>Externe Nummer</TableCell>
						<TableCell>
							<TableSortLabel
								active={sortKey === "Date"}
								direction={order}
								onClick={() => createSortHandler("Date")}
							>
								<b>Datum</b>
							</TableSortLabel>
						</TableCell>
						<TableCell>Uhrzeit</TableCell>
						<TableCell>Sprache</TableCell>
						<TableCell>Dolmetscher</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Erinnerung</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{jobs.map((job, index) => (
						<TableRow component={Link}
						          hover={true}
						          key={job.Id}
						          href={`/${NavigationStructure.jobs.url}/${job.Id}/edit`}
						          target="_blank"
						          underline="none"
						>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{`${job.NumberPrefix}-${job.NumberCounter}`}</TableCell>
							<TableCell>{job.Client.ShortName}</TableCell>
							<TableCell>{job.ExternReference ? job.ExternReference : '-'}</TableCell>
							<TableCell>{dayjs(job.Date).format("DD.MM.YYYY")}</TableCell>
							<TableCell>{dayjs(job.Date).utc().format("HH:mm")}</TableCell>
							<TableCell>{job.Language}</TableCell>
							<TableCell>{job.Interpreter ? `${job.Interpreter.Prename} ${job.Interpreter.Surname}` : '-'}</TableCell>
							<TableCell>
								<JobStatusChip status={job.Status}/>
							</TableCell>
							<TableCell>{job.EnableReminder ? 'aktiv' : 'nicht aktiv'}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default JobsLIst;