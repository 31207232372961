import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import {ConfirmationFileWithRelations} from "../../../Api/Types/ConfirmationFile";
import {ConfirmationFilesSortKeys} from "../../../Types/ConfirmationFilesSortKeys";
import {Download, Edit} from "@mui/icons-material";
import ConfirmationFileStatusChip from "../../../Components/ConfirmationFile/ConfirmationFileStatusChip";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";

type Props = {
	confirmations: Array<ConfirmationFileWithRelations>;
	order: 'asc' | 'desc';
	sortKey: ConfirmationFilesSortKeys;
	createSortHandler: (key: ConfirmationFilesSortKeys) => void;
}

export const Row = styled(TableRow)`
`;

export const ConfirmationsList = (
	{
		confirmations, createSortHandler, sortKey, order
	}: Props) => {

	// TODO make it better
	const onClick = (id: string) => {
		console.log(id);
	}

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<TableSortLabel
								active={sortKey === "WorkNumber"}
								direction={order}
								onClick={() => createSortHandler("WorkNumber")}
							>
								<b>#</b>
							</TableSortLabel>
						</TableCell>
						<TableCell>Dolmetscher</TableCell>
						<TableCell> Einsatzdatum </TableCell>
						<TableCell>Beginn der Hinfahrt</TableCell>
						<TableCell>Beginn des Einsatzes</TableCell>
						<TableCell>Ende des Einsatzes</TableCell>
						<TableCell>Ende der Rückfahrt</TableCell>
						<TableCell>Effektive Arbeitszeit</TableCell>
						<TableCell>Arbeitszeit</TableCell>
						<TableCell>
							<TableSortLabel
								active={sortKey === "CreatedAt"}
								direction={order}
								onClick={() => createSortHandler("CreatedAt")}
							>
								<b>Hochgeladen</b>
							</TableSortLabel>
						</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Download</TableCell>
						<TableCell>Edit</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{confirmations.map(confirmation => (
						<Row onClick={() => onClick(confirmation.Id)} hover={true} key={confirmation.Id}>
							<TableCell>{confirmation.WorkNumber}</TableCell>
							<TableCell>{confirmation.WorkerName}</TableCell>
							<TableCell>{dayjs(confirmation.WorkingDay).format("DD.MM.YYYY")}</TableCell>
							<TableCell>{confirmation.DriveStart}</TableCell>
							<TableCell>{confirmation.WorkingStart}</TableCell>
							<TableCell>{confirmation.WorkingEnd}</TableCell>
							<TableCell>{confirmation.DriveEnd}</TableCell>
							<TableCell>{confirmation.EffectiveWorkingTime}</TableCell>
							<TableCell>{confirmation.WorkingTime}</TableCell>
							<TableCell>{dayjs(confirmation.CreatedAt).format("DD.MM.YYYY")}</TableCell>
							<TableCell>
								<ConfirmationFileStatusChip confirmation={confirmation}/>
							</TableCell>
							<TableCell>
								<IconButton
									href={`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/files/confirmation-uploaded/${confirmation.Id}/download`}
									target={"_blank"}
									rel="noreferrer"
								>
									<Download/>
								</IconButton>
							</TableCell>
							<TableCell>
								<IconButton
									href={`/${NavigationStructure.confirmationEdit.getURL(confirmation.Id)}`}
									target={"_blank"}
									rel="noreferrer"
								>
									<Edit/>
								</IconButton>
							</TableCell>
						</Row>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default ConfirmationsList;