import {useEffect, useState} from "react";
import {getConfirmationFileById} from "./Calls/confirmationFiles/getConfirmationFile";
import {FormValuesConfirmationApprove} from "../Pages/ConfirmationUpload/EditConfirmationForm";
import {updateConfirmationFileById} from "./Calls/confirmationFiles/updateConfirmationFile";
import {getConfirmationFileUrl} from "./Calls/confirmationFiles/getConfirmationFileUrl";
import {ConfirmationFileWithRelations} from "./Types/ConfirmationFile";
import {searchConfirmationFiles} from "./Calls/confirmationFiles/searchConfirmationFiles";
import {useSearchParams} from "react-router-dom";
import {downloadCSVExport} from "./Calls/confirmationFiles/downloadCSVExport";
import {useAuthentication} from "./useAuthentication";
import {renameConfirmationFileById} from "./Calls/confirmationFiles/renameConfirmationFile";

export const useConfirmationFiles = () => {
	const {token} = useAuthentication();
	const [loading, setLoading] = useState<boolean>(false);
	const [requesting, setRequesting] = useState<boolean>(false);
	const [data, setData] = useState<ConfirmationFileWithRelations[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (token) {
			setLoading(true);
			load(searchParams.toString());
		}
	}, [token, searchParams]);

	const getById = (id: string) => {
		return getConfirmationFileById(token, id);
	}

	const update = (id: string, data: FormValuesConfirmationApprove) => {
		return updateConfirmationFileById(token, id, data);
	}

	const getUrl = (id: string) => {
		return getConfirmationFileUrl(token, id);
	}

	const load = (query: string = '') => {
		searchConfirmationFiles(token, query).then((res) => {
			if (res) {
				setData(res.results);
				setTotal(res.total);
				setLoading(false);
			}
		}).catch(error => {
			alert(`Ein Fehler ist aufgetreten: ${error}`);
			setData([]);
			setTotal(0);
			setLoading(false);
		})
	}

	const downloadExport = () => {
		return downloadCSVExport(token);
	}

	const rename = (id: string, name: string) => {
		setRequesting(true);
		return renameConfirmationFileById(token, id, {WorkNumber: name}).finally(() => {
			setRequesting(false)
		});
	}

	return {
		token,
		getById,
		update,
		getUrl,
		load,
		loading,
		data,
		total,
		downloadExport,
		rename,
		requesting
	}
}
