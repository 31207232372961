import {getHeader} from "../../getHeader";
import axios from "axios";

export const downloadCSVExport = (token: string) => {
	return axios.get(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/confirmations/export/csv`, {
			headers: getHeader(token),
		})
		.then((res) => {
			if (res.status !== 200) {
				throw res;
			}
			if (res.data) {
				const url = window.URL.createObjectURL(
					new Blob([res.data], {type: 'text/csv'})
				);
				const link = document.createElement("a");
				link.href = url;
				link.download = res.headers['x-file-name'] || 'export';
				link.target = "_blank";
				document.body.appendChild(link);
				link.click();
				link?.parentNode?.removeChild(link);
			}
		})
}

