import Job, {JobStatus} from "../Api/Types/Job";

type JobFeatures =
	'openInterpreter'
	| 'openConfirmation'
	| 'editConfirmation'
	| 'approveConfirmation'
	| 'editPayslip'
	| 'openPayslip'
	| 'showPayslip'
	| 'approvePayslip'
	| 'editInvoice'
	| 'openInvoice'
	| 'approveInvoice'
	| 'showUploadConfirmation'
	| 'unassigedAllowed'
	| 'cancelAllowed'
	| 'editDetails'
	| 'createCancelInvoice'
	| 'stepBack'
	| 'sendConfirmationReminder';

const jobFeatureAllowed = (job: Job | null, feature: JobFeatures): boolean => {
	if (job) {
		switch (feature) {
			case "openInterpreter":
				return job.Status === JobStatus.Open;
			case "openConfirmation":
				return job.Status === JobStatus.ApproveConfirmation;
			case "editConfirmation":
				return job.Status === JobStatus.ApproveConfirmation;
			case "approveConfirmation":
				return job.Status === JobStatus.ApproveConfirmation;
			case "editPayslip":
				return job.Status === JobStatus.ApproveInterpreterPay;
			case "openPayslip":
				return job.Status === JobStatus.ApproveInterpreterPay;
			case "sendConfirmationReminder":
				return job.Status === JobStatus.AwaitConfirmation;
			case "approvePayslip":
				return job.Status === JobStatus.ApproveInterpreterPay && job.Payslip !== null;
			case "editInvoice":
				return job.Status === JobStatus.ApproveClientPay;
			case "openInvoice":
				return job.Status === JobStatus.ApproveClientPay;
			case "approveInvoice":
				return job.Status === JobStatus.ApproveClientPay && job.Invoice !== null && !!job.ConfirmationFileId && !!job.Invoice?.InvoiceFileId;
			case "showUploadConfirmation":
				return job.Status === JobStatus.Assigned || job.Status === JobStatus.AwaitConfirmation;
			case "unassigedAllowed":
				return job.Status === JobStatus.Assigned || job.Status === JobStatus.AwaitConfirmation;
			case "cancelAllowed":
				return !(job.Status === JobStatus.Closed || job.Status === JobStatus.Canceled || job.Status === JobStatus.CanceledUnder24H);
			case "editDetails":
				return job.Status === JobStatus.Open || job.Status === JobStatus.Assigned || job.Status === JobStatus.AwaitConfirmation;
			case "createCancelInvoice":
				return job.Status === JobStatus.Canceled || job.Status === JobStatus.CanceledUnder24H;
			case "stepBack":
				return job.Status === JobStatus.ApproveInterpreterPay || job.Status === JobStatus.ApproveClientPay || job.Status === JobStatus.Closed;
		}
	}

	return false;
}

export default jobFeatureAllowed;