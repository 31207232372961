import React from "react";
import {ClientWithRelations} from "../../Api/Types/Client";
import {JobStatus} from "../../Api/Types/Job";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {getJobInstructionByStatus} from "../../Helpers/getJobInstructionByStatus";
import {Divider} from "@mui/material";

type Props = {
	client?: ClientWithRelations;
	status?: JobStatus;
}

export const JobInstructionForm = ({client, status}: Props) => {
	const value = getJobInstructionByStatus(client, status);

	return (
		<div>
			{value && (
				<>
					<p>Bitte folgenden Hinweis beachten:</p>
					<ReactQuill
						theme="snow"
						value={value}
						readOnly={true}
						modules={{
							toolbar: false
						}}
					/>
					<Divider style={{margin: "10px"}}/>
				</>)
			}
		</div>
	);
}

export default JobInstructionForm;