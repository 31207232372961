import React, {useState} from "react";
import {useInterpreters} from "../../Api/useInterpreters";
import Interpreter from "../../Api/Types/Interpreter";
import {Snackbar, SnackbarContent} from "@mui/material";
import InterpretersForm from "../../Components/Interpreter/InterpretersForm";
import Page from "../../Components/Page/Page";

export const RegisterView = () => {
	const {requestRegisterInterpreter} = useInterpreters();

	const [errorOpened, setErrorOpened] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [registered, setRegistered] = useState(false);

	const handleOnError = (text: string) => {
		setErrorMessage(text);
		setErrorOpened(true);
	};

	const handleCloseError = () => {
		setErrorMessage("");
		setErrorOpened(false)
	};

	const handleSubmit = async (newInterpreter: any) => {
		requestRegisterInterpreter(newInterpreter as Interpreter).then(() => {
				setRegistered(true)
			})
			.catch((error) => {
				let message = "Ein unerwartetes Problem ist aufgetreten. Bitte erneut versuchen.";
				if (error.status === 400) {
					message = `Bitte die Eingabe überprüfen. Wurde die E-Mail ${newInterpreter.Mail} schon verwendet?`;
				}
				handleOnError(message);
			});
	}

	return (
		<Page headline={registered ? "Anmeldung abgeschlossen!" : "Anmeldung"}>
			<div>
				{!registered && (
					<InterpretersForm
						clients={[]}
						handleSubmit={handleSubmit}
						mode="register"
					/>
				)}
				{registered && (
					<div>
						<h3>Deine Anmeldung war erfolgreich. Wir werden uns in den kommenden Tagen bei Dir
							melden!</h3>
					</div>
				)}

			</div>

			<Snackbar
				open={errorOpened}
				autoHideDuration={6000}
				onClose={handleCloseError}
				anchorOrigin={{vertical: "top", horizontal: "center"}}
			>
				<SnackbarContent
					style={{
						backgroundColor: "#f44336",
					}}
					message={errorMessage}
				/>
			</Snackbar>
		</Page>
	);
};

export default RegisterView;