import React from "react";
import {
	CircularProgress, FormGroup
} from "@mui/material";

import {Field, Form, Formik} from "formik";
import OrangeButton from "../OrangeButton/OrangeButton";
import JobValidation from "./JobValidation";
import Job, {JobStatus} from "../../Api/Types/Job";
import Interpreter from "../../Api/Types/Interpreter";
import {CheckboxWithLabel} from "formik-material-ui";

type Props = {
	job: Job;
	handleSubmit: any;
	interpreters: Array<Interpreter>;
	methode: MatchingMethode;
}

export type JobMatchingFormValues = {
	selectedInterpreters: Array<Interpreter['Id']>,
};

export type MatchingMethode = 'normal' | 'toAll' | 'toSome';

export const JobMatchingForm = ({
	                                job,
	                                handleSubmit,
	                                interpreters,
	                                methode
                                }: Props) => {

	const initialValue: JobMatchingFormValues = {
		selectedInterpreters: interpreters.map(interpreter => interpreter.Id),
	};

	return (<>
			<Formik
				initialValues={initialValue}
				validationSchema={JobValidation}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting} = props;
					const {
						selectedInterpreters
					} = props.values;

					return (
						<Form>
							<div>
								<FormGroup row={true}>
									{interpreters.map(interpreter => (
										<Field
											type="checkbox"
											component={CheckboxWithLabel}
											name="selectedInterpreters"
											key={interpreter.Id}
											value={interpreter.Id}
											Label={{label: `${interpreter.Prename} ${interpreter.Surname} | ${interpreter.Address.City}`}}
											onChange={props.handleChange}
											disabled={methode === "toAll"}
										/>
									))}
								</FormGroup>
							</div>
							<div>
								<div style={{display: "flex", paddingTop: "20px", maxWidth: "600px", gap: "20px"}}>
									<OrangeButton
										type={"submit"}
										disabled={job.Status !== JobStatus.Open || isSubmitting || selectedInterpreters.length === 0}
										startIcon={isSubmitting ? <CircularProgress size={20} color={"error"}/> : null}
									>
										Matching starten
									</OrangeButton>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default JobMatchingForm;
