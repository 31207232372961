import React from "react";
import {
	Paper
} from "@mui/material";

export const LogoutView = () => {
	return (
		<Paper style={{padding: "20px"}}>
			Du wurdest ausgeloggt.
		</Paper>
	);
}

export default LogoutView;
