import React from "react";
import {
	TextField,
	MenuItem,
	CircularProgress
} from "@mui/material";

import {ErrorMessage, Field, Form, Formik} from "formik";
import OrangeButton from "../OrangeButton/OrangeButton";
import JobValidation from "./JobValidation";
import Job, {JobStatus} from "../../Api/Types/Job";
import Grid3 from "../Grid/Grid3";
import Interpreter from "../../Api/Types/Interpreter";
import {CheckboxWithLabel} from "formik-material-ui";

type Props = {
	job: Job;
	handleSubmit: any;
	interpreters: Array<Interpreter>;
}

export type JobAssignFormValues = {
	InterpreterId: string,
	SendNotifications: boolean,
};

export const JobAssignForm = ({
	                              job,
	                              handleSubmit,
	                              interpreters
                              }: Props) => {

	const initialValue: JobAssignFormValues = {
		InterpreterId: job?.InterpreterId || '',
		SendNotifications: true
	};

	return (<>
			<Formik
				initialValues={initialValue}
				validationSchema={JobValidation}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting} = props;
					const {
						InterpreterId,
						SendNotifications
					} = props.values;

					return (
						<Form>
							<Grid3>
								<div>
									<TextField
										select={true}
										label="Dolmetscher"
										name="InterpreterId"
										fullWidth
										variant="outlined"
										margin="dense"
										value={InterpreterId}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="InterpreterId"/>}
										error={props.errors.InterpreterId as unknown as boolean && props.touched.InterpreterId}
										disabled={job.Status !== JobStatus.Open}
									>
										{interpreters.map(interpreter => (
											<MenuItem
												value={interpreter.Id}
												key={interpreter.Id}
											>{`${interpreter.Prename} ${interpreter.Surname} | ${interpreter.Address.City}`}
											</MenuItem>))}
									</TextField>
								</div>
								<div></div>
								<div></div>
							</Grid3>
							<div>
								Alle theoretisch möglichen Dolmetscher für diesen Kunden und diese
								Sprache: <b>{interpreters.map(inter => `${inter.Prename} ${inter.Surname}`).join(', ')}</b>
							</div>
							<div>
								<Field
									type="checkbox"
									component={CheckboxWithLabel}
									name="SendNotifications"
									value={SendNotifications}
									Label={{label: "Dolmetscher wird benachrichtigt"}}
									checked={SendNotifications}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									disabled={job.Status !== JobStatus.Open}
								/>
							</div>
							<div>
								<div style={{display: "flex", paddingTop: "20px", maxWidth: "600px", gap: "20px"}}>
									<OrangeButton
										type={"submit"}
										disabled={job.Status !== JobStatus.Open || isSubmitting || InterpreterId === ''}
										startIcon={isSubmitting ? <CircularProgress size={20} color={"error"}/> : null}
									>
										Dolmetscher zuweisen
									</OrangeButton>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default JobAssignForm;
