import React from "react";
import ClientsList from "../../../Components/Clients/ClientsList"
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";

export const ClientsListPage = () => {
	return (
		<Page headline={NavigationStructure.clients.display}>
			<ClientsList/>
		</Page>
	);
};

export default ClientsListPage;