import React from "react";
import Page from "../../Components/Page/Page";

export const HomeView = () => {
	return (
		<Page headline="Willkommen bei Gebrüder Saleh Dolmetscherservice Hub">
			<div></div>
		</Page>
	);
};

export default HomeView;