import {getHeaderTempToken} from "../../getHeader";
import Payslip from "../../Types/Payslip";

export const getInterpreterPayslips = async (token: string, id: string, query: string): Promise<{
	results: Payslip[],
	total: number
}> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/interpreters/${id}/payslips?${query}`, {
		method: "GET",
		headers: getHeaderTempToken(token),
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				console.log("logout");
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return [];
		})
}