import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import Payslip from "./Types/Payslip";
import {getInterpreterPayslips} from "./Calls/interpreters/getInterpreterPayslips";
import {useAuthentication} from "./useAuthentication";

export const usePayslips = (interpreterId: string) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Payslip[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [searchParams] = useSearchParams();
	const {token} = useAuthentication();

	useEffect(() => {
		if (token) {
			setLoading(true);
			load(searchParams.toString());
		}
	}, [token, searchParams]);

	const load = (query: string = '') => {
		getInterpreterPayslips(token, interpreterId, query).then((res) => {
			if (res) {
				setData(res.results);
				setTotal(res.total);
				setLoading(false);
			}
		}).catch(error => {
			alert(`Ein Fehler ist aufgetreten: ${error}`);
			setData([]);
			setTotal(0);
			setLoading(false);
		})
	}

	return {
		loading,
		data,
		load,
		total
	}
}
