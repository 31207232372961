import {FilePreview} from "../../Api/Types/FilePreview";
import React from "react";

const PreviewDocument = ({url, fileType}: FilePreview) => {
	const style = {
		width: "100%",
		maxWidth: "1000px",
		minHeight: "800px"
	}
	if (fileType === "application/pdf") {
		return (<object data={url} type={"application/pdf"} style={style}/>)
	}
	return (<img src={url} width={'auto'} height={'auto'} style={style}/>)
}

export default PreviewDocument