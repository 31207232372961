import React from "react";
import {
	TextField,
	CircularProgress, TextFieldProps
} from "@mui/material";

import {ErrorMessage, Form, Formik} from "formik";
import OrangeButton from "../OrangeButton/OrangeButton";
import Grid2 from "../Grid/Grid2";
import {Dayjs} from "dayjs";
import FormikDatePicker from "../FormikDatePicker/FormikDatePicker";

type Props = {
	quantity: number;
	rate: number;
	distanceEnabled: boolean;
	distanceValue: number;
	fixEnabled: boolean;
	fixValue: number;
	handleSubmit: any;
	distanceQuantity: number;
	comment: string;
	invoiceDate: string | Dayjs;
}

export type CreateOrUpdateInvoiceFormProps = Omit<Props, 'handleSubmit'>;

export const CreateOrUpdateInvoiceForm = ({
	                                          quantity,
	                                          rate,
	                                          distanceEnabled,
	                                          distanceValue,
	                                          fixEnabled,
	                                          fixValue,
	                                          distanceQuantity,
	                                          handleSubmit,
	                                          comment,
	                                          invoiceDate
                                          }: Props) => {

	const initialValue: CreateOrUpdateInvoiceFormProps = {
		quantity,
		rate,
		distanceEnabled,
		distanceValue,
		distanceQuantity,
		fixEnabled,
		fixValue,
		comment,
		invoiceDate
	};

	return (
		<>
			<Formik
				initialValues={initialValue}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting} = props;
					const {
						quantity,
						rate,
						distanceValue,
						distanceQuantity,
						fixValue,
						comment,
					} = props.values;

					return (
						<Form>
							<Grid2>
								<div>
									<FormikDatePicker
										name="invoiceDate"
										slotProps={{
											textField: {
												fullWidth: true,
												label: 'Rechnungsdatum',
												required: true,
												helperText: 'Datum der Rechnung'
											}
										}}
										renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
											<TextField {...params} />
										)}
									/>
								</div>
								<div>
									<TextField
										label="Kommentar"
										name="comment"
										fullWidth
										variant="outlined"
										margin="dense"
										value={comment}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={"Hier kann z.B. der Abfahrtsort eingetragen werden"}
									/>
								</div>
								<div>
									<TextField
										inputProps={{
											type: "number",
										}}
										type="number"
										label="Arbeitsstunden"
										name="quantity"
										fullWidth
										variant="outlined"
										margin="dense"
										value={quantity}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="quantity"/>}
										onFocus={(e) => e.target.addEventListener("wheel", function (e) {
											e.preventDefault()
										}, {passive: false})}
									/>
								</div>
								<div>
									<TextField
										inputProps={{
											type: "number",
										}}
										type="number"
										label="Stundensatz in €"
										name="rate"
										fullWidth
										variant="outlined"
										margin="dense"
										value={rate}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="rate"/>}
										onFocus={(e) => e.target.addEventListener("wheel", function (e) {
											e.preventDefault()
										}, {passive: false})}
										disabled
									/>
								</div>
							</Grid2>
							{distanceEnabled && (
								<Grid2>
									<div>
										<TextField
											inputProps={{
												type: "number",
											}}
											type="number"
											label="Kilometer"
											name="distanceQuantity"
											fullWidth
											variant="outlined"
											margin="dense"
											value={distanceQuantity}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="distanceQuantity"/>}
											onFocus={(e) => e.target.addEventListener("wheel", function (e) {
												e.preventDefault()
											}, {passive: false})}
										/>
									</div>
									<div>
										<TextField
											inputProps={{
												type: "number",
											}}
											type="number"
											label="Kilometerpreis"
											name="distanceValue"
											fullWidth
											variant="outlined"
											margin="dense"
											value={distanceValue}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="distanceValue"/>}
											onFocus={(e) => e.target.addEventListener("wheel", function (e) {
												e.preventDefault()
											}, {passive: false})}
											disabled
										/>
									</div>
								</Grid2>
							)}
							{fixEnabled && (
								<Grid2>
									<div>
										<TextField
											inputProps={{
												type: "number",
											}}
											type="number"
											label="Pauschale"
											name="fixValue"
											fullWidth
											variant="outlined"
											margin="dense"
											value={fixValue}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="fixValue"/>}
											onFocus={(e) => e.target.addEventListener("wheel", function (e) {
												e.preventDefault()
											}, {passive: false})}
										/>
									</div>
								</Grid2>
							)}
							<div>
								<div style={{display: "flex", paddingTop: "20px", maxWidth: "600px", gap: "20px"}}>
									<OrangeButton
										type={"submit"}
										disabled={isSubmitting}
										startIcon={isSubmitting ? <CircularProgress size={20} color={"error"}/> : null}
									>
										Rechnung speichern und generieren
									</OrangeButton>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default CreateOrUpdateInvoiceForm;
