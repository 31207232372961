import {useEffect, useState} from "react";
import Client from "./Types/Client";
import {getClients} from "./Calls/clients/getClients";
import {useAuthentication} from "./useAuthentication";

export const useClients = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [clients, setClients] = useState<Client[]>([]);
	const [activeClients, setActiveClients] = useState<Client[]>([]);
	const {token} = useAuthentication();

	useEffect(() => {
		if (token) {
			setLoading(true);
			loadClients();
		}
	}, [token]);

	useEffect(() => {
		setActiveClients(clients.filter(client => client.Status === 'Active'))
	}, [clients]);

	const loadClients = () => {
		getClients(token).then((data: Client[]) => {
			if (data) {
				setClients(data);
				setLoading(false);
			}
		})
	}

	return {
		loading,
		clients,
		activeClients,
		loadClients,
	}
}
