import Job from "../../Api/Types/Job";
import Interpreter from "../../Api/Types/Interpreter";
import Address from "../../Api/Types/Address";
import {addressToDestination} from "../../Helpers/addressToDestination";
import styled from "styled-components";

type Props = {
	from?: Interpreter['Address'];
	to: Job['Address'];
}

const Main = styled.div`
    margin: 32px 0;
    background: #61dafb50;
    padding: 16px;
    border-radius: 10px;
`;

export const Distance = ({from, to}: Props) => {

	const fromString = addressToDestination(from || {} as Address);
	const toString = addressToDestination(to);
	const link = `https://www.google.com/maps/dir/?api=1&origin=${fromString}&destination=${toString}`

	return (
		<Main>
			<p><b>Willst du wissen wie der Dolmetscher gefahren ist?</b></p>
			<p>Von: {fromString.replaceAll("+", " ")}</p>
			<p>Nach: {toString.replaceAll("+", " ")}</p>
			<p>
				<a href={link} target={"_blank"} rel="noreferrer">Link zu Google Maps</a>
			</p>
		</Main>
	);
}


export default Distance
