import React, {useEffect, useState} from "react";
import {
	DialogTitle,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Snackbar,
	SnackbarContent,
	CircularProgress,
	Grid
} from "@mui/material";

import {styled} from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledInterpreterIcon from '@mui/icons-material/HowToReg';
import EnabledInterpreterIcon from '@mui/icons-material/PersonOff';
import DeleteInterpreterIcon from '@mui/icons-material/DeleteForever';
import Interpreter from "../../../Api/Types/Interpreter";
import Client from "../../../Api/Types/Client";
import {useInterpreters} from "../../../Api/useInterpreters";
import {useClients} from "../../../Api/useClients";
import OrangeButton from "../../../Components/OrangeButton/OrangeButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDebounce} from "../../../Helpers/useDebounce";
import InterpretersList from "../../../Components/Interpreter/InterpreterList";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import {AllLanguages} from "../../../Reducers/Enums/LanguageOptions";

export const InterpretersView = () => {
	const [deactivateDialogOpened, setDeactivateDialogOpened] = useState(false);
	const [deactivateId, setDeactivateId] = useState<null | Interpreter['Id']>(null);
	const [deletionDialogOpened, setDeletionDialogOpened] = useState(false);
	const [deletionID, setDeletionID] = useState<null | Interpreter['Id']>(null);
	const [activateDialogOpened, setActivateDialogOpened] = useState(false);
	const [activateId, setActivateId] = useState<null | Interpreter['Id']>(null);
	const [okOpened, setOkOpened] = useState(false);
	const [okMessage, setOkMessage] = useState("");
	const [errorOpened, setErrorOpened] = useState(false);
	const [error, setError] = useState("");
	const [requesting, setRequesting] = useState(false);

	const [interpreters, setInterpreters] = useState<Array<Interpreter>>([]);

	const [searchParams, setSearchParams] = useSearchParams();
	const [order, setOrder] = useState<"asc" | "desc">(searchParams.get('order') as 'asc' | 'desc' || 'asc');
	const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || "Prename");
	const [language, setLanguage] = useState(searchParams.get('language') || "");

	const [statusFilterEnabled, setStatusFilterEnabled] = useState<boolean>(false);
	const [statusFilterType, setStatusFilterType] = useState<'Active' | 'Deactivated' | ''>('');

	// TODO find the proper names
	const [clientsFilter, setClientsFilter] = useState<{
		title: string,
		id: string
	}[]>(searchParams.getAll('clients').map(id => ({id, title: id})) || []);

	const [search, setSearch] = useState(searchParams.get('search') || "");
	const searchDebounced = useDebounce(search, 1000);

	useEffect(() => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('status');
		if (statusFilterType) params.append('status', statusFilterType);

		params.delete('search');
		if (searchDebounced) params.append('search', searchDebounced);

		params.delete('clients');
		clientsFilter.forEach(client => {
			params.append('clients', client.id);
		});

		params.delete('orderBy');
		params.append('orderBy', orderBy);

		params.delete('order');
		params.append('order', order);

		params.delete('language');
		if (language) params.append('language', language);

		setSearchParams(params.toString());
	}, [statusFilterType, clientsFilter, orderBy, order, searchDebounced, searchParams, language])

	const {
		interpreters: loadedInterpreters,
		loadInterpreters,
		loading,
		requestDeactivateInterpreter,
		requestActivateInterpreter,
		requestDeleteInterpreter
	} = useInterpreters(true);
	const {clients: customers} = useClients();

	useEffect(() => {
		setInterpreters(loadedInterpreters);
	}, [loadedInterpreters])

	const openDeactivateDialog = (interpreterId: Interpreter['Id']) => {
		setDeactivateId(interpreterId);
		setDeactivateDialogOpened(true);
	}

	const openActivateDialog = (interpreterId: Interpreter['Id']) => {
		setActivateId(interpreterId);
		setActivateDialogOpened(true);
	}

	const openDeletionDialog = (interpreterId: Interpreter['Id']) => {
		setDeletionID(interpreterId);
		setDeletionDialogOpened(true);
	}

	const closeDeactivateDialog = () => {
		setDeactivateDialogOpened(false);
		setDeactivateId(null);
	};

	const closeActivateDialog = () => {
		setActivateDialogOpened(false);
		setDeactivateId(null);
	};

	const closeDeletionDialog = () => {
		setDeletionDialogOpened(false);
		setDeletionID(null);
	};

	const handleDeactivate = () => {
		if (deactivateId !== null) {
			requestDeactivateInterpreter(deactivateId).then(() => {
				setOkMessage("Der Dolmetscher wurde deaktiviert.");
				setOkOpened(true);
				loadInterpreters(searchParams.toString());
				closeDeactivateDialog();
			}).catch((error) => {
				let message = "Ein unerwartetes Problem ist aufgetreten. Bitte erneut versuchen.";
				if (error.status === 400) {
					message = "Die Anfrage konnte nicht verarbeitet werden";
				}
				if (error.status === 404) {
					message = "Der Dolmetscher konnte im System nicht gefunden werden";
				}
				setError(message);
				setErrorOpened(true);
			}).finally(() => {
				setRequesting(false);
			});
		}
	};

	const handleActivate = () => {
		if (activateId !== null) {
			requestActivateInterpreter(activateId).then(() => {
				setOkMessage("Der Dolmetscher wurde aktiviert.");
				setOkOpened(true);
				loadInterpreters(searchParams.toString());
				closeActivateDialog();
			}).catch((error) => {
				let message = "Ein unerwartetes Problem ist aufgetreten. Bitte erneut versuchen.";
				if (error.status === 400) {
					message = "Die Anfrage konnte nicht verarbeitet werden";
				}
				if (error.status === 404) {
					message = "Der Dolmetscher konnte im System nicht gefunden werden";
				}
				setError(message);
				setErrorOpened(true);
			}).finally(() => {
				setRequesting(false);
			});
		}
	};

	const handleDeletion = () => {
		if (deletionID !== null) {
			requestDeleteInterpreter(deletionID).then(() => {
					setOkMessage("Der Dolmetscher wurde geloescht.");
					setOkOpened(true);
					loadInterpreters(searchParams.toString());
					closeDeletionDialog();
				})
				.catch((error) => {
					let message = "Ein unerwartetes Problem ist aufgetreten. Bitte erneut versuchen.";
					if (error.status === 400) {
						message = "Die Anfrage konnte nicht verarbeitet werden";
					}
					if (error.status === 404) {
						message = "Der Dolmetscher konnte im System nicht gefunden werden";
					}
					setError(message);
					setErrorOpened(true);
				}).finally(() => {
				setRequesting(false);
			});
		}
	};

	const handleCloseOk = () => {
		setOkMessage("");
		setOkOpened(false);
	};


	const filterHandleChange = (event: any, activator: boolean) => {
		let filterEnabledResult = !statusFilterEnabled;
		setStatusFilterEnabled(filterEnabledResult);

		let filterTypeResult: "Active" | "Deactivated" | "" = filterEnabledResult ? activator ? "Active" : "Deactivated" : "";
		setStatusFilterType(filterTypeResult);
	}

	function mapCustomers() {
		return customers.filter(client => client.Status === 'Active').map((customer: Client) => ({
			title: `${customer.ShortName} | ${customer.Postcode}`,
			id: customer.Id
		}))
	}

	function mapLanguages() {
		return AllLanguages.map((item) => (item.systemName));
	}

	const clientsFilterHandleChange = (event: any, enabledCustomers: { title: string, id: string }[]) => {
		setClientsFilter(enabledCustomers);
	}

	const languageFilterHandleChange = (event: any, selectedLanguage: string | null) => {
		setLanguage(selectedLanguage ? selectedLanguage : '');
	}

	const handleSortChange = (property: keyof Interpreter | string) => {
		setOrder(orderBy === property ? order === "asc" ? "desc" : "asc" : "asc");
		setOrderBy(property);
	};

	const updateSearch = (event: any) => {
		if (event.target.value.length === 0) {
			setSearch("");
			return;
		} else {
			setSearch(event.target.value);
		}
	}

	const navigate = useNavigate();
	const goToEdit = (id: Interpreter['Id']) => {
		navigate('/' + NavigationStructure.interpreterEdit.getURL(id));
	}

	const orangeForButton = "#ff481f";

	const TitleIconActiveInterpreter = styled(DisabledInterpreterIcon)(() => ({
		margin: "0px 0px -5px 10px",
	}));

	const TitleIconDeactivatedInterpreter = styled(EnabledInterpreterIcon)(() => ({
		margin: "0px 0px -5px 10px",
	}));

	if (error) return <div style={{color: "black"}}>error...</div>;

	if (loading) return (<Grid container spacing={2} style={{paddingTop: "20px"}}>
		<Grid item xs={12}>
			<center>
				<CircularProgress/>
			</center>
		</Grid>
	</Grid>);

	return (
		<Page headline={NavigationStructure.interpreters.display}>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					alignContent: "center",
					alignItems: "center"
				}}
			>
				<div
					style={{
						backgroundColor: "white",
						padding: "12px",
						borderRadius: "8px",
						marginRight: "18px"
					}}
				>
					<TextField
						id="outlined-basic"
						label="Suche"
						variant="outlined"
						value={search}
						style={{width: 400}}
						onChange={(event) => updateSearch(event)}
					/>
				</div>
				<div
					style={{
						backgroundColor: "white", padding: "12px", borderRadius: "8px"
					}}
				>
					<Autocomplete
						onChange={(event, enabledCustomers) => clientsFilterHandleChange(event, enabledCustomers)}
						multiple
						options={mapCustomers()}
						disableCloseOnSelect
						value={clientsFilter}
						getOptionLabel={(option) => option.title}
						isOptionEqualToValue={(option, value) => option.title === value.title}
						renderOption={(props, option, {selected}) => (<li {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
								checkedIcon={<CheckBoxIcon fontSize="small"/>}
								style={{marginRight: 8}}
								checked={selected}/>
							{option.title}
						</li>)}
						style={{width: 400, color: "black"}}
						renderInput={(params) => (
							<TextField {...params} label="Nach Kunden filtern" placeholder="Kunden"/>)}
					/>
				</div>
				<div
					style={{
						backgroundColor: "white", padding: "12px", borderRadius: "8px"
					}}
				>
					<Autocomplete
						onChange={(event, selectedLanguage) => languageFilterHandleChange(event, selectedLanguage)}
						options={mapLanguages()}
						renderOption={(props, option, {selected}) => (<li {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
								checkedIcon={<CheckBoxIcon fontSize="small"/>}
								style={{marginRight: 8}}
								checked={selected}/>
							{option}
						</li>)}
						style={{width: 400, color: "black"}}
						renderInput={(params) => (
							<TextField {...params} label="Nach Sprache filtern" placeholder="Sprache"/>)}
					/>
				</div>
				<div>
					<FormControlLabel
						control={<Checkbox
							disabled={statusFilterEnabled && statusFilterType === "Deactivated"}
							checked={statusFilterEnabled && statusFilterType === "Active"}
							onChange={event => filterHandleChange(event, true)}
							sx={{
								color: orangeForButton, '&.Mui-checked': {
									color: orangeForButton,
								},
							}}
						/>}
						label="Nur aktive Dolmetscher"
					/>
					<FormControlLabel
						control={
							<Checkbox
								disabled={statusFilterEnabled && statusFilterType === "Active"}
								checked={statusFilterEnabled && statusFilterType === "Deactivated"}
								onChange={event => filterHandleChange(event, false)}
								sx={{
									color: orangeForButton, '&.Mui-checked': {
										color: orangeForButton,
									},
								}}
							/>}
						label="Nur deaktivierte Dolmetscher"
					/>
				</div>
			</div>
			<div>
				<InterpretersList
					order={order}
					orderBy={orderBy}
					interpreters={interpreters}
					requesting={requesting}
					openDeactivateDialog={openDeactivateDialog}
					openActivateDialog={openActivateDialog}
					openEditDialog={(id) => goToEdit(id)}
					openDeletionDialog={openDeletionDialog}
					handleSortChange={handleSortChange}
				/>
			</div>

			<Dialog
				open={deletionDialogOpened}
				onClose={closeDeletionDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Dolmetscher löschen
					<DeleteInterpreterIcon
						style={{width: "30px", marginLeft: "2px", marginBottom: "-3.75px"}}
					>
					</DeleteInterpreterIcon>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Sind Sie sicher, dass Sie den Dolmetscher löschen wollen?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<OrangeButton onClick={closeDeletionDialog}>
						Abbrechen
					</OrangeButton>
					<OrangeButton onClick={handleDeletion}>
						Löschen
					</OrangeButton>
				</DialogActions>
			</Dialog>

			<Dialog
				open={deactivateDialogOpened}
				onClose={closeDeactivateDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Dolmetscher deaktivieren
					<TitleIconDeactivatedInterpreter>
					</TitleIconDeactivatedInterpreter>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Sind Sie sicher, dass Sie den Dolmetscher deaktivieren wollen?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<OrangeButton onClick={closeDeactivateDialog}>
						Abbrechen
					</OrangeButton>
					<OrangeButton onClick={handleDeactivate}>
						Deaktivieren
					</OrangeButton>
				</DialogActions>
			</Dialog>
			<Dialog
				open={activateDialogOpened}
				onClose={closeActivateDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Dolmetscher aktivieren
					<TitleIconActiveInterpreter>
					</TitleIconActiveInterpreter>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Sind Sie sicher, dass Sie den Dolmetscher aktivieren wollen?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<OrangeButton onClick={closeActivateDialog}>
						Abbrechen
					</OrangeButton>
					<OrangeButton onClick={handleActivate}>
						Aktivieren
					</OrangeButton>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={okOpened}
				autoHideDuration={6000}
				onClose={handleCloseOk}
				anchorOrigin={{vertical: "top", horizontal: "center"}}
			>
				<SnackbarContent
					style={{
						backgroundColor: "#4caf50",
					}}
					message={okMessage}
				/>
			</Snackbar>
			<Snackbar
				open={errorOpened}
				autoHideDuration={6000}
				onClose={() => setErrorOpened(false)}
				anchorOrigin={{vertical: "top", horizontal: "center"}}
			>
				<SnackbarContent
					style={{
						backgroundColor: "#f44336",
					}}
					message={error}
				/>
			</Snackbar>
		</Page>);
}

export default InterpretersView;