import React from "react";
import {
	TextField,
	MenuItem,
	CircularProgress
} from "@mui/material";

import {ErrorMessage, Form, Formik} from "formik";
import OrangeButton from "../OrangeButton/OrangeButton";
import Grid3 from "../Grid/Grid3";

type Props = {
	options: Array<{ display: string; value: number }>;
	selectedValue: number;
	grant: number;
	handleSubmit: any;
}

export type CreateOrUpdatePayslipFormProps = {
	grant: number;
	selectedValue: number;
}

export const CreateOrUpdatePayslipForm = ({
	                                          options,
	                                          selectedValue,
	                                          grant,
	                                          handleSubmit,
                                          }: Props) => {

	const initialValue: CreateOrUpdatePayslipFormProps = {
		grant,
		selectedValue
	};

	return (<>
			<Formik
				initialValues={initialValue}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting} = props;
					const {
						grant,
						selectedValue
					} = props.values;

					return (
						<Form>
							<Grid3>
								<div>
									<TextField
										select={true}
										label="Arbeitsstunden"
										name="selectedValue"
										fullWidth
										variant="outlined"
										margin="dense"
										value={selectedValue}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="selectedValue"/>}
									>
										{options.map(option => (
											<MenuItem
												value={option.value}
												key={option.display}
											>{option.display}
											</MenuItem>))}
									</TextField>
								</div>
								<div>
									<TextField
										inputProps={{
											type: "number",
										}}
										type="number"
										label="Pauschale"
										name="grant"
										fullWidth
										variant="outlined"
										margin="dense"
										value={grant}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="grant"/>}
									/>
								</div>
								<div></div>
							</Grid3>
							<div>
								<div style={{display: "flex", paddingTop: "20px", maxWidth: "600px", gap: "20px"}}>
									<OrangeButton
										type={"submit"}
										disabled={isSubmitting}
										startIcon={isSubmitting ? <CircularProgress size={20} color={"error"}/> : null}
									>
										Lohnabrechnung generieren
									</OrangeButton>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default CreateOrUpdatePayslipForm;
