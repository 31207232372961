import {JobStatus} from "../../Api/Types/Job";
import {Chip} from "@mui/material";
import {Cancel, HourglassEmpty, Paid} from "@mui/icons-material";
import getStatusLabel from "../../Helpers/getStatusLabel";

type Props = {
	status: JobStatus
}
export const JobStatusChip = ({status}: Props) => {
	if (status === JobStatus.Canceled || status === JobStatus.CanceledUnder24H) return (
		<Chip color="error" icon={<Cancel/>} label={getStatusLabel(status)}/>);
	if (status === JobStatus.AwaitConfirmation) return (
		<Chip color="warning" icon={<HourglassEmpty/>} label={getStatusLabel(status)}/>);
	if (status === JobStatus.ApproveClientPay) return (
		<Chip color="success" icon={<Paid/>} label={getStatusLabel(status)}/>);
	return (<Chip label={getStatusLabel(status)}/>);

}

export default JobStatusChip;