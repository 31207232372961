import React from "react";
import OrangeButton from "../OrangeButton/OrangeButton";
import {Form, Formik} from "formik";
import Grid from "../Grid/Grid";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Job from "../../Api/Types/Job";
import {useJob} from "../../Api/useJob";

type Props = {
	job?: Job;
}

export const JobInstructionForm = ({job}: Props) => {
	const initialValue: Pick<Job, "Comment"> = {
		Comment: job?.Comment || ''
	};

	const {updateComment} = useJob();

	const handleSubmit = (values: typeof initialValue) => {
		if (job?.Id) {
			return updateComment(job.Id, values.Comment).then().catch(() => alert('Kommentar konnte nicht gespeichert werden'));
		}
	}

	return (
		<div>
			<Formik
				initialValues={initialValue}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {isSubmitting, setFieldValue} = props;

					const {
						Comment
					} = props.values;

					const handleChange = (value: string) => {
						setFieldValue('Comment', value);
					}

					return (
						<Form>
							<Grid>
								<ReactQuill
									theme="snow"
									value={Comment}
									onChange={handleChange}
								/>
							</Grid>
							<div style={{
								display: "flex",
								paddingTop: "16px",
								justifyContent: "flex-end",
								gap: "8px"
							}}>
								<OrangeButton type={"submit"} disabled={isSubmitting}>
									Kommentar Speichern
								</OrangeButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}

export default JobInstructionForm;