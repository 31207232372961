import {MatchingMethode} from "../Components/Job/JobMatchingForm";

export const matchingMethodeName = (methode: MatchingMethode) => {
	switch (methode) {
		case "normal":
			return "An einen Dolmetscher senden";
		case "toAll":
			return "An alle verfügbaren Dolmetscher senden";
		case "toSome":
			return "An bestimmte Dolmetscher senden";
		default:
			return "Unbekannt";
	}
}

export const matchingMethodeDescription = (methode: MatchingMethode) => {
	switch (methode) {
		case "normal":
			return "Ein bestimmter Dolmetscher wird manuell zugewiesen";
		case "toAll":
			return "Alle verfügbaren Dolmetscher werden automatisch benachrichtigt. Der schnellste bekommt den Zuschlag";
		case "toSome":
			return "Nur ausgewählte Dolmetscher benachrichtigen. Der schnellste bekommt den Zuschlag";
		default:
			return "Unbekannt";
	}
}