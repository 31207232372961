import {Grid, Alert, CircularProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import {FormValuesConfirmationUpload, UploadConfirmationForm} from "../UploadConfirmationForm";
import {useNavigate, useParams} from "react-router-dom";
import {useJobConfirmationUpload} from "../../../Api/useJobConfirmationUpload";
import {getFormData} from "../../../Helpers/confirmationUploadHelper";

export const JobConfirmationReplaceView = () => {
	const {jobId} = useParams();
	const navigate = useNavigate();

	const {
		data: job,
		load,
		loading,
		error: errorLoad,
		replace
	} = useJobConfirmationUpload('replace');

	useEffect(() => {
		if (jobId) load(jobId);
	}, [jobId]);

	const [error, setError] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	const onSubmit = (data: FormValuesConfirmationUpload) => {
		const formData = getFormData(data);

		try {
			setError(false);
			replace(jobId || '', formData).then(_ => {
					navigate(`/${NavigationStructure.jobEdit.getURL(jobId || '')}`);
				})
				.catch((e) => {
					setError(true);
					if (e.response && e.response.data && e.response.data.message) {
						setErrorMessage(JSON.stringify(e.response.data.message));

					} else {
						setErrorMessage(JSON.stringify(e));
					}
				})
		} catch (error: any) {
			setError(true);
			setErrorMessage(error.toString());
		}
	};

	return (
		<Page headline={NavigationStructure.jobConfirmationReplace.display}>
			{loading && (<CircularProgress/>)}
			{!loading && job && !error && (
				<UploadConfirmationForm onSubmit={onSubmit} job={job}/>
			)}
			{error && (
				<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
					<Alert severity="error">{errorMessage}</Alert>
				</Grid>
			)}
			{errorLoad && (
				<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
					<Alert severity="error">{errorLoad}</Alert>
				</Grid>
			)}
		</Page>
	);
};

export default JobConfirmationReplaceView;
