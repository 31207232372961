import {useEffect, useState} from "react";
import Client, {ClientWithRelations} from "./Types/Client";
import {deactivateClient} from "./Calls/clients/deactivateClient";
import {activateClient} from "./Calls/clients/activateClient";
import {addClient} from "./Calls/clients/addClient";
import {editClient} from "./Calls/clients/editClient";
import {getClient} from "./Calls/clients/getClient";
import JobInstruction from "./Types/JobInstruction";
import {editJobInstructions} from "./Calls/clients/editJobInstructions";
import {useAuthentication} from "./useAuthentication";

export const useClient = (id?: Client['Id']) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [client, setClient] = useState<ClientWithRelations | null>(null);
	const {token} = useAuthentication();

	useEffect(() => {
		if (token && id) {
			setLoading(true);
			loadClient(id);
		}
	}, [token, id]);

	const loadClient = (id: string) => {
		getClient(token, id).then((data) => {
			if (data) {
				setClient(data);
				setLoading(false);
			}
		})
	}

	const requestDeactivateClient = (id: Client['Id']) => {
		return deactivateClient(id, token);
	}

	const requestActivateClient = (id: Client['Id']) => {
		return activateClient(id, token);
	}

	const requestNewClient = (client: Omit<Client, "Id" | "Number" | "Status">) => {
		return addClient(client, token);
	}

	const requestEditClient = (id: Client['Id'], client: Omit<Client, "Id" | "Number" | "Status">) => {
		return editClient(id, client, token);
	}

	const requestEditJobInstructions = (id: Client['Id'], jobInstructions: JobInstruction[]) => {
		return editJobInstructions(id, jobInstructions, token);
	}

	return {
		loading,
		client,
		requestDeactivateClient,
		requestActivateClient,
		requestNewClient,
		loadClient,
		requestEditClient,
		requestEditJobInstructions,
		token
	}
}
