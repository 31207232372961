import {TextField, TextFieldProps, CircularProgress, Typography, Divider} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import {ErrorMessage, Form, Formik} from "formik";
import UploadValidation from "./UploadValidation";
import Grid3 from "../../Components/Grid/Grid3";
import FormikDatePicker from "../../Components/FormikDatePicker/FormikDatePicker";
import OrangeButton from "../../Components/OrangeButton/OrangeButton";
import {JobWithConfirmation} from "../../Api/Types/Job";
import {FormikHelpers} from "formik/dist/types";
import styled from "styled-components";
import Grid from "../../Components/Grid/Grid";
import EBExample from "../../Images/eb_example.png"
import UploadValidationWithoutWorknumber from "./UploadValidationWithoutWorknumber";

export type FormValuesConfirmationUpload = {
	formFile: string;
	fileName: string;
	workNumber: string;
	workingStart: string;
	workingEnd: string;
	workerName: string;
	driveStart: string;
	driveEnd: string;
	workingDate: dayjs.Dayjs;
}

type Props = {
	job?: JobWithConfirmation;
	onSubmit: (values: FormValuesConfirmationUpload, formikHelpers: FormikHelpers<FormValuesConfirmationUpload>) => void | Promise<any>;
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
`;

const ExampleImage = styled.img`
    width: 100%;
    height: auto;
    border: 2px gray dashed;
`

export const UploadConfirmationForm = ({job, onSubmit}: Props) => {
	const initialValue: FormValuesConfirmationUpload = {
		formFile: '',
		fileName: '',
		workNumber: job ? `${job.NumberPrefix}-${job.NumberCounter}` : '',
		workingStart: job?.ConfirmationInterpreter?.WorkingStart || '',
		workingEnd: job?.ConfirmationInterpreter?.WorkingEnd || '',
		workerName: job ? `${job.Interpreter.Prename}-${job.Interpreter.Surname}` : '',
		driveStart: job?.ConfirmationInterpreter?.DriveStart || '',
		driveEnd: job?.ConfirmationInterpreter?.DriveEnd || '',
		workingDate: job ? dayjs(job.Date) : dayjs(new Date()),
	};

	const disabled = job !== undefined;
	const showHelp = job === undefined;

	return (
		<Formik
			initialValues={initialValue}
			validationSchema={job ? UploadValidationWithoutWorknumber : UploadValidation}
			onSubmit={onSubmit}
		>
			{(formik) => {
				const {
					fileName,
					workNumber,
					workingStart,
					workingEnd,
					workerName,
					driveStart,
					driveEnd,
				} = formik.values;

				return (
					<Form>
						<Grid>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Einsatznummer</Typography>
											<Typography>
												Trage hier die Einsatznummer wie auf der Einsatzbestätigung
												angegeben. Diese findest du direkt hinter der Bezeichnung
												"Einsatzbestätigung".
											</Typography>
											<ExampleImage src={EBExample} width={"auto"}/>
											<Typography color={"red"}>
												Achtung: Bitte nicht mit der "Auftragsnummer" verwechseln!
											</Typography>
										</>
									)}
									<TextField
										inputProps={{
											placeholder: "z. B. LAIV-1234",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										label="Einsatznummer"
										name="workNumber"
										fullWidth
										variant="outlined"
										margin="dense"
										value={workNumber}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={<ErrorMessage name="workNumber"/>}
										error={formik.errors.workNumber as unknown as boolean && formik.touched.workNumber}
										disabled={disabled}
										required
									/>
								</Box>
							</div>
							<Divider/>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Dein Vor- und Nachname</Typography>
											<Typography>Trage hier deinen Vor- und Nachnamen ein.</Typography>
										</>
									)}
									<TextField
										inputProps={{
											placeholder: "z. B. Max Mustermann",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										label="Dein Vor- und Nachname"
										name="workerName"
										fullWidth
										variant="outlined"
										margin="dense"
										value={workerName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={<ErrorMessage name="workerName"/>}
										error={formik.errors.workerName as unknown as boolean && formik.touched.workerName}
										disabled={disabled}
										required
									/>
								</Box>
							</div>
							<Divider/>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Einsatzdatum</Typography>
											<Typography>Trage hier das Datum des Einsatzes ein.</Typography>
										</>
									)}
									<FormikDatePicker
										disabled={disabled}
										name="workingDate"
										slotProps={{
											textField: {
												fullWidth: true,
												label: 'Einsatzdatum',
												required: true,
												disabled: disabled
											}
										}}
										renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
											<TextField {...params} />
										)}
										required
									/>
								</Box>
							</div>
							<Divider/>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Beginn der Hinfahrt</Typography>
											<Typography>Uhrzeit als Du zum Einsatz hingefahren bist.</Typography>
										</>
									)}
									<TextField
										inputProps={{
											placeholder: "z. B. 8:00",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										label="Beginn der Hinfahrt"
										name="driveStart"
										fullWidth
										variant="outlined"
										margin="dense"
										value={driveStart}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={<ErrorMessage name="driveStart"/>}
										error={formik.errors.driveStart as unknown as boolean && formik.touched.driveStart}
										required
									/>
								</Box>
							</div>
							<Divider/>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Beginn des Einsatzes</Typography>
											<Typography>Uhrzeit als der Einsatz gestartet ist.</Typography>
										</>
									)}
									<TextField
										inputProps={{
											placeholder: "z. B. 9:00",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										label="Beginn des Einsatzes"
										name="workingStart"
										fullWidth
										variant="outlined"
										margin="dense"
										value={workingStart}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={<ErrorMessage name="workingStart"/>}
										error={formik.errors.workingStart as unknown as boolean && formik.touched.workingStart}
										required
									/>
								</Box>
							</div>
							<Divider/>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Ende des Einsatzes (inkl. Wartezeit)</Typography>
											<Typography>Uhrzeit als der Einsatz beendet worden ist inkl.
												Wartezeit.</Typography>
										</>
									)}
									<TextField
										inputProps={{
											placeholder: "z. B. 10:00",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										label="Ende des Einsatzes (inkl. Wartezeit)"
										name="workingEnd"
										fullWidth
										variant="outlined"
										margin="dense"
										value={workingEnd}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={<ErrorMessage name="workingEnd"/>}
										error={formik.errors.workingEnd as unknown as boolean && formik.touched.workingEnd}
										required
									/>
								</Box>
							</div>
							<Divider/>
							<div>
								<Box>
									{showHelp && (
										<>
											<Typography variant="h5">Ende der Rückfahrt</Typography>
											<Typography>Uhrzeit als die Rückfahrt beendet worden ist.</Typography>
										</>
									)}
									<TextField
										inputProps={{
											placeholder: "z. B. 11:00",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										label="Ende der Rückfahrt"
										name="driveEnd"
										fullWidth
										variant="outlined"
										margin="dense"
										value={driveEnd}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={<ErrorMessage name="driveEnd"/>}
										error={formik.errors.driveEnd as unknown as boolean && formik.touched.driveEnd}
										required
									/>
								</Box>
							</div>
						</Grid>
						<Grid3>
							<div>
								{/* @ts-ignore */}
								<OrangeButton component="label">
									<input
										hidden
										id="file"
										name="formFile"
										type="file"
										accept=".png, .jpg, .jpeg, .pdf"
										onChange={(event) => {
											const files = event.target.files;
											if (files && files[0]) {
												formik.setFieldValue("formFile", files[0]);
												formik.setFieldValue("fileName", files[0].name);
											}
										}}
									/>
									Datei auswählen
								</OrangeButton>
								<ErrorMessage name="fileName"/>
							</div>
							<div>
								<OrangeButton type="submit"
								              disabled={formik.isSubmitting || fileName === '' || driveStart === '' || workingStart === '' || workingEnd === '' || driveEnd === ''}
								              startIcon={
									              <>
										              {formik.isSubmitting && (
											              <CircularProgress size={20} color={"error"}/>
										              )}
									              </>
								              }
								>
									Hochladen
								</OrangeButton>
							</div>
							<div>
								{!fileName && (
									<>
										<br/>
										<p>Bitte ein Dokument auswählen!</p>
									</>
								)}
								{fileName && (
									<>
										<br/>
										<p>{fileName}</p>
									</>
								)}
							</div>
						</Grid3>
					</Form>
				)
			}}
		</Formik>
	);
};

export default UploadConfirmationForm;
