import {getHeaderTempToken} from "../../getHeader";
import Client from "../../Types/Client";

export const getClients = async (token: string): Promise<Client[]> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/clients`, {
		method: "GET",
		headers: getHeaderTempToken(token),
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				console.log("logout");
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return [];
		})
}