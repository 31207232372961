import React from "react";
import {
	DialogContent,
	DialogTitle, Divider, FormControl, FormGroup, IconButton, Typography
} from "@mui/material";
import Client from "../../Api/Types/Client";
import OrangeButton from "../OrangeButton/OrangeButton";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import ClientValidation from "./ClientsValidation";
import {useClients} from "../../Api/useClients";
import Grid2 from "../Grid/Grid2";
import {CheckboxWithLabel} from "formik-material-ui";
import {Add, Remove} from "@mui/icons-material";
import {useClient} from "../../Api/useClient";

type Props = {
	client?: Client;
	onClose: () => void;
	onError?: (e: any) => void;
	mode: 'new' | 'edit';
}

export const ClientForm = ({client, onClose, mode, onError}: Props) => {
	const initialValue: Omit<Client, "Id" | "Number" | "Status"> = {
		Name: client?.Name || '',
		ShortName: client?.ShortName || '',
		AdditionalName: client?.AdditionalName || '',
		AdditionalName2: client?.AdditionalName2 || '',
		Street: client?.Street || '',
		Postcode: client?.Postcode || '',
		City: client?.City || '',
		JobNumberPrefix: client?.JobNumberPrefix || '',
		InvoiceMails: client?.InvoiceMails || [],
		DistanceInvoiceEnabled: client?.DistanceInvoiceEnabled || false,
		DistanceInvoiceValue: client?.DistanceInvoiceValue || 0,
		RateInvoice: client?.RateInvoice || 0,
		FixInvoiceEnabled: client?.FixInvoiceEnabled || false,
		FixInvoiceValue: client?.FixInvoiceValue || 0,
		InvoiceReference: client?.InvoiceReference || '',
		JobAddress: client?.JobAddress || []
	};

	const {requestNewClient, requestEditClient} = useClient();

	const handleSubmit = (values: typeof initialValue) => {
		if (mode === 'new') {
			requestNewClient(values).then(() => onClose()).catch(onError);
		}
		if (mode === 'edit' && client) {
			requestEditClient(client.Id, values).then(() => onClose()).catch(onError);
		}
	}

	return (
		<div>
			<DialogTitle>
				Allgemeine Informationen
			</DialogTitle>
			<DialogContent>
				<Formik
					initialValues={initialValue}
					validationSchema={ClientValidation}
					onSubmit={handleSubmit}
				>
					{(props) => {
						const {
							Name,
							ShortName,
							AdditionalName,
							AdditionalName2,
							Street,
							Postcode,
							City,
							JobNumberPrefix,
							DistanceInvoiceEnabled,
							DistanceInvoiceValue,
							InvoiceMails,
							RateInvoice,
							FixInvoiceEnabled,
							FixInvoiceValue,
							InvoiceReference,
							JobAddress
						} = props.values;
						return (
							<Form>
								<Grid2>
									<TextField
										label="Interne Bezeichung"
										name="ShortName"
										fullWidth
										variant="outlined"
										margin="dense"
										value={ShortName}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="ShortName"/>}
										error={props.errors.ShortName as unknown as boolean && props.touched.ShortName}
										required
									/>
									<TextField
										label="Vorzeichen (z.B. LAIV)"
										name="JobNumberPrefix"
										fullWidth
										variant="outlined"
										margin="dense"
										value={JobNumberPrefix}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="JobNumberPrefix"/>}
										error={props.errors.JobNumberPrefix as unknown as boolean && props.touched.JobNumberPrefix}
										required
										disabled={mode === 'edit'}
									/>
								</Grid2>
								<Grid2>
									<TextField
										label="Name"
										name="Name"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Name}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Name"/>}
										error={props.errors.Name as unknown as boolean && props.touched.Name}
										required
									/>
									<TextField
										label="AdditionalName"
										name="AdditionalName"
										fullWidth
										variant="outlined"
										margin="dense"
										value={AdditionalName}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="AdditionalName"/>}
										error={props.errors.AdditionalName as unknown as boolean && props.touched.AdditionalName}
									/>
								</Grid2>
								<Grid2>
									<TextField
										label="AdditionalName2"
										name="AdditionalName2"
										fullWidth
										variant="outlined"
										margin="dense"
										value={AdditionalName2}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="AdditionalName2"/>}
										error={props.errors.AdditionalName2 as unknown as boolean && props.touched.AdditionalName2}
									/>
									<TextField
										label="Street"
										name="Street"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Street}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Street"/>}
										error={props.errors.Street as unknown as boolean && props.touched.Street}
										required
									/>
								</Grid2>
								<Grid2>
									<TextField
										label="Postcode"
										name="Postcode"
										fullWidth
										variant="outlined"
										margin="dense"
										value={Postcode}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="Postcode"/>}
										error={props.errors.Postcode as unknown as boolean && props.touched.Postcode}
										required
									/>
									<TextField
										label="City"
										name="City"
										fullWidth
										variant="outlined"
										margin="dense"
										value={City}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										helperText={<ErrorMessage name="City"/>}
										error={props.errors.City as unknown as boolean && props.touched.City}
										required
									/>
								</Grid2>
								<Divider style={{margin: "20px 0"}}/>
								<Typography variant="h6">Rechnungsinformationen</Typography>
								<Grid2>
									<div>
										<TextField
											type="number"
											label="Preis pro Stunde in €"
											name="RateInvoice"
											fullWidth
											variant="outlined"
											margin="dense"
											value={RateInvoice}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="RateInvoice"/>}
											error={props.errors.RateInvoice as unknown as boolean && props.touched.RateInvoice}
											onFocus={(e) => e.target.addEventListener("wheel", function (e) {
												e.preventDefault()
											}, {passive: false})}
											required
										/>
									</div>
									<div>
										<TextField
											label="Referenz auf der Rechnung (optional)"
											name="InvoiceReference"
											fullWidth
											variant="outlined"
											margin="dense"
											value={InvoiceReference}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											helperText={<ErrorMessage name="InvoiceReference"/>}
											error={props.errors.InvoiceReference as unknown as boolean && props.touched.InvoiceReference}
										/>
									</div>
								</Grid2>
								<Grid2>
									<div>
										<FormControl aria-autocomplete={"none"}>
											<FormGroup>
												<Field
													type="checkbox"
													component={CheckboxWithLabel}
													name="DistanceInvoiceEnabled"
													value={DistanceInvoiceEnabled}
													Label={{label: "Kilometer auf der Rechnung anrechnen?"}}
													checked={DistanceInvoiceEnabled}
												/>
											</FormGroup>
										</FormControl>
									</div>
									<div>
										{DistanceInvoiceEnabled && (
											<TextField
												type="number"
												label="Kilometerpreis in €"
												name="DistanceInvoiceValue"
												fullWidth
												variant="outlined"
												margin="dense"
												value={DistanceInvoiceValue}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												helperText={<ErrorMessage name="DistanceInvoiceValue"/>}
												error={props.errors.DistanceInvoiceValue as unknown as boolean && props.touched.DistanceInvoiceValue}
												onFocus={(e) => e.target.addEventListener("wheel", function (e) {
													e.preventDefault()
												}, {passive: false})}
												required
											/>
										)}
									</div>
								</Grid2>
								<Grid2>
									<div>
										<FormControl aria-autocomplete={"none"}>
											<FormGroup>
												<Field
													type="checkbox"
													component={CheckboxWithLabel}
													name="FixInvoiceEnabled"
													value={FixInvoiceEnabled}
													Label={{label: "Pauschale auf der Rechnung anrechnen?"}}
													checked={FixInvoiceEnabled}
												/>
											</FormGroup>
										</FormControl>
									</div>
									<div>
										{FixInvoiceEnabled && (
											<TextField
												type="number"
												label="Pauschale in €"
												name="FixInvoiceValue"
												fullWidth
												variant="outlined"
												margin="dense"
												value={FixInvoiceValue}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												helperText={<ErrorMessage name="FixInvoiceValue"/>}
												error={props.errors.FixInvoiceValue as unknown as boolean && props.touched.FixInvoiceValue}
												onFocus={(e) => e.target.addEventListener("wheel", function (e) {
													e.preventDefault()
												}, {passive: false})}
												required
											/>
										)}
									</div>
								</Grid2>
								<div>
									<FieldArray
										name="InvoiceMails"
										render={(arrayHelpers: {
											remove: (arg0: number) => void;
											insert: (arg0: number, arg1: string) => void;
											push: (arg0: string) => void;
										}) => (
											<div>
												{InvoiceMails && InvoiceMails.length > 0 ? (
													InvoiceMails.map((mail, index) => (
														<div key={index} style={{display: "flex"}}>
															<TextField
																label={`Mail ${index + 1}`}
																name={`InvoiceMails.${index}`}
																variant="outlined"
																margin="dense"
																value={mail}
																onChange={props.handleChange}
																onBlur={props.handleBlur}
																style={{minWidth: "400px"}}
															/>
															<IconButton
																onClick={() => arrayHelpers.remove(index)}
															>
																<Remove/>
															</IconButton>
															<IconButton
																onClick={() => arrayHelpers.insert(index, '')}
															>
																<Add/>
															</IconButton>
														</div>
													))
												) : (
													<OrangeButton onClick={() => arrayHelpers.push('')}>
														Rechnung E-Mail hinzufügen
													</OrangeButton>
												)}
											</div>
										)}
									/>
								</div>
								<Divider style={{margin: "20px 0"}}/>
								<div>
									<Typography variant="h6">Einsatzorte</Typography>
									<FieldArray
										name="JobAddress"
										render={(arrayHelpers: {
											remove: (arg0: number) => void;
											insert: (arg0: number, arg1: string) => void;
											push: (arg0: string) => void;
										}) => (
											<div>
												{JobAddress && JobAddress.length > 0 ? (
													JobAddress.map((address, index) => (
														<>
															<div key={index}
															     style={{
																     display: "flex",
																     flexDirection: "row",
																     flexWrap: "wrap",
																     gap: "8px"
															     }}>
																<TextField
																	label={`Firma ${index + 1}`}
																	name={`JobAddress.${index}.Station`}
																	variant="outlined"
																	margin="dense"
																	value={address.Station || ''}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	style={{minWidth: "200px"}}
																/>
																<TextField
																	label={`Straße ${index + 1}`}
																	name={`JobAddress.${index}.Street`}
																	variant="outlined"
																	margin="dense"
																	value={address.Street || ''}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	style={{minWidth: "200px"}}
																/>
																<TextField
																	label={`PLZ ${index + 1}`}
																	name={`JobAddress.${index}.Postcode`}
																	variant="outlined"
																	margin="dense"
																	value={address.Postcode || ''}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	style={{minWidth: "200px"}}
																/>
																<TextField
																	label={`Stadt ${index + 1}`}
																	name={`JobAddress.${index}.City`}
																	variant="outlined"
																	margin="dense"
																	value={address.City || ''}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	style={{minWidth: "200px"}}
																/>
																<TextField
																	label={`Zusatz ${index + 1}`}
																	name={`JobAddress.${index}.Location`}
																	variant="outlined"
																	margin="dense"
																	value={address.Location || ''}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	style={{minWidth: "200px"}}
																/>
																<IconButton
																	onClick={() => arrayHelpers.remove(index)}
																>
																	<Remove/>
																</IconButton>
																<IconButton
																	onClick={() => arrayHelpers.insert(index, '')}
																>
																	<Add/>
																</IconButton>
															</div>
															<Divider style={{margin: "20px 0"}}/>
														</>
													))
												) : (
													<OrangeButton onClick={() => arrayHelpers.push('')}>
														Einsatzort hinzufügen
													</OrangeButton>
												)}
											</div>
										)}
									/>
								</div>
								<div style={{
									display: "flex",
									paddingTop: "16px",
									justifyContent: "flex-end",
									gap: "8px"
								}}>
									<OrangeButton onClick={onClose}>
										Abbrechen
									</OrangeButton>
									<OrangeButton type={"submit"}>
										Speichern
									</OrangeButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</DialogContent>
		</div>
	);
}

export default ClientForm;