import JobInstructionForm from "../../JobInstruction/JobInstructionView";
import Job, {JobStatus} from "../../../Api/Types/Job";
import jobFeatureAllowed from "../../../Helpers/JobFeatures";
import PreviewDocument from "../../PreviewDocument/PreviewDocument";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormGroup
} from "@mui/material";
import OrangeButton from "../../OrangeButton/OrangeButton";
import {Send} from "@mui/icons-material";
import React, {useState} from "react";
import ConfirmationFile from "../../../Api/Types/ConfirmationFile";
import {Actions} from "../../Actions/Actions";
import {FilePreview} from "../../../Api/Types/FilePreview";
import {useJob} from "../../../Api/useJob";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Distance from "../../Distance/Distance";
import CreateOrUpdatePayslipForm, {CreateOrUpdatePayslipFormProps} from "../../Payslip/CreateOrUpdatePayslipForm";

type Props = {
	job: Job;
	confirmationFile: ConfirmationFile;
	previewPayslip: FilePreview | null;
}

const ManagePayslip = ({job, confirmationFile, previewPayslip}: Props) => {
	const [sendNotification, setSendNotification] = useState(true);
	const [open, setOpen] = useState(false);

	const {
		createPayslip,
		approvePayslip
	} = useJob();
	const creation = (values: CreateOrUpdatePayslipFormProps) => {
		createPayslip(job.Id, values.selectedValue, values.grant).then(() => window.location.reload())
	}

	const confirm = () => {
		approvePayslip(job?.Id, sendNotification).then(() => window.location.reload())
	}

	return (
		<>
			<JobInstructionForm client={job.Client} status={JobStatus.ApproveInterpreterPay}/>
			<Distance from={job.Interpreter?.Address} to={job.Address}/>
			{jobFeatureAllowed(job, 'editPayslip') && (
				<CreateOrUpdatePayslipForm
					options={[
						{
							value: confirmationFile.EffectiveWorkingTime,
							display: `Effektive Arbeitszeit von ${confirmationFile.EffectiveWorkingTime} h`
						},
						{
							value: confirmationFile.WorkingTime,
							display: `Gesamte Arbeitszeit von ${confirmationFile.WorkingTime} h`
						}
					]}
					selectedValue={confirmationFile.EffectiveWorkingTime}
					// TODO get this number from a helper function based on client ID
					grant={5}
					handleSubmit={creation}
				/>
			)}
			{previewPayslip && (
				<div>
					<p>Vorschau:</p>
					<PreviewDocument {...previewPayslip}/>
				</div>
			)}
			{jobFeatureAllowed(job, 'approvePayslip') && (
				<Actions>
					<OrangeButton
						onClick={() => setOpen(true)}
						startIcon={<Send fontSize={"small"}/>}>
						Lohnabrechnung versenden
					</OrangeButton>
				</Actions>
			)}
			{open && (
				<Dialog
					open={true}
					onClose={() => setOpen(false)}
				>
					<DialogTitle>
						Lohnabrechnung erstellen
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Soll die Lohnabrechnung anschließend an der Dolmetscher gesendet werden?
						</DialogContentText>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox checked={sendNotification}
								          onChange={(event) => setSendNotification(event.target.checked)}/>}
							                  label="Lohnabrechnung versenden"
							/>
						</FormGroup>
					</DialogContent>
					<DialogActions>
						<OrangeButton onClick={() => setOpen(false)}>
							Abbrechen
						</OrangeButton>
						<OrangeButton onClick={confirm}>
							Lohnabrechnung abschließen
						</OrangeButton>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}

export default ManagePayslip;