import {getHeaderTempToken} from "../../getHeader";
import Job from "../../Types/Job";

export const getJob = async (token: string, id: string): Promise<Job | null> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/jobs/${id}`, {
		method: "GET",
		headers: getHeaderTempToken(token),
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				return null;
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return null;
		})
}