import Address from "../Api/Types/Address";

export const addressToStringHelper = (address: Address): string => {
	const city = [address.Postcode, address.City]
		.filter((word) => word)
		.join(' ');
	return [address.Station, address.Street, address.Location, city]
		.filter((word) => word)
		.join(', ');
};
