import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import ClientForm from "../../../Components/Clients/ClientForm";

export const ClientAddPage = () => {
	const navigate = useNavigate();

	const [error, setError] = useState("");

	if (error) return (
		<Page headline={NavigationStructure.clientAdd.display}>
			<center>
				{error}
			</center>
		</Page>
	);

	const onClose = () => {
		navigate(-1);
	}

	const onError = (e: any) => {
		setError(e.toString);
	}

	return (
		<Page headline={NavigationStructure.clientAdd.display}>
			<ClientForm
				onClose={onClose}
				onError={onError}
				mode={"new"}
			/>
		</Page>);
}

export default ClientAddPage;