import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

var whiteForBackground = "#f4f7ff";
var orangeForButton = "#ff481f";

const OrangeButton = styled(Button)(() => ({
	fontFamily: "Lato",
	fontSize: "19px",
	fontWeight: 900,
	textTransform: "none",
	flex: 1,
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: orangeForButton,
	borderRadius: "999rem",
	backgroundColor: orangeForButton,
	'&:hover': {
		borderColor: orangeForButton,
		borderRadius: "999rem",
		backgroundColor: whiteForBackground,
		color: orangeForButton
	},
	transitionDuration: "250ms",
	transitionTimingFunction: "ease",
	transitionDelay: "0s",
	transitionProperty: "all",
	color: "white",
	maxWidth: "300px"
}));

export default OrangeButton;

