export const PersischOptions = [
	{systemName: "afghanisch", displayName: "afghanisch"},
	{systemName: "balochi", displayName: "balochi"},
	{systemName: "dari", displayName: "dari"},
	{systemName: "farsi", displayName: "farsi"},
	{systemName: "iranisch", displayName: "iranisch"},
	{systemName: "paschtu", displayName: "paschtu"},
	{systemName: "persisch", displayName: "persisch"},
]

export const RussianOptions = [
	{systemName: "georgisch", displayName: "georgisch"},
	{systemName: "russisch", displayName: "russisch"},
	{systemName: "ukrainisch", displayName: "ukrainisch"},
]

export const KurdischOptions = [
	{systemName: "bandiani", displayName: "bandiani"},
	{systemName: "kurdisch", displayName: "kurdisch"},
	{systemName: "sorani", displayName: "sorani"},
]

export const PakistanischOptions = [
	{systemName: "pakistanisch", displayName: "pakistanisch"},
	{systemName: "urdu", displayName: "urdu"},
]

export const GeneralLanguageOptions = [
	{systemName: "albanisch", displayName: "albanisch"},
	{systemName: "algerisch", displayName: "algerisch"},
	{systemName: "amharisch", displayName: "amharisch"},
	{systemName: "arabisch", displayName: "arabisch"},
	{systemName: "armenisch", displayName: "armenisch"},
	{systemName: "aserbaidschanisch", displayName: "aserbaidschanisch"},
	{systemName: "berberisch", displayName: "berberisch"},
	{systemName: "bosnisch", displayName: "bosnisch"},
	{systemName: "bulgarisch", displayName: "bulgarisch"},
	{systemName: "chinesisch", displayName: "chinesisch"},
	{systemName: "dendi", displayName: "dendi"},
	{systemName: "englisch", displayName: "englisch"},
	{systemName: "französisch", displayName: "französisch"},
	{systemName: "fula", displayName: "fula"},
	{systemName: "indisch", displayName: "indisch"},
	{systemName: "italienisch", displayName: "italienisch"},
	{systemName: "jordanisch", displayName: "jordanisch"},
	{systemName: "kissi", displayName: "kissi"},
	{systemName: "kumanci", displayName: "kumanci"},
	{systemName: "litauisch", displayName: "litauisch"},
	{systemName: "marrokanisch", displayName: "marrokanisch"},
	{systemName: "mazedonisch", displayName: "mazedonisch"},
	{systemName: "moldauisch", displayName: "moldauisch"},
	{systemName: "norwegisch", displayName: "norwegisch"},
	{systemName: "polnisch", displayName: "polnisch"},
	{systemName: "portugiesisch", displayName: "portugiesisch"},
	{systemName: "punjabi", displayName: "punjabi"},
	{systemName: "romani", displayName: "romani"},
	{systemName: "rumänisch", displayName: "rumänisch"},
	{systemName: "schwedisch", displayName: "schwedisch"},
	{systemName: "senegalesisch", displayName: "senegalesisch"},
	{systemName: "serbisch", displayName: "serbisch"},
	{systemName: "somali", displayName: "somali"},
	{systemName: "sonike", displayName: "sonike"},
	{systemName: "spanisch", displayName: "spanisch"},
	{systemName: "tadschikisch", displayName: "tadschikisch"},
	{systemName: "thailändisch", displayName: "thailändisch"},
	{systemName: "tigrinya", displayName: "tigrinya"},
	{systemName: "tschetschenisch", displayName: "tschetschenisch"},
	{systemName: "twi", displayName: "twi"},
	{systemName: "türkisch", displayName: "türkisch"},
	{systemName: "vietnamesisch", displayName: "vietnamesisch"},
	{systemName: "wolof", displayName: "wolof"},
];

export const AllLanguages = [...PersischOptions, ...RussianOptions, ...KurdischOptions, ...PakistanischOptions, ...GeneralLanguageOptions].sort((a, b) => {
	if (a.systemName > b.systemName) return 1;
	if (a.systemName < b.systemName) return -1;
	return 0;
})