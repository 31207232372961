import React from "react";
import {Typography} from "@mui/material";

export const PageNotFound = () => (
    <>
        <Typography align="center" variant="h2" style={{ paddingTop: "80px", color: "darkred" }}>
            Error 404.
        </Typography>
        <Typography align="center" variant="h2" style={{ paddingTop: "20px", color: "black" }}>
            Seite konnte nicht gefunden werden.
        </Typography>
    </>
);

export default PageNotFound;
