export function prioToLabel(prio : any) {
	switch (prio) {
		case 0:
			return "Hoch"
		case 1:
			return "Mittel"
		case 2:
			return "Niedrig"
	}
}
