import styled from "styled-components";

const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > div {
    min-height: 72px;
    display: flex;
    align-items: center;
  }
`;

export default Grid2;