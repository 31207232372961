import React, {useState} from "react";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle, Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import Client from "../../Api/Types/Client";
import EnabledInterpreterIcon from "@mui/icons-material/PersonOff";
import DisabledInterpreterIcon from "@mui/icons-material/HowToReg";
import CustomIconButton from "../CustomIconButton/CustomIconButton";
import OrangeButton from "../OrangeButton/OrangeButton";
import {useClients} from "../../Api/useClients";
import {Edit, PlaylistAddCheck} from "@mui/icons-material";
import Interpreter from "../../Api/Types/Interpreter";
import {NavigationStructure} from "../Navigation/NavigationStructure";
import {useNavigate} from "react-router-dom";
import {useClient} from "../../Api/useClient";

export const ClientsList = () => {
	const navigate = useNavigate();
	const [activateClientDialogOpened, setActivateClientDialogOpened] = useState(false);
	const [deactivateClientDialogOpened, setDeactivateClientDialogOpened] = useState(false);
	const [selectedClient, setSelectedClient] = useState<Client['Id']>('');

	const {loading, clients, loadClients} = useClients();
	const {requestDeactivateClient, requestActivateClient} = useClient();
	const openDeactivateDialog = (id: Client['Id']) => {
		setSelectedClient(id);
		setDeactivateClientDialogOpened(true);
	}

	const closeDeactivateDialog = () => {
		setSelectedClient('');
		setDeactivateClientDialogOpened(false);
	}

	const openActivateDialog = (id: Client['Id']) => {
		setSelectedClient(id);
		setActivateClientDialogOpened(true);
	}

	const closeActivateDialog = () => {
		setSelectedClient('');
		setActivateClientDialogOpened(false);
	}

	const handleDeactivate = () => {
		requestDeactivateClient(selectedClient).then(() => {
			closeDeactivateDialog();
			loadClients();
		});
	}

	const handleActivate = () => {
		requestActivateClient(selectedClient).then(() => {
			closeActivateDialog();
			loadClients();
		});
	}

	const goToEdit = (id: Interpreter['Id']) => {
		navigate('/' + NavigationStructure.clientEdit.getURL(id));
	}

	const goToJobInstructionsEdit = (id: Interpreter['Id']) => {
		navigate('/' + NavigationStructure.clientJobInstructionEdit.getURL(id));
	}

	const goToAdd = () => {
		navigate('/' + NavigationStructure.clientAdd.url);
	}

	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					color: "white",
				}}
			>
				{loading && (
					<CircularProgress/>
				)}
				{!loading && (
					<div style={{display: "flex", flexDirection: "column"}}>
						<div style={{display: "flex", justifyContent: "flex-end"}}>
							<div>
								<OrangeButton onClick={goToAdd}>
									Kunde anlegen
								</OrangeButton>
							</div>
						</div>
						<TableContainer component={Paper}>
							<Table size="small" aria-label="a dense table">
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell align="right">
											Name
										</TableCell>
										<TableCell align="right">
											RE-Kürzel
										</TableCell>
										<TableCell align="right">
											Adresse
										</TableCell>
										<TableCell align="right">
											Zusatz
										</TableCell>
										<TableCell align="right">
											Zusatz 2
										</TableCell>
										<TableCell align="right">
											Interne Bezeichnung
										</TableCell>
										<TableCell align="right">
											De-/aktivieren
										</TableCell>
										<TableCell align="right">
											Bearbeiten
										</TableCell>
										<TableCell align="right">
											Hinweise
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>{
									clients.map((row: Client) => (<TableRow
										key={row.Id}
										style={{opacity: row.Status === 'Active' ? 1 : 0.3}}
									>
										<TableCell align="left">#{row.Number}</TableCell>
										<TableCell align="right">{row.Name}</TableCell>
										<TableCell align="right">{row.JobNumberPrefix}</TableCell>
										<TableCell align="right">
											{row.Street}
											<br/>
											{row.Postcode} {row.City}
											<br/>
										</TableCell>
										<TableCell align="right">{row.AdditionalName}</TableCell>
										<TableCell align="right">
											{row.AdditionalName2}
										</TableCell>
										<TableCell align="right">{row.ShortName}</TableCell>
										<TableCell align="right">
											{row.Status === 'Active' && (<CustomIconButton
												onClick={() => openDeactivateDialog(row.Id)}
											>
												<EnabledInterpreterIcon/>
											</CustomIconButton>)}
											{row.Status === 'Deactivated' && (<CustomIconButton
												onClick={() => openActivateDialog(row.Id)}
											>
												<DisabledInterpreterIcon/>
											</CustomIconButton>)}
										</TableCell>
										<TableCell align="right">
											<CustomIconButton
												onClick={() => goToEdit(row.Id)}
											>
												<Edit/>
											</CustomIconButton>
										</TableCell>
										<TableCell align="right">
											<CustomIconButton
												onClick={() => goToJobInstructionsEdit(row.Id)}
											>
												<PlaylistAddCheck/>
											</CustomIconButton>
										</TableCell>
									</TableRow>))
								}</TableBody>
							</Table>
						</TableContainer>
					</div>)}
			</div>

			<Dialog
				open={deactivateClientDialogOpened}
				onClose={closeDeactivateDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Kunde deaktivieren
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`Sind Sie sicher, dass Sie den neuen Kunden "${clients.find(client => client.Id === selectedClient)?.ShortName}" deaktivieren wollen?`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<OrangeButton onClick={closeDeactivateDialog}>
						Abbrechen
					</OrangeButton>
					<OrangeButton onClick={handleDeactivate}>
						Deaktivieren
					</OrangeButton>
				</DialogActions>
			</Dialog>
			<Dialog
				open={activateClientDialogOpened}
				onClose={closeActivateDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Kunde aktivieren
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`Sind Sie sicher, dass Sie den neuen Kunden "${clients.find(client => client.Id === selectedClient)?.ShortName}" aktivieren wollen?`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<OrangeButton onClick={closeActivateDialog}>
						Abbrechen
					</OrangeButton>
					<OrangeButton onClick={handleActivate}>
						Aktivieren
					</OrangeButton>
				</DialogActions>
			</Dialog>
		</div>);
}

export default ClientsList;