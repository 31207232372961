import JobInstructionForm from "../../JobInstruction/JobInstructionView";
import Job, {JobStatus} from "../../../Api/Types/Job";
import InvoiceStatus from "../../Invoice/InvoiceStatus";
import jobFeatureAllowed from "../../../Helpers/JobFeatures";
import CreateOrUpdateInvoiceForm, {CreateOrUpdateInvoiceFormProps} from "../../Invoice/CreateOrUpdateInvoiceForm";
import {roundHalf} from "../../../Helpers/roundHalf";
import PreviewDocument from "../../PreviewDocument/PreviewDocument";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormGroup,
	Typography
} from "@mui/material";
import OrangeButton from "../../OrangeButton/OrangeButton";
import {Send} from "@mui/icons-material";
import React, {useState} from "react";
import ConfirmationFile from "../../../Api/Types/ConfirmationFile";
import {Actions} from "../../Actions/Actions";
import {FilePreview} from "../../../Api/Types/FilePreview";
import {useJob} from "../../../Api/useJob";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

type Props = {
	job: Job;
	confirmationFile: ConfirmationFile;
	previewInvoice: FilePreview | null;
	previewInvoiceConfirmation: FilePreview | null;
}

const ManageInvoice = ({job, confirmationFile, previewInvoice, previewInvoiceConfirmation}: Props) => {
	const [sendNotification, setSendNotification] = useState(true);
	const [open, setOpen] = useState(false);

	const {
		createInvoice,
		approveInvoice
	} = useJob();

	const creation = (values: CreateOrUpdateInvoiceFormProps) => {
		if (job) {
			createInvoice(job.Id, values).then(() => window.location.reload())
		}
	}

	const confirm = () => {
		approveInvoice(job?.Id, sendNotification).then(() => window.location.reload());
	}

	return (
		<>
			<JobInstructionForm client={job.Client} status={JobStatus.ApproveClientPay}/>
			<InvoiceStatus invoice={job.Invoice}/>
			<p>{`Effektive Arbeitszeit: ${confirmationFile.EffectiveWorkingTime} | Gesamte Arbeitszeit: ${confirmationFile.WorkingTime}`}</p>
			{jobFeatureAllowed(job, 'editInvoice') && (
				<CreateOrUpdateInvoiceForm
					quantity={job.Invoice?.Rows[0]?.Quantity || roundHalf(confirmationFile.EffectiveWorkingTime)}
					rate={job.Client.RateInvoice}
					distanceEnabled={job.Client.DistanceInvoiceEnabled}
					distanceValue={job.Client.DistanceInvoiceValue}
					fixEnabled={job.Client.FixInvoiceEnabled}
					fixValue={job.Client.FixInvoiceValue}
					distanceQuantity={job.Invoice?.Rows.find(row => row.Description === 'KM Aufwand')?.Quantity || 0}
					handleSubmit={creation}
					comment={job.Invoice?.Comment ? job.Invoice.Comment : job.Client?.InvoiceReference ? job.Client.InvoiceReference : ''}
					invoiceDate={job.Invoice?.InvoiceDate || job.Date}
				/>
			)}
			{previewInvoice && (
				<div>
					<p>Vorschau:</p>
					<PreviewDocument {...previewInvoice}/>
				</div>
			)}
			{previewInvoice && previewInvoiceConfirmation && (
				<>
					<Divider style={{margin: "20px 0"}}/>
					<Typography variant='h6'>EB für den Kunden</Typography>
					<div>
						<p>EB Vorschau:</p>
						<PreviewDocument {...previewInvoiceConfirmation}/>
					</div>
				</>
			)}
			{jobFeatureAllowed(job, 'approveInvoice') && (
				<Actions>
					<OrangeButton
						onClick={() => setOpen(true)}
						startIcon={<Send fontSize={"small"}/>}>
						Einsatz abschließen
					</OrangeButton>
				</Actions>
			)}
			{open && (
				<Dialog
					open={true}
					onClose={() => setOpen(false)}
				>
					<DialogTitle>
						Einsatz abschließen
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Soll die Rechnung anschließend an der Kunden gesendet werden?
						</DialogContentText>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox checked={sendNotification}
								          onChange={(event) => setSendNotification(event.target.checked)}/>}
							                  label="Rechnung versenden"
							/>
						</FormGroup>
					</DialogContent>
					<DialogActions>
						<OrangeButton onClick={() => setOpen(false)}>
							Abbrechen
						</OrangeButton>
						<OrangeButton onClick={confirm}>
							Einsatz abschließen
						</OrangeButton>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}

export default ManageInvoice;