import Page from "../../../Components/Page/Page";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
} from "@mui/material";
import Grid from "../../../Components/Grid/Grid";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import {
	ExpandMore,
	ReceiptLong,
	Download,
} from "@mui/icons-material";
import ConfirmationFile from "../../../Api/Types/ConfirmationFile";
import {FilePreview} from "../../../Api/Types/FilePreview";
import PreviewDocument from "../../../Components/PreviewDocument/PreviewDocument";
import {useConfirmationFiles} from "../../../Api/useConfirmationFiles";
import TextField from "@mui/material/TextField";
import OrangeButton from "../../../Components/OrangeButton/OrangeButton";

export const ConfirmationEditView = () => {
	const {id} = useParams();
	const [confirmationFile, setConfirmationFile] = useState<ConfirmationFile | null>(null);
	const [previewConfirmation, setPreviewConfirmation] = useState<FilePreview | null>(null);
	const [newName, setNewName] = useState<string>('');

	const {getById, getUrl, rename, loading, token, requesting} = useConfirmationFiles();

	useEffect(() => {
			if (id && token) {
				getById(id).then(res => setConfirmationFile(res));
				getUrl(id).then(res => setPreviewConfirmation(res));
			}
		}, [id, token]
	);

	useEffect(() => {
			if (confirmationFile?.WorkNumber) {
				setNewName(confirmationFile.WorkNumber);
			}
		}, [confirmationFile?.WorkNumber]
	);


	const getConfirmationFileDownloadLink = (id: string) => {
		return `${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/confirmations/${id}/download`
	}

	const updateName = (event: any) => {
		if (event.target.value || event.target.value === '') {
			setNewName(event.target.value);
		}
	}

	const handleRename = () => {
		if (id) {
			rename(id, newName);
		}
	}


	return (
		<Page headline={NavigationStructure.jobEdit.display}>
			{loading && (<div><CircularProgress/></div>)}
			{!loading && id && (
				<Grid>
					{confirmationFile && (
						<Accordion defaultExpanded={true}>
							<AccordionSummary
								expandIcon={<ExpandMore/>}
							>
								<ReceiptLong fontSize={"small"}/>
								Dolmetscher Einsatzbestätigung
							</AccordionSummary>
							<AccordionDetails>
								<div style={{margin: "10px 0"}}>
									<p>Beginn der Hinfahrt: <b>{confirmationFile.DriveStart}</b></p>
									<p>Beginn des Einsatzes: <b>{confirmationFile.WorkingStart}</b></p>
									<p>Ende des Einsatzes (inkl. Wartezeit): <b>{confirmationFile.WorkingEnd}</b></p>
									<p>Ende der Rückfahrt: <b>{confirmationFile.DriveEnd}</b></p>
									<p>Effektive Arbeitszeit: <b>{confirmationFile.EffectiveWorkingTime}</b></p>
									<p>Arbeitszeit: <b>{confirmationFile.WorkingTime}</b></p>
								</div>
								<div style={{margin: "10px 0"}}>
									<TextField label="Einsatznummer"
									           variant="outlined"
									           value={newName}
									           onChange={updateName}
									/>
								</div>
								<div style={{margin: "10px 0"}}>
									<OrangeButton
										disabled={loading || requesting}
										startIcon={requesting ? <CircularProgress size={20} color={"error"}/> : null}
										onClick={handleRename}
									>
										Speichern
									</OrangeButton>
								</div>
								{previewConfirmation && (
									<div>
										<p>Vorschau:</p>
										<PreviewDocument {...previewConfirmation}/>
										<p>
											<a target={"_blank"}
											   href={getConfirmationFileDownloadLink(id)}
											   rel="noreferrer"
											>
												<Download fontSize={"small"}/>
												Download Einsatzbestätigung
											</a>
										</p>
									</div>
								)}
							</AccordionDetails>
						</Accordion>
					)}
				</Grid>
			)}
		</Page>
	)
}

export default ConfirmationEditView;