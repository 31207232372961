import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";

export const LoginForm = () => {
	const {
		isAuthenticated,
		loginWithRedirect,
		isLoading
	} = useAuth0();

	useEffect(() => {
		async function checkUser() {
			if (!isAuthenticated) {
				await loginWithRedirect();
			} else {
				console.log('user was logged in')
			}
		}

		checkUser();
	}, [isAuthenticated, isLoading, loginWithRedirect])

	return (
		<div></div>
	);
};

export default LoginForm;
