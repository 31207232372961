import {getHeaderTempToken} from "../../getHeader";
import Interpreter from "../../Types/Interpreter";

export const startBidding = async (token: string, id: string, interpreters: Array<Interpreter['Id']>): Promise<Array<Interpreter>> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/jobs/${id}/bidding`, {
		method: "POST",
		headers: getHeaderTempToken(token),
		body: JSON.stringify({Ids: interpreters})
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				return null;
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return [];
		})
}