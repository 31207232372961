import * as yup from "yup";

const ClientValidation = yup.object().shape({
	Name: yup
		.string()
		.min(3, "Too Short !")
		.required("Required !"),
	ShortName: yup
		.string()
		.min(3, "Too Short !")
		.required("Required !"),
	AdditionalName: yup
		.string()
		.min(3, "Too Short !")
		.optional(),
	AdditionalName2: yup
		.string()
		.min(3, "Too Short !")
		.optional(),
	Street: yup
		.string()
		.min(3, "Too Short !")
		.required("Required !"),
	Postcode: yup
		.string()
		.min(5, "Too Short !")
		.max(5, "Too Long !")
		.required("Required !"),
	City: yup
		.string()
		.min(3, "Too Short !")
		.required("Required !"),
	JobNumberPrefix: yup
		.string()
		.required("Required !"),

});

export default ClientValidation;
