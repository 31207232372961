import {getHeaderTempToken} from "../../getHeader";
import Job from "../../Types/Job";
import Interpreter from "../../Types/Interpreter";

export const assignJob = async (token: string, jobId: Job['Id'], InterpreterId: Interpreter['Id'], SendNotifications: boolean): Promise<Job[]> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/jobs/${jobId}/assign`, {
		method: "POST",
		headers: getHeaderTempToken(token),
		body: JSON.stringify({InterpreterId, SendNotifications})
	})
		.then(async (res: any) => {
			if (res.error) {
				console.error("Error", res)
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				console.log("logout");
			} else if (res.status === 400) {
				throw res;
			} else {
				return res.json();
			}
		})
}