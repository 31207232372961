import {getHeaderTempToken} from "../../getHeader";
import ConfirmationFile from "../../Types/ConfirmationFile";
import {FormValuesConfirmationApprove} from "../../../Pages/ConfirmationUpload/EditConfirmationForm";

export const updateConfirmationFileById = async (token: string, id: string, data: FormValuesConfirmationApprove): Promise<ConfirmationFile | null> => {
	return fetch(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/confirmations/${id}`, {
		method: "PUT",
		headers: getHeaderTempToken(token),
		body: JSON.stringify(data)
	})
		.then(async (res: any) => {
			if (res.error) {
				throw res.error;
			}
			if (res.status === 404) {
				return null;
			} else if (res.status === 401) {
				return null;
			} else {
				return res.json();
			}
		}).then((res) => {
			if (res) return res;
			return null;
		})
}