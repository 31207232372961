import * as yup from "yup";

const timeFormat = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

const helper = "Bitte die richtige Form beachten z.B. 08:00 oder 23:45";

const UpdateValidation = yup.object().shape({
	DriveStart: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	WorkingStart: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	WorkingEnd: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
	DriveEnd: yup.string()
		.required(helper)
		.matches(
			timeFormat,
			helper
		),
});

export default UpdateValidation;
