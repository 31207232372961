import React, {useEffect, useState} from "react";
import Page from "../../Components/Page/Page";
import {NavigationStructure} from "../../Components/Navigation/NavigationStructure";
import {useJobs} from "../../Api/useJobs";
import OrangeButton from "../../Components/OrangeButton/OrangeButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useClients} from "../../Api/useClients";
import {
	Chip,
	CircularProgress,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Typography
} from "@mui/material";
import FiltersDropdown from "../../Components/FiltersDropdown/FiltersDropdown";
import JobsLIst from "./JobsList";
import {JobStatus} from "../../Api/Types/Job";
import {useInterpreters} from "../../Api/useInterpreters";
import {Clear} from "@mui/icons-material";
import getStatusLabel from "../../Helpers/getStatusLabel";
import TextField from "@mui/material/TextField";
import {JobsSortKeys} from "../../Types/JobsSortKeys";
import {JobDateOptions} from "../../Types/JobDateOptions";
import dayjs, {Dayjs} from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import utc from 'dayjs/plugin/utc';
import compare from "../../Helpers/compare";

dayjs.extend(utc);
dayjs.extend(isoWeek);

export const JobsView = () => {
	const {data, loading, total, token, load} = useJobs(false);
	const {activeClients, loading: loadingClients} = useClients();
	const {interpreters, loading: loadingInterpreters} = useInterpreters();
	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

	const [selectedClients, setSelectedClients] = useState<Array<string>>(searchParams.getAll('clients') || []);
	const [selectedStatus, setSelectedStatus] = useState<Array<string>>(searchParams.getAll('statuses') || []);
	const [selectedFromDate, setSelectedFromDate] = useState<JobDateOptions>('all');
	const [selectedInterpreters, setSelectedInterpreters] = useState<Array<string>>(searchParams.getAll('interpreters') || []);
	const [take] = useState<string>(searchParams.get('take') || '100');
	const [skip, setSkip] = useState<string>(searchParams.get('skip') || '0');
	const [page, setPage] = useState<number>(Number(searchParams.get('skip')) / 100);
	const [search, setSearch] = useState<string>(searchParams.get('search') || '');
	const [sortKey, setSortKey] = useState<JobsSortKeys>(searchParams.get('sortKey') as JobsSortKeys || 'Date');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(searchParams.get('sortOrder') as 'asc' | 'desc' || 'desc');
	const [from, setFrom] = useState<undefined | Dayjs>(dayjs(searchParams.get('from')).isValid() ? dayjs(searchParams.get('from')) : undefined);
	const [to, setTo] = useState<undefined | Dayjs>(dayjs(searchParams.get('to')).isValid() ? dayjs(searchParams.get('to')) : undefined);

	useEffect(() => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('clients');
		selectedClients.forEach(client => {
			params.append('clients', client);
		});

		params.delete('statuses');
		selectedStatus.forEach(status => {
			params.append('statuses', status);
		});

		params.delete('interpreters');
		selectedInterpreters.forEach(interpreter => {
			params.append('interpreters', interpreter);
		});

		params.delete('from');
		if (from && dayjs(from).isValid()) {
			params.append('from', dayjs(from).format('YYYY-MM-DD'));
		}

		params.delete('to');
		if (to && dayjs(to).isValid()) {
			params.append('to', dayjs(to).format('YYYY-MM-DD'));
		}

		params.delete('take');
		if (take) params.append('take', take);

		params.delete('skip');
		if (take) params.append('skip', skip);

		params.delete('search');
		if (search) params.append('search', search);

		params.delete('sortKey');
		if (sortKey) params.append('sortKey', sortKey);

		params.delete('sortOrder');
		if (sortOrder) params.append('sortOrder', sortOrder);

		setSearchParams(params.toString());

		if (token && !loading) {
			load(params.toString());
		}
	}, [selectedClients, selectedStatus, searchParams, selectedInterpreters, skip, take, search, sortKey, sortOrder, from, to, token])

	const deleteFilters = () => {
		const params = new URLSearchParams(searchParams?.toString() || {});

		params.delete('clients');
		params.delete('statuses');
		params.delete('interpreters');
		params.delete('to');
		params.delete('from');
		setSelectedClients([]);
		setSelectedStatus([]);
		setSelectedInterpreters([]);
		setSearchParams(params.toString());
		setPage(1);
		setSkip('0');
		setSearch('');
		setTo(undefined);
		setFrom(undefined);
		setSelectedFromDate('all');
	}

	const onChangeClient = (checked: boolean, value: string) => {
		if (checked) {
			setSelectedClients([...selectedClients, value]);
		} else {
			setSelectedClients([...selectedClients.filter(client => client !== value)]);
		}
		setPage(1);
		setSkip('0');
	}

	const onChangeStatus = (checked: boolean, value: string) => {
		if (checked) {
			setSelectedStatus([...selectedStatus, value]);
		} else {
			setSelectedStatus([...selectedStatus.filter(client => client !== value)]);
		}
		setPage(1);
		setSkip('0');
	}

	const onChangeInterpreter = (checked: boolean, value: string) => {
		if (checked) {
			setSelectedInterpreters([...selectedInterpreters, value]);
		} else {
			setSelectedInterpreters([...selectedInterpreters.filter(interpreter => interpreter !== value)]);
		}
		setPage(1);
		setSkip('0');
	}

	const goToAddJob = () => {
		navigate(`/${NavigationStructure.jobAdd.url}`);
	}

	const handlePaginationChange = (e: any, page: number) => {
		setSkip(`${(page - 1) * 100}`);
		setPage(page);
	}

	const handleSearchChange = (e: any) => {
		setPage(1);
		setSkip('0');
		setSearch(e.target.value);
	}

	const createSortHandler = (key: JobsSortKeys) => {
		if (key === sortKey) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		}
		setSortKey(key);
	}

	const handleDateFilterPreset = (event: any) => {
		const value = event.target.value as JobDateOptions;
		setSelectedFromDate(value);
		if (value === 'all') {
			setFrom(undefined);
			setTo(undefined);
		} else if (value === 'thisWeek') {
			const start = dayjs().startOf('week');
			const end = dayjs().endOf('week');
			setFrom(start);
			setTo(end);
		} else if (value === 'nextWeek') {
			const start = dayjs().add(1, 'week').startOf('week');
			const end = dayjs().add(1, 'week').endOf('week');
			setFrom(start);
			setTo(end);
		}
	}

	return (
		<Page headline={NavigationStructure.jobs.display}>
			<div style={{display: "flex", justifyContent: "end", marginBottom: "20px"}}>
				<OrangeButton onClick={goToAddJob}>Auftrag anlegen</OrangeButton>
			</div>
			<div style={{display: "flex"}}>
				<TextField
					value={search}
					onChange={handleSearchChange}
					label={"Einsatznummer"}
				/>
				<FiltersDropdown
					label={'Kunde'}
					onChange={onChangeClient}
					values={selectedClients}
					options={activeClients.sort((a, b) => compare(a.JobNumberPrefix, b.JobNumberPrefix)).map(client => ({
						name: client.ShortName,
						value: client.Id
					}))}
				/>
				<FiltersDropdown
					label={'Status'}
					onChange={onChangeStatus}
					values={selectedStatus}
					options={Object.keys(JobStatus).map(status => ({
						name: getStatusLabel(status),
						value: status
					}))}
				/>
				<FiltersDropdown
					label={'Dolmetscher'}
					onChange={onChangeInterpreter}
					values={selectedInterpreters}
					options={interpreters.filter(interpreter => interpreter.Status === "Active").map(interpreter => ({
						name: `${interpreter.Prename} ${interpreter.Surname} | ${interpreter.Address.Postcode}`,
						value: interpreter.Id
					}))}
				/>
				<FormControl>
					<InputLabel>Datum</InputLabel>
					<Select
						value={selectedFromDate}
						label="Datum"
						onChange={handleDateFilterPreset}
					>
						<MenuItem value={'all'}>Alles</MenuItem>
						<MenuItem value={'thisWeek'}>Diese Woche</MenuItem>
						<MenuItem value={'nextWeek'}>Nächste Woche</MenuItem>
						<MenuItem value={'custom'}>Eigener Filter</MenuItem>
					</Select>
				</FormControl>
				{selectedFromDate === 'custom' && (
					<div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label="Von"
								defaultValue={from}
								format="DD.MM.YYYY"
								onChange={(newValue) => setFrom(dayjs(newValue))}/>
							<DatePicker
								label="Bis"
								defaultValue={to}
								format="DD.MM.YYYY"
								onChange={(newValue) => setTo(dayjs(newValue))}
							/>
						</LocalizationProvider>
					</div>
				)}
				<div style={{display: "flex"}}>
					<div>
						<IconButton onClick={deleteFilters}>
							<Clear/>
						</IconButton>
					</div>
				</div>
			</div>
			<div>
				<div>
					{selectedClients.length > 0 && (
						<div>
							<span>Kundenfilter:</span>
							{selectedClients.map(client => (
								<Chip label={activeClients.find(item => item.Id === client)?.Name}/>))}
						</div>
					)}
				</div>
				<div>
					{selectedStatus.length > 0 && (
						<div>
							<span>Statusfilter:</span>
							{selectedStatus.map(status => (<Chip label={status}/>))}
						</div>
					)}
				</div>
				<div>
					{selectedInterpreters.length > 0 && (
						<div>
							<span>Dolmetscher Filter:</span>
							{selectedInterpreters.map(interpreter => (<Chip
								label={interpreters.find(item => item.Id === interpreter)?.Prename}
							/>))}
						</div>
					)}
				</div>
			</div>
			{(loading || loadingClients || loadingInterpreters) && (
				<div>
					<CircularProgress/>
				</div>
			)}
			{!loading && !loadingClients && !loadingInterpreters && (
				<div>

					<div>
						<Typography variant={"body2"}>{`Es wurden ${total} Aufträge gefunden`}</Typography>
						<Pagination count={Math.ceil(total / 100)}
						            onChange={handlePaginationChange}
						            style={{marginTop: "15px"}}
						            page={page}
						/>
						<JobsLIst
							jobs={data}
							order={sortOrder}
							createSortHandler={createSortHandler}
							sortKey={sortKey}
						/>
						<Pagination count={Math.ceil(total / 100)}
						            onChange={handlePaginationChange}
						            style={{marginTop: "15px"}}
						            page={page}
						/>
					</div>
				</div>
			)}
		</Page>
	);
};

export default JobsView;