import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { useField, useFormikContext } from "formik";
import React from "react";
import {TimePicker} from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Props<TInputDate, TDate> = {
	name: string;
// @ts-ignore
} & Omit<DatePickerProps<TInputDate, TDate>, "onChange" | "value">;
const FormikTimePicker = <TInputDate, TDate = TInputDate>(
	props: Props<TInputDate, TDate>
) => {
	const { name, ...restProps } = props;
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();
	return (
		<TimePicker
			{...restProps}
			ampm={false}
			value={field.value ?? null}
			onChange={(val) => setFieldValue(name, val)}
			timezone={'UTC'}
		/>
	);
};

export default FormikTimePicker;