import {getHeader} from "../../getHeader";
import axios from "axios";
import {ConfirmationFileWithRelations} from "../../Types/ConfirmationFile";

export const searchConfirmationFiles = async (token: string, query: string): Promise<{
	results: ConfirmationFileWithRelations[],
	total: number
}> => {
	return axios.get<{
			results: ConfirmationFileWithRelations[],
			total: number
		}>(`${process.env.REACT_APP_NEST_BACKEND_URL}/api/v1/confirmations/search?${query}`,
			{
				headers: getHeader(token),
			})
		.then(res => {
			if (res.status !== 200) {
				throw res;
			}
			return res.data;
		})
}