import Job from "../Api/Types/Job";
import dayjs from "dayjs";

const getJobAsText = (job: Job) => {
	return `${job.NumberPrefix}-${job.NumberCounter} | ${job.Client.JobNumberPrefix} | ${job.Language} | ${dayjs(job.Date).utc().format('DD.MM.YYYY - HH:mm')} | ${job.Address.Location || ''} ${job.Address.Station || ''} ${job.Address.Street} ${job.Address.Postcode} ${job.Address.City} | ${job?.Patient?.Name || ''} ${job?.Patient?.Comment || ''}`
}

const getJobNumber = (job: Job) => {
	return `${job.NumberPrefix}-${job.NumberCounter}`;
}

export {
	getJobAsText,
	getJobNumber
}