import {Grid, Alert, CircularProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import {FormValuesConfirmationUpload, UploadConfirmationForm} from "../UploadConfirmationForm";
import {useParams} from "react-router-dom";
import {useJobConfirmationUpload} from "../../../Api/useJobConfirmationUpload";
import {JobStatus} from "../../../Api/Types/Job";
import {getJobNumber} from "../../../Helpers/JobHelper";
import {getFormData} from "../../../Helpers/confirmationUploadHelper";

export const JobConfirmationUploadView = () => {
	const {jobId} = useParams();

	const {
		data: job,
		load,
		loading,
		error: errorLoad,
		upload
	} = useJobConfirmationUpload();

	useEffect(() => {
		if (jobId) load(jobId);
	}, [jobId]);

	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	const onSubmit = (data: FormValuesConfirmationUpload) => {
		const formData = getFormData(data);

		try {
			setSuccess(false);
			setError(false);

			upload(jobId || '', formData).then(_ => {
					setSuccess(true);
				})
				.catch((e) => {
					setError(true);
					if (e.response && e.response.data && e.response.data.message) {
						setErrorMessage(JSON.stringify(e.response.data.message));

					} else {
						setErrorMessage(JSON.stringify(e));
					}
				})
		} catch (error: any) {
			setError(true);
			setSuccess(false);
			setErrorMessage(error.toString());
		}
	};

	return (
		<Page headline={NavigationStructure.upload.display}>
			{loading && (<CircularProgress/>)}
			{!loading && job && job.Status !== JobStatus.ApproveConfirmation && !success && (
				<UploadConfirmationForm onSubmit={onSubmit} job={job}/>
			)}
			{!loading && job && job.Status === JobStatus.ApproveConfirmation && !success && (
				<div>
					<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
						<Alert severity="success">Für den Einsatz {getJobNumber(job)} wurde die Einsatzbestätigung
							bereits übermittelt!</Alert>
					</Grid>
				</div>
			)}
			{error && (
				<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
					<Alert severity="error">{errorMessage}</Alert>
				</Grid>
			)}
			{errorLoad && (
				<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
					<Alert severity="error">{errorLoad}</Alert>
				</Grid>
			)}
			{success && (
				<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
					<Alert severity="success">Deine Einsatzbestätigung wurde erfolgreich übermittelt!</Alert>
				</Grid>
			)}
		</Page>
	);
};

export default JobConfirmationUploadView;
