import React, {useState} from "react";
import {
	CircularProgress
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";
import {useClient} from "../../../Api/useClient";
import ClientForm from "../../../Components/Clients/ClientForm";

export const ClientEditPage = () => {
	const {clientId} = useParams();
	const navigate = useNavigate();

	const [error, setError] = useState("");

	const {client, loading} = useClient(clientId);

	if (error) return (
		<Page headline={NavigationStructure.clientEdit.display}>
			<center>
				{error}
			</center>
		</Page>
	);

	if (loading) return (
		<Page headline={NavigationStructure.clientEdit.display}>
			<center>
				<CircularProgress/>
			</center>
		</Page>
	);

	const onClose = () => {
		navigate(-1);
	}

	const onError = (e: any) => {
		setError(e.toString);
	}

	return (
		<Page headline={NavigationStructure.clientEdit.display}>
			{client && (
				<ClientForm client={client}
				            onClose={onClose}
				            onError={onError}
				            mode={"edit"}
				/>
			)}
		</Page>);
}

export default ClientEditPage;