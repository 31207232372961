import {useRef, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Menu, MenuItem, SvgIcon} from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = {
	label: string,
	onChange: (checked: boolean, value: string, name: string) => void,
	options: Array<{ name: string, value: string }>,
	values: Array<string>;
}

export default function FiltersDropdown({
	                                        label,
	                                        onChange,
	                                        options,
	                                        values
                                        }: Props) {
	const anchorRef = useRef<null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<>
			<Button
				color="inherit"
				ref={anchorRef}
				endIcon={(
					<SvgIcon>
						<ChevronDownIcon/>
					</SvgIcon>
				)}
				onClick={() => setIsOpen(true)}
			>
				{label}
			</Button>
			<Menu
				anchorEl={anchorRef.current}
				open={isOpen}
				onClose={() => setIsOpen(false)}
			>
				{options.map(option => (
					<MenuItem key={option.value}>
						<FormControlLabel
							label={option.name}
							name={option.name}
							sx={{flexGrow: 1, mr: 0}}
							control={(
								<Checkbox
									checked={values.some((value) => value === String(option.value))}
									value={option.value}
									onChange={({target}) => onChange(target.checked, target.value, target.name)}
								/>
							)}
						/>
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
