import {Grid, Alert} from "@mui/material";
import React from "react";
import {NavigationStructure} from "../../../Components/Navigation/NavigationStructure";
import Page from "../../../Components/Page/Page";

export const GeneralConfirmationUploadView = () => {
	return (
		<Page headline={NavigationStructure.upload.display}>
			<Grid item xs={12} style={{padding: "10px 5px 10px 5px"}}>
				<Alert severity="warning">Bitte nutze den Upload-Link, der Dir per Mail oder WhatsApp zugesendet worden
					ist.</Alert>
			</Grid>
		</Page>
	);
};

export default GeneralConfirmationUploadView;
