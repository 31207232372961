export function getHeader(newToken: null | string = null): Record<string, any> {
	const token = newToken || localStorage.getItem("IMS_user_token");
	return {
		"Content-Type": "application/json",
		"Authorization": token == null ? null : `Bearer ${token}`,
	};
}

export function getHeaderTempToken(token: string): Record<string, any> {
	//TODO load token from memory
	const headers = new Headers();
	headers.set("Content-Type", "application/json");
	headers.set("Authorization", token == null ? "" : `Bearer ${token}`);

	return headers;
}